import * as assert from 'assert';
import * as Stream from 'stream';
import { put, select, takeEvery, call } from '@redux-saga/core/effects';

import { SearchAction, SearchActionType, SearchState } from './search.reducer';
import { NodeId } from '../../types/json-node.type';
import { idSiblingFromString } from '../../component/side-bar/search-document/idSibling';
import { selectById } from '../../component/lazy/selectById';
import { getClosestParentSectionById } from '../../component/lazy/getClosestParentSectionById';
import { SortedDisplayOrderEntry, TextSearchResult, IdSiblingString, Position } from '../../types/search.type';
import { SearchService, convertResult2Map } from '../../component/side-bar/search-document/search.service';
import { standardStateStore } from '../standard-state/standard-state.store';
import { DebugTimer } from '../../test-util/debugTimer';
import { Uuid } from '../../types/workerMessage.type';

const searchStreamIn: Stream.Readable = new Stream.Readable( {
    objectMode: true,
    read( size: number ): void {}
} );

const timer = new DebugTimer( 'search saga' );

/** dispatch through this function.
 *  do not call `searchStore.dispatch`
 * */
function dispatch2searchStore( action: SearchAction ): void {
    searchStreamIn.push( action );
}

function *searchSaga() {
    yield takeEvery( [
        SearchActionType.searchTerm,
        SearchActionType.bookmarkIncrement,
        SearchActionType.bookmarkDecrement,
        SearchActionType.setBookmark,
        SearchActionType.storeOwnMatchesTrigger,
        SearchActionType.storeChildMatchesTrigger,
        SearchActionType.storeDisplayOrderTrigger,
        SearchActionType.storeToc2matchTrigger
    ], searchFactoryMethod );
}

function *searchFactoryMethod( action: SearchAction ) {
    const searchUuid1: Uuid | undefined = yield select( ( s: SearchState ) => s.searchInProgress );

    switch ( action.type ) {
        case SearchActionType.searchTerm:
            yield withSearchUuid( onSearchTerm, action, searchUuid1 );
            break;
        case SearchActionType.storeOwnMatchesTrigger:
            yield withSearchUuid( ownMatches, action, searchUuid1 );
            break;
        case SearchActionType.storeChildMatchesTrigger:
            yield withSearchUuid( childMatches, action, searchUuid1 );
            break;
        case SearchActionType.storeDisplayOrderTrigger:
            yield withSearchUuid( displayOrder, action, searchUuid1 );
            break;
        case SearchActionType.storeToc2matchTrigger:
            yield withSearchUuid( toc2Match, action, searchUuid1 );
            yield withSearchUuid( dispatch2searchStoreIfInProgress, { type: SearchActionType.searchComplete }, searchUuid1 );
            yield selectFirstMatch();
            break;
        case SearchActionType.bookmarkIncrement:
        case SearchActionType.bookmarkDecrement:
        case SearchActionType.setBookmark:
            yield bookmark();
            break;
    }
}

function searchService(): SearchService {
    const standardState = standardStateStore.getState();
    assert.ok( standardState.searchService, 'falsey searchService' );
    return standardState.searchService!;
}

function *resetBookmark() {
    const bookmark: number = yield select( ( s: SearchState ) => s.bookmark );
    if ( bookmark > -1 ) {
        dispatch2searchStore( { type: SearchActionType.setBookmark, content: -1 } );
    }
}

function *dispatch2searchStoreIfInProgress( expected: Uuid, action: SearchAction ) {
    const inProgress: Uuid | undefined = yield select( ( s: SearchState ) => s.searchInProgress );
    if ( inProgress === expected ) {
        dispatch2searchStore( action );
    }
}


type Step = ( ( currentUuid: Uuid, action: SearchAction ) => IterableIterator<any> )
    | ( ( currentUuid: Uuid ) => IterableIterator<any> );

function *withSearchUuid( step: Step,
                          action: SearchAction,
                          searchUuid?: Uuid ) {
    if ( searchUuid ) {
        yield step( searchUuid, action );
    } else {
        console.debug( 'falsey searchUuid' );
        return;
    }
}

function *onSearchTerm( currentUuid: Uuid, action: SearchAction ): any {
    timer.begin();
    yield resetBookmark();

    const service: SearchService = searchService(),
        searchResultArray: TextSearchResult[] = service!.search( action.content as string );
    timer.lap( 'onSearchTerm service!.search' );

    if ( searchResultArray.length === 0 ) {
        dispatch2searchStore( { type: SearchActionType.searchComplete } );
        timer.end();
        return;
    }

    const resultMap: Map<IdSiblingString, Position[]> = convertResult2Map( searchResultArray );
    timer.lap( 'onSearchTerm convertResult2Map' );

    yield dispatch2searchStoreIfInProgress( currentUuid, {
        type: SearchActionType.storeResultsArray,
        content: searchResultArray
    } );
    yield dispatch2searchStoreIfInProgress( currentUuid, {
        type: SearchActionType.storeResultsMap,
        content: resultMap
    } );
    yield dispatch2searchStoreIfInProgress( currentUuid, { type: SearchActionType.storeOwnMatchesTrigger } );
}

function *ownMatches( currentUuid: Uuid ): any {
    const searchResultArray = yield select( ( s: SearchState ) => s.resultArray ),
        ownMatches: Map<NodeId, number> = yield call( SearchService.calculateOwnMatches, searchResultArray );
    timer.lap( 'ownMatches' );
    yield dispatch2searchStoreIfInProgress( currentUuid, {
        type: SearchActionType.storeOwnMatches,
        content: ownMatches
    } );
    yield dispatch2searchStoreIfInProgress( currentUuid, { type: SearchActionType.storeChildMatchesTrigger } );
}

function *childMatches( currentUuid: Uuid ): any {
    const ownMatches1 = yield select( ( s: SearchState ) => s.ownMatches ),
        childMatches: Map<NodeId, number> = yield call( SearchService.calculateChildMatches, ownMatches1 );
    timer.lap( 'childMatches' );
    yield dispatch2searchStoreIfInProgress( currentUuid, {
        type: SearchActionType.storeChildMatches,
        content: childMatches
    } );
    yield dispatch2searchStoreIfInProgress( currentUuid, { type: SearchActionType.storeDisplayOrderTrigger } );
}

function *displayOrder( currentUuid: Uuid ): any {
    const resultMap = yield select( ( s: SearchState ) => s.resultMap ),
        order: SortedDisplayOrderEntry[ ] = yield call( SearchService.calculateSortedInDisplayOrder, resultMap );
    timer.lap( 'displayOrder' );
    yield dispatch2searchStoreIfInProgress( currentUuid, { type: SearchActionType.storeDisplayOrder, content: order } );
    yield dispatch2searchStoreIfInProgress( currentUuid, { type: SearchActionType.storeToc2matchTrigger } );
}

function *toc2Match( currentUuid: Uuid ): any {
    const order = yield select( ( s: SearchState ) => s.sortedDisplayOrder ),
        toc2match: Map<NodeId, [ NodeId, number ]> = yield call( SearchService.calculateToc2Match, order );
    timer.lap( 'toc2Match' );
    yield dispatch2searchStoreIfInProgress( currentUuid, {
        type: SearchActionType.storeToc2match,
        content: toc2match
    } );
    timer.end();
}

function *selectFirstMatch() {
    const order: SortedDisplayOrderEntry[] = yield select( ( s: SearchState ) => s.sortedDisplayOrder );
    if ( order.length > 0 ) {
        yield put( { type: SearchActionType.bookmarkIncrement } );
    }
}

function *bookmark() {
    const searchState: SearchState = yield select();

    if ( searchState.bookmark < 0 ) return;

    const [ idSiblingString ]: SortedDisplayOrderEntry = searchState.sortedDisplayOrder[ searchState.bookmark ],
        highlightedNode: NodeId = idSiblingFromString( idSiblingString ).id;
    getClosestParentSectionById( highlightedNode ).then(
        closestParent => {
            if ( closestParent ) {
                let scrollTo: NodeId | undefined;
                if ( closestParent !== highlightedNode ) {
                    scrollTo = highlightedNode;
                }
                selectById( closestParent, scrollTo );
            } else {
                console.debug( {
                    highlightedNode,
                    closestParent
                } );
            }
        }
    );
}

export { searchSaga, searchStreamIn, dispatch2searchStore };
