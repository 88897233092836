import React, { useEffect, useRef } from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { rotateTableCell } from '../util/tableCellRotate';

export type valign = "top" | "middle" | "bottom" | "baseline" | undefined;
export type align = "left" | "center" | "right" | "justify" | "char" | undefined;

function Td( props: ComponentArg ): JSX.Element {

    const tdRef: React.RefObject<HTMLTableCellElement> = useRef(null);

    useEffect(() => {
        let style =  props.node!.a && props.node!.a['style'];
        if (style) {
            style = rotateTableCell(style, tdRef);
            tdRef.current!.setAttribute('style', style);
        }
      }, [ props.node ]);

    const rowSpan = props.node!.a && props.node!.a['rowspan'] ? Number(props.node!.a['rowspan']) : undefined;
    const colSpan = props.node!.a && props.node!.a['colspan'] ? Number(props.node!.a['colspan']) : undefined;
    const align = props.node!.a && props.node!.a['align'] ? props.node!.a['align']! as align : undefined;
    const valign = props.node!.a && props.node!.a['valign'] ? props.node!.a['valign']! as valign : undefined;

    return (<td ref={ tdRef }
        rowSpan={ rowSpan ? rowSpan : undefined } 
        colSpan={ colSpan ? colSpan : undefined } 
        align={ align ? align : undefined } 
        valign={ valign ? valign : undefined }
        key={ key( props ) }>{ defaultChildren( props ) }
    </td>);
}

export { Td };
