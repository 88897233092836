import { LazyBase } from '../lazy/lazy-base';

class Fig extends LazyBase<{}> {
    protected renderDialog(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }

    protected get divClass(): string {
        return 'sts-fig';
    }

    protected renderFootnote(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }
}

export { Fig };
