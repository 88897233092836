import React from 'react';
import { LazyBase } from '../../lazy/lazy-base';

class Standard extends LazyBase<{}> {
  protected renderDialog(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return null;
  }

  protected get divClass(): string {
    return '';
  }

  protected renderFootnote(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return null;
  }

  render(): React.ReactNode {
    const component = this.props;
    if (!component.node) {
      return null;
    }

    // Render a simple div that we will be able to link to in the table of contents
    return <div id={component.node.id} ref={this.lazyDiv}></div>;
  }
}

export default Standard;
