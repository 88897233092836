import { LazyBase } from '../lazy/lazy-base';

export class TermSec extends LazyBase<{}> {
    protected renderDialog(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }

    protected get divClass(): string {
        return 'sts-section sts-tbx-sec';
    }

    protected renderFootnote(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }
}
