import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { JsonNodeType } from '../../../types/json-node.type';
import { DispatchStream } from '../../stream/dispatch.stream';

type Content = WorkerStoreItem<JsonNodeType[]>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const allScrollableSectionDataStore: Store<S, A> = createStore( identityReducer as Reducer );

class AllScrollableSectionDataStoreDispatch extends DispatchStream<A>{
    protected act( m: A ): void {
        allScrollableSectionDataStore.dispatch(m);
    }
}

const allScrollableSectionDataStoreDispatcher = new AllScrollableSectionDataStoreDispatch();

export { allScrollableSectionDataStore, allScrollableSectionDataStoreDispatcher };
