import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Drawer, Menu } from 'antd';
import HeaderControls from '../../molecules/HeaderControls/HeaderControls';
import MenuControls from '../../molecules/MenuControls/MenuControls';
import styles from './Header.module.less';
import { getActiveCustomer, isExternalLink, isNullOrWhiteSpace } from '../../../util/util';
import { customerUrl, urlToEnquiry } from '../../../../../component/util/checkConfig';
import urlJoin from 'url-join';
import IconTextButton from '../../atoms/IconTextButton/IconTextButton';
import { IconType } from '../../atoms/Icon/Icon';
import Logo from '../../../../../component/header/logo';
import ScreenReaderOnly from '../../../../../component/util/screenReaderOnly';
import withRouter from '../../../../../withRouter';

const Header = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const clientCode = getActiveCustomer();
  const enquiryUrl = urlJoin(urlToEnquiry());
  const enquiryContact = urlJoin(enquiryUrl, "contact");
  const enquiryAbout = urlJoin(enquiryUrl, "about");
  const enquiryHelp = urlJoin(enquiryUrl, "help");
  const customerPageUrl = customerUrl();
  const HeaderControlsWithRouter = withRouter(HeaderControls);

  const logoHtml = (
    <div className={styles.logoWrapper}>
      <Logo clientCode={clientCode} />
      <span className={styles.logoText}>Logo</span>
    </div>
  );

  return (
    <>
      <header className={styles.header}>
        <Row style={{ width: '100%' }} align="middle">
          <Col xs={20} md={12}>
            <section className={styles.headerLinks}>
              {!isNullOrWhiteSpace(customerPageUrl) ? (
                <>
                  {logoHtml}
                  <IconTextButton
                    className={styles.homeButton}
                    iconType={IconType.Home}
                    iconRight={false}
                    buttonText={t('Enquiry.Menu.Home')}
                    textStyles={styles.homeButtonText}
                    iconHeight="var(--homeIconSize)"
                    iconWidth="var(--homeIconSize)"
                    strokeWidth={1.5}
                    url={enquiryUrl}
                  />
                </>
              ) : (
                <a href={enquiryUrl} className={styles.homeLink} rel={isExternalLink(enquiryUrl) ? 'noopener' : ''}>
                  {logoHtml}
                  <ScreenReaderOnly>
                      {t('Enquiry.RedirectToEnquiry')}
                      {isExternalLink(enquiryUrl) && t('External link') }
                  </ScreenReaderOnly>
                </a>
              )}
            </section>
          </Col>
          <Col xs={4} md={12} className={styles.rightSection}>
            <HeaderControlsWithRouter toggleMenu={toggleMenu} />
          </Col>
        </Row>
      </header>
      <Drawer
        closable={false} 
        open={isMenuOpen} 
        onClose={toggleMenu} 
        width="var(--mainMenuWidth)"
        className={styles.menuDrawer}
      >
        <MenuControls isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <nav className={styles.nav}>
          <Menu mode="inline" className={styles.menu}>
            <Menu.Item key="1">
              <a href={enquiryUrl} rel={isExternalLink(enquiryUrl) ? 'noopener' : ''}>
                {t('Enquiry.Menu.Home')}
                {isExternalLink(enquiryUrl) && (
                  <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
                )}
              </a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href={enquiryContact} rel={isExternalLink(enquiryContact) ? 'noopener' : ''}>
                {t('Enquiry.Menu.Contact')}
                {isExternalLink(enquiryContact) && (
                  <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
                )}
              </a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href={enquiryAbout} rel={isExternalLink(enquiryAbout) ? 'noopener' : ''}>
                {t('Enquiry.Menu.About')}
                {isExternalLink(enquiryAbout) && (
                  <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
                )}
              </a>
            </Menu.Item>
            <Menu.Item key="4">
              <a href={enquiryHelp} rel={isExternalLink(enquiryHelp) ? 'noopener' : ''}>
                {t('Enquiry.Menu.Help')}
                {isExternalLink(enquiryHelp) && (
                  <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
                )}
              </a>
            </Menu.Item>
          </Menu>
        </nav>
      </Drawer>
    </>
  );
};

export default Header;
