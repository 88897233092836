import React from "react";
import { useTranslation } from "react-i18next";
import { ReportError } from "./report-error";

export interface GlobalErrorProp {
    errorId: string;
}

const GlobalError = ({ errorId }: GlobalErrorProp) => {

    const { t } = useTranslation();

    return (
        <div className="errorMessage">
            <h1>{t('Errors.GlobalErrorTitle')}</h1>
            <p>{t('Errors.GlobalErrorTextLong')}</p>
            <p>{t('Errors.GlobalErrorPleaseTryAgain')}</p>
            <ReportError errorId={errorId} />
        </div>
    )
}

export { GlobalError }