import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from '../../atoms/Icon/Icon';
import IconButton from '../../atoms/IconButton/IconButton';
import LanguageSelector from '../../atoms/LanguageSelector/LanguageSelector';
import styles from './MenuControls.module.less';

type MenuControlsProps = {
  className?: string,
  toggleMenu: () => void,
  isMenuOpen?: boolean,
}

const MenuControls = ({ toggleMenu }: MenuControlsProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.MenuControls} aria-label="Controls">
      {/* Search button is hidden for now. */}
      <IconButton
        hidden
        iconType={IconType.Search}
        iconHeight={24}
        iconWidth={24}
        className={styles.search}
        ariaLabel="Search"
      />
      {/* Help guide button is hidden for now. */}
      <IconButton
        hidden
        iconType={IconType.Help}
        iconHeight={22}
        iconWidth={22}
        className={styles.help}
        ariaLabel="Help guides"
      />
      {/* User button is hidden for now. */}
      <IconButton
        hidden
        iconType={IconType.User}
        iconHeight={28}
        iconWidth={28}
        className={styles.user}
      />
      {/* LanguageSelecter is hidden for now. */}
      <LanguageSelector className={styles.language} hidden={true} />
      <IconButton
        iconType={IconType.X}
        iconHeight={32}
        iconWidth={32}
        onClick={toggleMenu}
        className={styles.x}
        ariaLabel={t('Enquiry.Menu.CloseMainNavigation')}
      />
    </section>
  );
};

export default MenuControls;
