import { LazyState } from '../../component/lazy/lazy.state';
import { CurrentFilteredCategory, IsFilterApplied } from '../filter-document/filter-document.selector';
import { ViewOnlyCategory } from '../filter-document/filter-document.state';
import { standardStateStore } from './standard-state.store';
import { StandardState } from './standard-state.types';

function getActiveLazyState(): LazyState {
  const standardState: StandardState = standardStateStore.getState();

  if (!IsFilterApplied()) {
    return standardState.lazyStateStandard;
  }

  const filterType = CurrentFilteredCategory();
  switch (filterType) {
    case ViewOnlyCategory.tables:
      return standardState.lazyStateTables;
    case ViewOnlyCategory.figures:
      return standardState.lazyStateFigures;
    case ViewOnlyCategory.requirements:
      return standardState.lazyStateRequirements;
    case ViewOnlyCategory.themes:
      return standardState.lazyStateThemes;
    case ViewOnlyCategory.commentingEnabled:
      return standardState.lazyStateCommentables;
    default:
      return standardState.lazyStateStandard;
  }
}

export { getActiveLazyState };
