import { ThrottleAction, ThrottleActionType } from './throttleSaga';

export interface ThrottleState<Content> {
    lastType?: ThrottleActionType,
    content?: Content;
}

function throttleReducer<Content>(
    _previousState: ThrottleState<Content> = { lastType: ThrottleActionType.raw},
    action: ThrottleAction<Content> ): ThrottleState<Content> {

    switch (  action.type ) {
        case ThrottleActionType.raw:
            return { lastType: action.type, content: action.content };
        case ThrottleActionType.throttled:
            return { lastType: action.type, content: _previousState.content };
        default:
            return _previousState;
    }
}

export { throttleReducer };
