import { Button } from 'antd';
import { ButtonShape } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import * as React from 'react';
import Icon, { IconType } from '../Icon/Icon';
import styles from './IconButton.module.less';

type IconButtonProps = {
  onClick?: React.MouseEventHandler,
  iconType: IconType,
  iconWidth?: string | number,
  iconHeight?: string | number,
  className?: string,
  shape?: ButtonShape,
  hidden?: boolean,
  ariaLabel?: string,
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>,
  disabled?: boolean,
  strokeWidth?: string | number,
  iconClasses?: string,
  tabIndex?: number,
  size?: SizeType,
}

const IconButton =  React.forwardRef<HTMLButtonElement, IconButtonProps>(({
  iconType,
  iconWidth,
  iconHeight,
  onClick,
  className = '',
  shape,
  hidden,
  ariaLabel,
  onKeyDown,
  disabled,
  strokeWidth,
  iconClasses,
  tabIndex,
  size
}, ref) => (
  <Button
    onClick={onClick}
    className={`${styles.button} ${className}`}
    shape={shape}
    hidden={hidden}
    aria-label={ariaLabel ? ariaLabel : iconType}
    ref={ref}
    onKeyDown={onKeyDown}
    disabled={disabled}
    tabIndex={tabIndex}
    size={size}
  >
    <Icon
      type={iconType}
      width={iconWidth}
      height={iconHeight}
      strokeWidth={strokeWidth}
      className={iconClasses}
    />
  </Button>
));

export default IconButton;
