import { ViewOnlyCategory } from './filter-document.state';
import { RequirementType } from '../../types/requirement.type';

export enum FilterDocumentActionType {
    clearFilter = 'clearFilter',
    selectViewOnlyCategory = 'selectViewOnlyCategory',
    addRequirementType = 'addRequirementType',
    addRequirementTypeAll = 'addRequirementTypeAll',
    removeRequirementType = 'removeRequirementType',
    removeRequirementTypeAll = 'removeRequirementTypeAll',
    setThemes = 'setThemes'
}

export interface FilterDocumentAction {
    type: FilterDocumentActionType,
    requirementType?: RequirementType,
    viewOnlyCategory?: ViewOnlyCategory,
    selectedThemes?: string[],
}
