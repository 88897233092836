export enum RequirementType {
    Shall = 'shall',
    Should = 'should',
    May = 'may',
    Must = 'must',
    Can = 'can'
}

export function fromString(requirementType: string): RequirementType {
    return requirementType as RequirementType;
}
