import * as React from 'react';
import { Tag } from 'antd';
import styles from './CommentTag.module.less';
import { Comment } from '../../../redux/types';
import { editComment } from '../../organisms/CommentingFramework/CommentingFrameworkState';
import { selectWrapper } from '../../../../selection/components/molecules/DocumentFractionWrapper/DocumentFractionWrapperState';

type CommentProps = {
  comment: Comment;
};

const CommentTag = ({ comment }: CommentProps) => {
  const { label, elementId } = comment;
  return (
    <button
      className={styles.button}
      onClick={(event) => {
        event.stopPropagation();
        selectWrapper(elementId);
        editComment(comment);
      }}
    >
      <Tag className={styles.tag}>{label}</Tag>
    </button>
  );
};

export default CommentTag;
