import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { CyNode } from '../../../types/graph.type';

type Content = WorkerStoreItem<CyNode[]>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const parentsStore: Store<S, A> = createStore( identityReducer as Reducer );

export { parentsStore };
