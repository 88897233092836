import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../redux/reducer';
import {
  DocumentFractionWrapperState,
  ElementDetails,
  ElementLocation,
  nothingSelected,
} from './DocumentFractionWrapperState';

export interface StateInformation {
  key: string;
  isKeySet: boolean;
  location: ElementLocation[];
  selection?: ElementDetails;
}

const documentWrapper = (state: RootState) =>
  state.DocumentFractionWrapperState;

const selectedComponent = createSelector(
  documentWrapper,
  (wrapper: DocumentFractionWrapperState): StateInformation => {
    return {
      key: wrapper.selectedItem,
      isKeySet: wrapper.selectedItem !== nothingSelected,
      location: wrapper.location,
      selection: wrapper.selection,
    };
  }
);

export { selectedComponent };
