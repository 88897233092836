import React from 'react';
import ValueKeyword from './value-keyword';
import { ValueProps } from './value-render';
import ValueSpecification from './value-specification';
import ValueUnit from './value-unit';

const Value = ({ component, vocab }: ValueProps) => {

  if (component == null)
  {
    return null;
  }

  if (component.node == null)
  {
    return null;
  }

  const supportedUnits = [
    <ValueUnit
      component={component}
      vocab={vocab}
      key="1"
    />,
    <ValueKeyword
      component={component}
      vocab={vocab}
      key="2"
    />,
    <ValueSpecification
      component={component}
      vocab={vocab}
      key="3"
    />,
  ];

  return <>{supportedUnits}</>;
};

export const IsValue = (contentType: string) => {
  return 'value' === contentType;
};

export default Value;
