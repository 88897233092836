import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import styles from './code.module.css';

const code = (arg: ComponentArg) => {
  return (
    <code className={styles.codeBlock} key={key(arg)}>
      <pre>{defaultChildren(arg)}</pre>
    </code>
  );
};

export { code };
