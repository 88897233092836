import { InitLazyStateBase } from './init-lazy-state.base';
import { NodeId } from '../../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { flatRenderedTextSizeStore } from '../../../redux/worker/stores/flatRenderedTextSize.store';
import { lazyLoadedSections } from '../../../graph/lazyLoadedSections';
import { getSectionRequirementThemes } from '../../../redux/standard-state/standard-state.selectors';

export class InitLazyStateThemes extends InitLazyStateBase {
  private _ids: NodeId[];
  themes: string[] = [];

  protected async setLazyLoadedIds(): Promise<void> {
    this._ids = this.state.allLazyLoadedIdsInDisplayOrder =
      this.filteredContentByThemes();
  }

  private filteredContentByThemes(): NodeId[] {
    if (!this.themes || !this.themes.length) {
      return [];
    }
    const sectionRequirementThemes = getSectionRequirementThemes();
    const allSections = lazyLoadedSections(this._standardTocData);
    const allSectionIds: NodeId[] = allSections.map((item) => item.nodeId);

    const filteredIds: NodeId[] = [];
    const processedSectionIds: string[] = [];
    for (const sectionRequirementTheme of sectionRequirementThemes) {
      const foundSection = allSectionIds.find(
        (x) => x === sectionRequirementTheme.sectionRequirementId,
      );
      if (!foundSection && sectionRequirementTheme.sectionId) {
        if (processedSectionIds) {
          const requirementSection = processedSectionIds.find(
            (x) => x === sectionRequirementTheme.sectionId,
          );
          if (requirementSection) {
            continue;
          }
        }

        for (const theme of sectionRequirementTheme.themes) {
          const foundTheme = this.themes.find((x) => x === theme);
          if (foundTheme) {
            filteredIds.push(sectionRequirementTheme.sectionRequirementId);
          }
        }
      } else if (foundSection) {
        for (const theme of sectionRequirementTheme.themes) {
          const foundTheme = this.themes.find((x) => x === theme);
          if (foundTheme) {
            filteredIds.push(sectionRequirementTheme.sectionRequirementId);
            processedSectionIds.push(sectionRequirementTheme.sectionRequirementId);
          }
        }
      }
    }

    return filteredIds;
  }

  protected async setflatRenderedTextSize(): Promise<void> {
    if (this._ids && this._ids.length) {
      const state = this.state,
        data4worker: ToWorker = [WorkerMessageType.flatRenderedTextSize, this._ids],
        promise = makeWorkerPromise(data4worker, flatRenderedTextSizeStore);

      await promise.then((map) => (state.flatRenderedTextSize = map));
    }
  }
}
