import { delay } from 'lodash';

import { NodeId } from '../../types/json-node.type';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';

function selectById( sectionNodeId: NodeId, navigateIdAfterLoadSection?: NodeId): void {
    const tocService = standardStateStore.getState().tocService;
    if ( !tocService ) return;

    const navigateToc = () => {
        tocService.selectById( { sectionNodeId, navigateIdAfterLoadSection } );
    };
    delay( navigateToc, 0 );
}


export { selectById };
