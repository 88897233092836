import React from 'react';
import { JsonNodeType } from '../../types/json-node.type';


export interface ColgroupProp {
    cols: JsonNodeType[];
}

function Colgroup( arg: ColgroupProp ): JSX.Element {

    const colsHtml = arg.cols.map( ( col ) => {
        const span = col.a[ 'span' ],
            spanNr = Number( span ),
            spanChecked = isNaN( spanNr ) ? undefined : spanNr;

        const width = col.a[ 'width' ];
        let columnWidth;

        // Replace trailing asterisk character with percent.
        if (width?.endsWith( '*' )) {
            columnWidth = width.replace( /.$/, '%' );
        }

        // We only want to include percentage widths.
        if (width?.endsWith( '%' )) {
            columnWidth = width;
        }

        return (
            <col key={ col.id } span={ spanChecked } width={ columnWidth }/>
        );
    } );

    return (
        <colgroup>{ colsHtml }</colgroup>
    );
}

export { Colgroup };
