import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';

function TableCaptionLabel(arg: ComponentArg): JSX.Element {
  return <>{defaultChildren(arg)}&nbsp;&mdash;&nbsp;</>;
}

function TableCaptionTitle(arg: ComponentArg): JSX.Element {
  return <>{defaultChildren(arg)}</>;
}

function TableCaptionUnits(arg: ComponentArg): JSX.Element {
  return <div className="table-caption-units">{defaultChildren(arg)}</div>;
}

function TableCaption(arg: ComponentArg): JSX.Element {
  return <caption>{defaultChildren(arg)}</caption>;
}

function TableCaptionWithButton(
  arg: ComponentArg & {
    onClick: React.MouseEventHandler;
    ariaExpanded: boolean;
    tableId: string;
  }
): JSX.Element {
  return (
    <caption>
      <button
        className="table-toggle"
        type="button"
        onClick={arg.onClick}
        aria-expanded={arg.ariaExpanded}
        aria-controls={`#${arg.tableId}`}
      >
        {defaultChildren(arg)}
      </button>
    </caption>
  );
}

export {
  TableCaption,
  TableCaptionLabel,
  TableCaptionTitle,
  TableCaptionUnits,
  TableCaptionWithButton,
};
