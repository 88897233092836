import { Action } from 'redux';

export interface IdentityState<Content> {
    content?: Content;
}

export interface IdentityAction<Content> extends Action {
    content: Content;
}

function identityReducer<Content>(
    _previousState: IdentityState<Content> = {},
    action: IdentityAction<Content> ): IdentityState<Content> {

    return { content: action.content };
}

export { identityReducer };
