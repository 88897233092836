import React from 'react';
import Footnote from '../../../features/footnote';
import { ComponentArg } from '../../../types/component-arg';
import { LazyBase } from '../../lazy/lazy-base';
import { CreateCssBuilder } from '../../util/cssBuilder';

export class SecGeneric extends LazyBase<{}> {
  protected renderDialog(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return null;
  }

  protected get isSection(): boolean {
    return true;
  }

  protected get divClass(): string {
    let contentType = '';
    if (this.props.node && this.props.node.a['sec-type']) {
      contentType = this.props.node.a['sec-type'];
    }

    const hasTitle = (component: ComponentArg): boolean => {
      const node = component.node!.c;
      const titleComponent = node.filter((x) => 'title' === x.x!.tag);

      if (titleComponent.length === 0) {
        return false;
      }

      var result = titleComponent[0].x!.c.filter((x) => x.x == null).length > 0;
      return result;
    };

    const cssBuilder = CreateCssBuilder();
    cssBuilder.AddClass('sts-section');
    cssBuilder.AddClass(contentType);
    cssBuilder.AddClass(hasTitle(this.props) ? 'hasTitle' : '');
    return cssBuilder.Build();
  }

  protected renderFootnote(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return <Footnote elementKey={parentId} arg={this.props}></Footnote>;
  }
}
