import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function array( arg: ComponentArg ): JSX.Element {
    return (
        <div className="sts-array" key={ key( arg ) }>
            <div className="sts-array-content">{ defaultChildren( arg ) }</div>
        </div>);
}

export { array };
