/// <reference path="../../../node_modules/@types/mathjax/index.d.ts" />
import React, { Component, RefObject } from 'react';
import { Unsubscribe } from 'redux';
import { scrollSubject } from '../../redux/identity/scrollSubject';
import { ComponentArg } from '../../types/component-arg';
import { key } from '../util/key';
import { isWithinScrollThreshold } from '../lazy/inView';
import { scrollToLastElement } from '../../features/scroll/actions';

interface MathState {
    display: boolean;
}

class Math extends Component<ComponentArg, MathState> {
    constructor( p: ComponentArg ) {
        super( p );
        this.state = { display: false };
        this.contentRef = React.createRef();
    }

    contentRef: RefObject<HTMLDivElement>;
    mathWasRendered: boolean = false;
    innerHTML?: string;
    scrollUnsubscribe: Unsubscribe;

    private async extractDataFromProps() {
        if ( !this.props.node || !this.props.node.c ) {
            return;
        }
        if ( this.props.node.c.length ) {
            const mathMl = this.props.node.c[ 0 ].t;
            if ( mathMl ) {
                this.innerHTML = mathMl;
            }
        }
    }

    componentDidMount() {
        this.scrollUnsubscribe = scrollSubject.subscribe( this.checkComponentVisibility.bind( this ) );
        this.extractDataFromProps().then();
        this.checkComponentVisibility();
    }

    componentWillUnmount(): void {
        this.scrollUnsubscribe();
    }

    private checkComponentVisibility() {
        const ref: HTMLDivElement | null = this.contentRef.current;
        if ( !ref ) return;
        const isInViewport = isWithinScrollThreshold( ref );
        if ( this.innerHTML && isInViewport && !this.state.display ) {
            this.setState( { display: true } );
        }
    }

    componentDidUpdate( prevProps: Readonly<ComponentArg>, prevState: Readonly<MathState>, snapshot?: any ): void {
        if ( !prevState.display && this.state.display ) {
            this.renderMath();
            scrollToLastElement();
        }
    }

    private renderMath() {
        if (!this.innerHTML || this.mathWasRendered)
            return;
        
        if (!MathJax.Hub)
            return;

        MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.contentRef.current]);
    }

    private formula() {
        const key1 = key( this.props ),
            { display } = this.state;

        return display ? 
        (<span className="formula inline" key={key1} ref={this.contentRef} dangerouslySetInnerHTML={this.mathMlMarkup}/>): 
         (<div className="formula" key={key1} ref={this.contentRef}/>);
    }

    render() {
        const { display } = this.state;
            return display ? 
            (<span className="formula-container inline">{this.formula()}</span>): 
             (<div className="formula-container">{this.formula()}</div>);
    }
    private get mathMlMarkup() {
        return { __html: this.innerHTML! };
    }
}

export { Math };
