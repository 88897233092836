import { TagEnum } from "./tag-enum";

export type NodeId = string;

export interface NodeIdTextSize {
    nodeId: NodeId;
    textChildrenSize: number;
}

export interface JsonNodeType {
    id: NodeId;
    tag: string; // 	xml tag e.g. standard
    a: { [key: string]: string }; // 	attributes map
    c: MixedContent[];
    parentSectionId?: string;
}

export interface JsonNodeTypeBranch extends JsonNodeType {
    isTop?: boolean; //	this node is at the top (split out of parent)
}
export interface MixedContent {
    e: MixedContentEnum;
    t?: string; // 	text content
    x?: JsonNodeType; // 	child node
}

export enum MixedContentEnum {
    Skip = 0, // 	server should never include skipped content  in the serialized json shipped to browser
    XmlNode = 1,
    Text = 2,
}

function isText(mc: MixedContent): boolean {
    return mc.e === MixedContentEnum.Text;
}

function isNode(mc: MixedContent): boolean {
    return mc.e === MixedContentEnum.XmlNode;
}

function getByTag(node: JsonNodeType | undefined, tag: TagEnum, vocab: string | undefined): JsonNodeType[] {
    let result: JsonNodeType[] = [];

    if (!nodeHasContent(node)) {
        return result;
    }

    node!.c.forEach( ( mixedContent: MixedContent, siblingIdx: number ) => {
        const internalNode = mixedContent.x;
        if (internalNode && internalNode.tag === tag) {

            if (vocab) {
                if (internalNode.a['vocab'] === vocab) {
                    result.push(internalNode);
                }
            }
            else {
                result.push(internalNode);
            }
        }
        else {
            result.push(...getByTag(internalNode, tag, vocab));
        }
    } );

    return result;
}

function getById(node: JsonNodeType | undefined, id: string) : JsonNodeType[] {
    let result: JsonNodeType[] = [];

    if (!nodeHasContent(node)) {
        return result;
    }

    const internalNode = node!;

    if (internalNode.id === id) {
        result.push(internalNode);
        return result;
    }

    internalNode.c.forEach( ( mixedContent: MixedContent, siblingIdx: number ) => {
        result.push(...getById(mixedContent.x, id));
    } );

    return result;
}

function getByAttribute(node: JsonNodeType | undefined, name: string, value: string) : JsonNodeType[] {
    let result: JsonNodeType[] = [];

    if (!nodeHasContent(node)) {
        return result;
    }

    const internalNode = node!;

    if (internalNode.a[name] === value) {
        result.push(internalNode);
        return result;
    }

    internalNode.c.forEach((mixedContent: MixedContent) => {
        result.push(...getByAttribute(mixedContent.x, name, value ));
    } );

    return result;
}


function nodeHasContent(node: JsonNodeType | undefined): boolean {
    return !(!node || !node.c);
}

export { isText, isNode, getByTag, getById, getByAttribute };
