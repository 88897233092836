const queryStrings = {
  externalClient: 'externalClient',
  filePath: 'filePath',
  langUI: 'langUI',
  isPublishPreview: 'isPublishPreview',
  isPrint: 'isPrint',
  fileType: 'fileType',
  organization: 'organization',
  publicationUrn: 'publicationUrn',
};

export { queryStrings };
