import * as Stream from 'stream';

abstract class DispatchStream<Message> {
    constructor() {
        this.initStreams();
    }

    protected abstract act( m: Message ): void;

    dispatch( m: Message ): void {
        this._streamReader.push( m );
    }

    private _streamReader: Stream.Readable;
    private _streamWriter: Stream.Writable;

    private initStreams(): void {
        this._streamReader = new Stream.Readable( { objectMode: true, read() {} } );
        const actor = this.act.bind( this );
        this._streamWriter = new Stream.Writable( {
            objectMode: true,
            write( data, _, done ) {
                if ( data ) {
                    actor( data );
                }
                done();
            }
        } );

        this._streamReader.pipe( this._streamWriter );
    }
}

export { DispatchStream };
