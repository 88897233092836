import React from 'react';
import { legalImage, privateImage } from './image-data';

interface Props {
  vocab: string;
}

const Counterparty = ({ vocab }: Props) => {
  let src = '';
  switch (vocab) {
    case 'legal':
      src = legalImage;
      break;
    case 'private':
      src = privateImage;
      break;
    default:
      console.error(`EForm Counterparty does not support vocab: ${vocab}`);
      break;
  }

  return (
    <div>
      <img src={src} alt={`counterparty-${vocab}`} />
    </div>
  );
};

export default Counterparty;
