/* eslint-disable camelcase */

import { UserManagerSettings } from "oidc-client-ts";
import { isEnquiryEnabled } from "../util/checkConfig";
import { getAppConfiguration } from "../../redux/store";

const getScopes = (): string => {
    const commonScopes = 'openid profile standards_viewer_api offline_access';
    const additionalEnquiryScopes = 'enquiry_api roles';

    if (isEnquiryEnabled()) {
        return `${commonScopes} ${additionalEnquiryScopes}`;    
    }

    return commonScopes;
}

const getConfigParams = () => {
    const appConfig = getAppConfiguration()

    const stsServerUrl = appConfig.stsServerUrl,
        standardsViewerWebAppUrl = appConfig.standardsViewerWebAppUrl,
        accessTokenExpiringNotificationTimeInSeconds = appConfig.accessTokenExpiringNotificationTime,
        checkSessionIntervalInSeconds = appConfig.checkSessionInterval;

    return {
        stsServerUrl,
        standardsViewerWebAppUrl,
        accessTokenExpiringNotificationTimeInSeconds,
        checkSessionIntervalInSeconds
    }
}

export const getIdentityConfig  = () : UserManagerSettings => {
    const { 
        stsServerUrl,
        standardsViewerWebAppUrl,
        accessTokenExpiringNotificationTimeInSeconds,
        checkSessionIntervalInSeconds
    } = getConfigParams();

    return {
        authority: stsServerUrl!,
        client_id: 'sv_spa', 
        redirect_uri: standardsViewerWebAppUrl!, 
        automaticSilentRenew: true,
        loadUserInfo: true, 
        post_logout_redirect_uri: standardsViewerWebAppUrl + '/unauthorized', 
        response_type: 'code', 
        scope: getScopes(), 
        validateSubOnSilentRenew : false,
        includeIdTokenInSilentRenew : true,
        accessTokenExpiringNotificationTimeInSeconds,
        checkSessionIntervalInSeconds
    }
}

export const getMetadataOidc = () => {
    const { stsServerUrl } = getConfigParams();

    return {
        issuer: stsServerUrl,
        jwks_uri: stsServerUrl + '/.well-known/openid-configuration/jwks',
        authorization_endpoint: stsServerUrl + '/connect/authorize',
        token_endpoint: stsServerUrl + '/connect/token',
        userinfo_endpoint: stsServerUrl + '/connect/userinfo',
        end_session_endpoint: stsServerUrl + '/connect/endsession',
        check_session_iframe: stsServerUrl + '/connect/checksession',
        revocation_endpoint: stsServerUrl + '/connect/revocation',
        introspection_endpoint: stsServerUrl + '/connect/introspect',
    }
}
