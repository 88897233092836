import { createStore, Reducer, Store } from 'redux';
import { IdentityAction, identityReducer, IdentityState } from './identityReducer';

export type ScrollY = number;

type S = IdentityState<ScrollY>;
type A = IdentityAction<ScrollY>;
const promptScrollYSubject: Store<S, A> = createStore( identityReducer as Reducer );

export { promptScrollYSubject };
