const
    topRibbonHeightDekstop = 70,
    topRibbonHeightMobile = 50,
    minBufferTextSize = 3000,
    minBufferSizePixels = 1500,
    maxBufferSizePixels = minBufferSizePixels * 5,
    scrollDelay = 1500,
    closeLeftMenuWait = 250,
    highlightToCthrottle = 350,
    textSizeToPixelHeightRatio = 1,
    preventTrimForSectionsOfMinSize = maxBufferSizePixels / textSizeToPixelHeightRatio,
    horizontalSwipeThreshold = 20,
    verticalSwipeThreshold = 10,
    debugSectionsRendered = false,
    workerPath = '/standards-viewer-worker.bundle.js';

export {
    topRibbonHeightDekstop,
    topRibbonHeightMobile,
    minBufferTextSize,
    minBufferSizePixels,
    maxBufferSizePixels,
    scrollDelay,
    closeLeftMenuWait,
    highlightToCthrottle,
    textSizeToPixelHeightRatio,
    preventTrimForSectionsOfMinSize,
    horizontalSwipeThreshold,
    verticalSwipeThreshold,
    debugSectionsRendered,
    workerPath
};
