import React from 'react';
import { TagEnum } from '../../../types/tag-enum';
import { LazyBase } from '../../lazy/lazy-base';
import { getAppConfiguration } from '../../../redux/store';

class Metadata extends LazyBase<{}> {
  protected renderDialog(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return null;
  }

  protected get divClass(): string {
    return '';
  }

  protected renderFootnote(
    componentToRender: JSX.Element | null,
    parentId: string,
  ): JSX.Element | null {
    return null;
  }

  render(): React.ReactNode {
    const { node, t } = this.props;
    if (!node) {
      return null;
    }

    const appConfig = getAppConfiguration();
    const validMedataLanguages = appConfig.features?.enquiry?.validMetadataLanguages?.split(',') ?? [];

    const titleNodes = node.c.filter((child) => {
      if (!child.x || !child.x.tag || !child.x.a) {
        return false;
      }

      if (child.x?.tag !== TagEnum.TitleWrap) {
        return false;
      }

      const language = child.x ? child.x.a['xml:lang'] : '';
      return language && validMedataLanguages.includes(language.toUpperCase());
    });

    if (titleNodes.length < 2) {
      // We don't want to comment on titles if there is only one title, since that is the original document title
      // And we care about commenting on translations
      return null;
    }

    return (
      <div className="sts-section-hasTitle" ref={this.lazyDiv}>
        <div className="sts-section hasTitle" id={node.id}>
          <div className="titleWrap">
            <h1 className="titleHeading1">
              <span className="default-text">
                <span className="mixed-content-text">{t('MetadataTitle')}</span>
              </span>
            </h1>
          </div>
          {titleNodes.map((titleNode) => {
            if (!titleNode.x) {
              return null;
            }

            const language = titleNode.x.a['xml:lang'];
            const content =
              titleNode.x.c.find((c) => c.x?.tag === 'full')?.x?.c[0].t ?? '';
            return (
              <div className="sts-p" key={language} id={titleNode.x.id}>
                <span className="default-text">
                  <span className="mixed-content-text">
                    <b style={{ textTransform: 'uppercase' }}>{language}</b>:{' '}
                    {content}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Metadata;
