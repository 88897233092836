import React from 'react';
import { ComponentArg } from '../../../../types/component-arg';
import { defaultChildren } from '../../../generic/default-children';

const supportedCategories: string[] = ['unit-category', 'specification', 'keyword'];

export interface UnitCategoryProps {
  component: ComponentArg;
  category: string;
}

const Category = ({ component, category }: UnitCategoryProps) => {
  return <div className={category}>{defaultChildren(component)}</div>;
};

export const IsSupportedCategory = (category: string): boolean => {
  const foundCategories = supportedCategories.filter((c) => c === category);
  const result = foundCategories.length > 0;
  return result;
}

export default Category;
