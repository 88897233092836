import React from "react";
import { isSdCommerce } from '../../util/checkConfig';
import { ReportError } from "./report-error";
import { useTranslation } from "react-i18next";

export interface DocumentNotFoundProp {
    errorId: string;
}

const DocumentNotFound = ({ errorId }: DocumentNotFoundProp) => {

    const { t } = useTranslation();
    const translationKey = isSdCommerce() ? 'Errors.DocumentNotExist' : 'Errors.DocumentNotExistOrNoAccess';

    return (
        <div className="errorMessage">
            <h1>{t('Errors.DocumentNotFound')}</h1>
            <p dangerouslySetInnerHTML={{__html: t(translationKey)}}/>
            <p>{t('Errors.DocumentReopen')}</p>
            <ReportError errorId={errorId} />
        </div>
    )
}

export { DocumentNotFound }