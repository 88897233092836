import { createStore, Reducer, Store } from 'redux';
import { last } from 'lodash';

import { ScrollAction, scrollReducer, ScrollState } from './scrollReducer';
import { ScrollNotification } from '../../types/lazy.type';

const scrollSubject: Store<ScrollState, ScrollAction> = createStore( scrollReducer as Reducer );

function lastScrollNotification(): ScrollNotification | undefined {
    const state = scrollSubject.getState().content;
    if ( state.length > 0 ) {
        return last( state );
    }
}

export enum ScrollDirection {
    Level = 'Level',
    Up = 'Up',
    Down = 'Down'
}

export enum SizeChange {
    Unchanged = 'Unchanged',
    Expand = 'Expand',
    Shrink = 'Shrink'
}

export interface ScrollTrend {
    direction: ScrollDirection
    height: SizeChange
}

function scrollTrend(): ScrollTrend {
    const state = scrollSubject.getState().content,
        length = state.length,
        lastIdx = state.length - 1,
        prevIdx = state.length - 2,
        result = {
            direction: ScrollDirection.Level,
            height: SizeChange.Unchanged
        };

    if ( length < 2 ) {
        return result;
    }

    const last1 = state[ lastIdx ],
        prev1 = state[ prevIdx ];

    if ( last1.scrollTop > prev1.scrollTop ) {
        result.direction = ScrollDirection.Down;
    }

    if ( last1.scrollTop < prev1.scrollTop ) {
        result.direction = ScrollDirection.Up;
    }

    if ( last1.scrollHeight > prev1.scrollHeight ) {
        result.height = SizeChange.Expand;
    }

    if ( last1.scrollHeight < prev1.scrollHeight ) {
        result.height = SizeChange.Shrink;
    }

    return result;
}

export { scrollSubject, lastScrollNotification, scrollTrend };
