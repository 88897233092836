import React from 'react';
import { signaturesImage } from './image-data';

const Signatures = () => {
  return (
    <div>
      <img src={signaturesImage} alt="signatures" />
    </div>
  );
};

export default Signatures;
