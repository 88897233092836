import { validateCopyAvailability } from '../../component/util/checkConfig';
import { NodeId } from "../../types/json-node.type";
import { SectionRequirementThemes } from "../../types/section-requirement-themes";
import { standardStateStore } from "./standard-state.store";

function getSectionRequirementThemes(): SectionRequirementThemes[] {
  return standardStateStore.getState().graphService!.productInfo!.sectionRequirementThemes;
}

function getSectionRequirementTheme(id: NodeId): string[] {
  const themes = standardStateStore.getState().graphService!.productInfo!.sectionRequirementThemes;
  const result: string[] = []
  themes
    .filter((value) => value.sectionId === id || value.sectionRequirementId === id)
    .map((value) => value.themes)
    .forEach((themes) => result.push(...themes));

    return result;
}

function getProductId(): string {
  const result = standardStateStore.getState().requestUrlData!.productId!
  return result;
}

function getFavoriteRequirementIds(): string[] {
  const result = standardStateStore.getState().graphService!.productInfo!.favouritedRequirementIds;
  return result;
}

function isCopyAvailable(): boolean {
  if (validateCopyAvailability() === false)
  {
    return true;
  }

  const graphService = standardStateStore.getState().graphService!;
  const canCopyText = graphService.productInfo && graphService.productInfo.availableRights!.canCopyText;
  const result = canCopyText ?? false;
  return result;
}

export { getSectionRequirementThemes, getSectionRequirementTheme, getProductId, getFavoriteRequirementIds, isCopyAvailable }