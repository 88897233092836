import React from 'react';
import { defaultChildren } from '../generic/default-children';
import { LazyBase } from '../lazy/lazy-base';
import { key } from '../util/key';

export class RefList extends LazyBase<{}> {
    protected renderDialog(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }

    render() {
        if ( !this.shouldDisplay ) {
            return null;
        }

        const id = key( this.props );

        return (
            <div className={ this.divClass } key={ id } id={ id }>
                <ul className="sts-ref-list">{ defaultChildren( this.props ) }</ul>
            </div>
        );
    }

    protected get divClass(): string {
        return 'sts-section sts-ref-list';
    }

    protected renderFootnote(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null {
        return null;
    }
}
