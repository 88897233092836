import React from 'react';
import {
  realestateSimple,
  realestateMedium,
  realestateConsumer,
  realestateSsb,
} from './image-data';

interface Props {
  vocab: string;
}

const Realestate = ({ vocab }: Props) => {
  let src = '';
  switch (vocab) {
    case 'simple':
      src = realestateSimple;
      break;
    case 'medium':
      src = realestateMedium;
      break;
    case 'consumersettings':
      src = realestateConsumer;
      break;
    case 'ssbsettings':
      src = realestateSsb;
      break;
    case 'full':
      src = '';
      break;
    default:
      console.error(`EForm RealEstate does not support vocab: ${vocab}`);
      break;
  }

  return (
    <div>
      <img src={src} alt={`realestate-${vocab}`} />
    </div>
  );
};

export default Realestate;
