import React from 'react';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { Themes } from './themes';
import { TitleBase } from './title-base';
import { TitleType } from './title-base';

class Title extends TitleBase {

    render(): JSX.Element | null {
        const arg = this.props,
            key1 = key( arg ),
            sectionId = this.state.sectionId!;        

        switch ( this.state.titleType ) {
            case TitleType.H1:
                return (                    
                    <div className="titleWrap">
                        <h1 className="titleHeading1" key={ key1 }>{ defaultChildren( arg ) }</h1>
                        <Themes sectionRequirementId={sectionId}/>
                    </div>          
                );
            case TitleType.H2:
                return (                    
                    <div className="titleWrap">
                        <h2 className="titleHeading2" key={ key1 }>{ defaultChildren( arg ) }</h2>
                        <Themes sectionRequirementId={sectionId}/>
                    </div>
                );
            case TitleType.H3:
                return ( 
                    <div className="titleWrap"> 
                        <h3 className="titleHeading" key={ key1 }>{ defaultChildren( arg ) }</h3>
                        <Themes sectionRequirementId={sectionId}/>
                    </div> 
                );
            case TitleType.H4:
                return ( 
                    <div className="titleWrap">  
                        <h4 className="titleHeading" key={ key1 }>{ defaultChildren( arg ) }</h4>
                        <Themes sectionRequirementId={sectionId}/>
                    </div>
                );
            case TitleType.H5:
                return ( 
                    <div className="titleWrap">  
                        <h5 className="titleHeading" key={ key1 }>{ defaultChildren( arg ) }</h5>
                        <Themes sectionRequirementId={sectionId}/>
                    </div>
                );
            case TitleType.H6:
                return ( 
                    <div className="titleWrap">  
                        <h6 className="titleHeading" key={ key1 }>{ defaultChildren( arg ) }</h6>
                        <Themes sectionRequirementId={sectionId}/>
                    </div>
                );
            case TitleType.Annex:
                return ( <h1 className="sts-app-header"
                            key={ key1 }>{ defaultChildren( arg ) }</h1> );
            case TitleType.Caption:
                return ( <span className="sts-caption-title"
                            key={ key1 }>{ defaultChildren( this.props ) }</span> );
            case TitleType.DefList:
                return ( <div className="term-title"
                            key={ key1 }>{ defaultChildren( this.props ) }</div> );
            case TitleType.Std:
                return ( <span className="std-title"
                            key={ key1 }><i>{ defaultChildren( this.props ) }</i></span> );
            case TitleType.Unknown:
                return defaultChildren( arg );
            default:
                return <h1 className="titleHeading1">&nbsp;</h1>;
        }
    }
}

export { Title };
