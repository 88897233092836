import { Button } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ScreenReaderOnly from '../../../../../component/util/screenReaderOnly';
import { isExternalLink } from '../../../util/util';
import styles from './DropdownItem.module.less';

export type DropdownOption = {
  value: string | number,
  label: string,
  url?: string,
  onClick?: React.MouseEventHandler<Element>,
}

type DropdownItemProps = {
  option: DropdownOption,
  index: number,
  onFocus: React.FocusEventHandler,
} 

const DropdownItem = ({ option: { label, url, onClick }, onFocus }: DropdownItemProps) => {
  const { t } = useTranslation();

  if (url && isExternalLink(url)) {
    return (
      <a 
        href={url} 
        onFocus={onFocus} 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.menuLink}
      >
        {label}
        <ScreenReaderOnly>{ t('External link') }</ScreenReaderOnly>
      </a>
    );
  } else if (url) {
    return <Link to={url} onFocus={onFocus} className={styles.menuLink}>{label}</Link>
  } else if (onClick) {
    return (
      <Button 
        onClick={onClick} 
        onFocus={onFocus} 
        className={styles.menuButton}
        size="large"
      >
        {label}
      </Button>
    );
  } else {
    return <div tabIndex={0} onFocus={onFocus} className={styles.menuDiv}>
      {label}
    </div>
  }
}

export default DropdownItem;
