import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';

function MathWrapper( arg: ComponentArg ): JSX.Element {
    return (
        <div className="math-wrapper">
            <span className="math-inner">
                {defaultChildren( arg ) }
            </span>
        </div>
    );
}

export { MathWrapper };