import { put, select, call } from 'redux-saga/effects';
import { StandardState, StandardStateActionType } from './standard-state.types';
import { UpdateSectionService } from '../../component/lazy/update-section.service';
import { GraphService } from '../../graph/graph.service';
import { TocService } from '../../component/lazy/toc.service';
import { InitLazyStateStandard } from '../../component/lazy/init-state/init-lazy-state.standard';
import { ScrollService } from '../../component/lazy/scroll.service';
import { productInfoLoadedSubject } from '../identity/productInfoLoadedSubject';
import {
  LoadingSpinnerNotification,
  loadingSpinnerSubject,
} from '../identity/loadingSpinnerSubject';
import { StandardDto } from '../../types/standard-dto';
import { InitLazyStateTables } from '../../component/lazy/init-state/init-lazy-state.tables';
import { InitLazyStateFigures } from '../../component/lazy/init-state/init-lazy-state.figures';
import { InitLazyStateRequirements } from '../../component/lazy/init-state/init-lazy-state.requirements';
import { SearchService } from '../../component/side-bar/search-document/search.service';
import * as assert from 'assert';
import { emptyJson } from '../../test-util/mockStandard';
import { InitLazyStateThemes } from '../../component/lazy/init-state/init-lazy-state.themes';
import { CollectionDto } from '../../types/collection-dto';
import { InitLazyStateCommentables } from '../../component/lazy/init-state/init-lazy-state.commentables';

function* initGraphInstance(standardDto: StandardDto) {
  let graph: GraphService;
  if (!standardDto.standardContent) {
    const standardDtoMock: StandardDto = {
      productInfo: {
        tables: [],
        figures: [],
        figureMetadata: [],
        themes: [],
        requirements: [],
        partialEnquiries: [],
        requirementTypeCounts: [],
        favouritedRequirementIds: [],
        sectionRequirementThemes: [],
        openForCommenting: false,
      },
      standardContent: emptyJson(),
      pdfContentBase64: standardDto.pdfContentBase64,
      messageType: standardDto.messageType,
    };

    graph = new GraphService(standardDtoMock.standardContent!);
  } else {
    graph = new GraphService(standardDto.standardContent);
  }
  graph.productInfo = { ...standardDto.productInfo };
  yield put({ type: StandardStateActionType.setGraphService, content: graph });
}

function* initGraphInstanceCollection(collectionDto: CollectionDto) {
  let graph: GraphService;
  if (!collectionDto.collectionContent) {
    const collectionDtoMock: CollectionDto = {
      productInfo: {
        tables: [],
        figures: [],
        figureMetadata: [],
        themes: [],
        requirements: [],
        partialEnquiries: [],
        requirementTypeCounts: [],
        favouritedRequirementIds: [],
        sectionRequirementThemes: [],
        openForCommenting: false,
      },
      collectionContent: emptyJson(),
      standards: [
        {
          productInfo: {
            tables: [],
            figures: [],
            figureMetadata: [],
            themes: [],
            requirements: [],
            partialEnquiries: [],
            requirementTypeCounts: [],
            favouritedRequirementIds: [],
            sectionRequirementThemes: [],
            openForCommenting: false,
          },
          standardContent: emptyJson(),
          pdfContentBase64: '',
          messageType: '',
        },
      ],
    };

    graph = new GraphService(collectionDtoMock.collectionContent!);
  } else {
    graph = new GraphService(collectionDto.collectionContent);
  }
  graph.productInfo = { ...collectionDto.productInfo };
  yield put({ type: StandardStateActionType.setGraphService, content: graph });
}

function* initClassInstances() {
  const graph: GraphService = yield select((s: StandardState) => s.graphService),
    standardDto: StandardDto = yield select((s: StandardState) => s.standardDto),
    collectionDto: CollectionDto = yield select(
      (s: StandardState) => s.collectionDto,
    ),
    updateSection: UpdateSectionService = new UpdateSectionService(),
    toc: TocService = new TocService(updateSection, graph),
    search: SearchService = new SearchService(
      graph,
      collectionDto?.collectionContent ?? standardDto.standardContent!,
    ),
    standardState: StandardState = yield select();

  assert.ok(graph, 'falsey graph');
  yield initLazyState(graph, toc, standardState);

  new ScrollService(updateSection);

  yield put({ type: StandardStateActionType.setSearchService, content: search });
  yield put({ type: StandardStateActionType.setTocService, content: toc });
  if (standardDto) {
    if (standardDto.pdfContentBase64) {
      yield put({
        type: StandardStateActionType.setPdfContentBase64,
        content: standardDto.pdfContentBase64,
      });
    }

    yield put({
      type: StandardStateActionType.setMessageType,
      content: standardDto.messageType,
    });
  }

  productInfoLoadedSubject.dispatch({ type: null, content: null });

  const loadingSpinner: LoadingSpinnerNotification = { visible: false };
  loadingSpinnerSubject.dispatch({ type: null, content: loadingSpinner });

  toc.documentLoad.call(toc);

  return true;
}

function* initLazyState(
  graph: GraphService,
  toc: TocService,
  standardState: StandardState,
) {
  const st = new InitLazyStateStandard(graph, standardState.lazyStateStandard);
  yield call([st, st.initData]);

  const tab = new InitLazyStateTables(graph, standardState.lazyStateTables);
  yield call([tab, tab.initData]);

  const fig = new InitLazyStateFigures(graph, standardState.lazyStateFigures);
  yield call([fig, fig.initData]);

  const initLazyStateThemes = new InitLazyStateThemes(
    graph,
    standardState.lazyStateThemes,
  );
  yield call([initLazyStateThemes, initLazyStateThemes.initData]);

  const initLazyStateRequirements = new InitLazyStateRequirements(
    graph,
    standardState.lazyStateRequirements,
  );
  yield call([initLazyStateRequirements, initLazyStateRequirements.initData]);

  const comm = new InitLazyStateCommentables(
    graph,
    standardState.lazyStateCommentables,
  );
  yield call([comm, comm.initData]);

  yield put({
    type: StandardStateActionType.setInitLazyStateRequirements,
    content: initLazyStateRequirements,
  });

  yield put({
    type: StandardStateActionType.setInitLazyStateThemes,
    content: initLazyStateThemes,
  });
}

export { initGraphInstance, initGraphInstanceCollection, initClassInstances };
