import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function sub( arg: ComponentArg ): JSX.Element {
    return <sub key={ key( arg ) }>{ defaultChildren( arg ) }</sub>;
}

export { sub };
