import { createSlice } from '@reduxjs/toolkit';

export interface ComponentId {
  key: string;
}

export class ComponentIdImpl {
  key: ComponentId;

  constructor(key: ComponentId) {
    this.key = key;
  }

  equals(objB: ComponentId) {
    if (objB == null) {
      return false;
    }

    return this.key.key === objB.key;
  }
}

export class HiddenComponents {
  _components: ComponentIdImpl[];

  constructor() {
    this._components = [];
  }

  hideItem(nodeId: ComponentId) {
    if (nodeId == null) {
      throw new RangeError(`nodeId should not be empty`);
    }

    if (nodeId.key === '') {
      return;
    }

    if (!this.valueContainsId(nodeId)) {
      this._components.push(new ComponentIdImpl(nodeId));
    }
  }

  valueContainsId(id: ComponentId): boolean {
    return this._components.filter((value) => value.equals(id)).length > 0;
  }

  toJson(): string {
    return JSON.stringify(this._components.map((x) => x.key));
  }

  loadJson(json: string) {
    const deserializedJson: ComponentId[] = JSON.parse(json);
    deserializedJson.forEach((value) =>
      this._components.push(new ComponentIdImpl(value))
    );
  }
}

function toReduxState(items: HiddenComponents): string {
  var result = items.toJson();
  return result;
}

export function fromReduxState(reduxState: string): HiddenComponents {
  var result = new HiddenComponents();

  if (reduxState === '') {
    return result;
  }

  result.loadJson(reduxState);
  return result;
}

export interface HiddenComponentsState {
  hiddenItems: string;
}

const initialState: HiddenComponentsState = {
  hiddenItems: '',
};

const hiddenComponents = createSlice({
  name: 'HiddenComponents',
  initialState,
  reducers: {
    hideComponent(state, action) {
      const payload = action.payload as ComponentId;
      const hiddenItems = fromReduxState(state.hiddenItems);
      hiddenItems.hideItem(payload);
      state.hiddenItems = toReduxState(hiddenItems);
    },
  },
});

export const { hideComponent } = hiddenComponents.actions;

export default hiddenComponents.reducer;
