import * as React from 'react';
import { getSupportedStandardsInNEKPlugin } from './../util/checkConfig';

type NekFaqProps = {
    productCode?: string;
};

const productCodeExists = (productCode: string) => getSupportedStandardsInNEKPlugin().indexOf(productCode) > -1;

const NekFaq = ({ productCode }: NekFaqProps) => {

    const nekProduct = productCode && productCodeExists(productCode) ? productCode : 'Alle standarder';
    const loadDynamicScript = () => {
        let dynamicScript = document.createElement('script');

        dynamicScript.addEventListener('load', () => {
            const loadEvent = new Event('load');
            window.dispatchEvent(loadEvent);
        });

        dynamicScript.nonce = 'EDNnf03nceIOfn39fn3e9h3sdfa';
        dynamicScript.src = 'https://www.nek.no/embedded/js/faq.js';
        dynamicScript.id = 'nek-embedded-faq-script';
        dynamicScript.setAttribute('data-nek-faq-category', nekProduct);
        dynamicScript.setAttribute('data-autoload-button', 'true');
        document.getElementsByTagName("head")[0].appendChild(dynamicScript);
    }

    React.useEffect(() => loadDynamicScript(), []);

    return (<></>)
};

export default NekFaq;
