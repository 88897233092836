import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { globalError, ErrorTypes } from "../../redux/show-error";
import { PageNotFound } from "./templates/page-not-found";
import { DocumentNotFound } from "./templates/document-not-found";
import '../../themes/sdTheme/styles/sdThemeStyles.css';
import SwitchableHeader from "../../component/switchable-header";
import { useParams } from 'react-router-dom';

export interface NotFoundProp {
}

const NotFound = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        globalError(ErrorTypes.GlobalError);
    }, []);
    return (
        <>
            <SwitchableHeader />
            <main className="viewerMain" aria-label={t('MainContent')}>
                <div className="wrapper">
                    {id ? <DocumentNotFound errorId={id} /> : <PageNotFound />}
                </div>
            </main>
        </>
    )
}

export { NotFound };
