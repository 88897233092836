import React, { Component, ReactNode, RefObject } from 'react';
import { Unsubscribe } from 'redux';

import { ComponentArg } from '../../types/component-arg';
import { isScrolledIntoView } from './inView';
import { sectionVisibleSubject } from '../../redux/identity/sectionVisibleSubject';
import { scrollSubject } from '../../redux/identity/scrollSubject';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { ViewportNotification } from '../../types/lazy.type';
import { promptScrollYSubject } from '../../redux/identity/promptScrollYSubject';
import { getActiveLazyState } from '../../redux/standard-state/lazyStateProxy';
import { NodeId } from '../../types/json-node.type';
import { scrollToLastElement } from '../../features/scroll/actions';

enum RelativeToViewport {
    Above = 'Above',
    Below = 'Below',
    Within = 'Within'
}

enum Signum {
    Positive = 'Positive',
    Negative = 'Negative'
}

export abstract class LazyBase<State> extends Component<ComponentArg, State> {
    constructor( prop: ComponentArg ) {
        super( prop );
        this.lazyDiv = React.createRef();
    }

    private _id: NodeId;
    private _isProtectedContent: boolean | null = null;

    get id(): NodeId {
        if ( !this._id ) {
            this._id = key( this.props );
        }
        return this._id;
    }

    get isProtectedContent(): boolean {
        if ( this._isProtectedContent === null ) {
            this._isProtectedContent = this.props.node?.a['is-protected-content'] === 'True';
        }
        return this._isProtectedContent!;
    }

    protected lastVisible: ViewportNotification = {
        visible: false,
        nodeId: this.id
    };
    protected unsubscribeScroll?: Unsubscribe;

    protected abstract get divClass(): string;
    protected abstract renderDialog(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null
    protected abstract renderFootnote(componentToRender: JSX.Element | null, parentId: string): JSX.Element | null
    protected get isSection(): boolean {
        return false;
    }

    get shouldDisplay(): boolean {
        return this.props.node !== undefined && !this.isProtectedContent && ( !this.isLazyLoadedSection || this.isTopNode );
    }

    private _isTopNode?: boolean;
    protected get isTopNode(): boolean {
        if ( this._isTopNode === undefined ) {
            const node = this.props.node;
            this._isTopNode = node && node.isTop;
        }
        return this._isTopNode || false;
    }

    private _isLazyLoadedSection?: boolean;
    protected get isLazyLoadedSection(): boolean {
        if ( this._isLazyLoadedSection === undefined ) {
            this._isLazyLoadedSection = getActiveLazyState().isLazyLoadedNode( this.id );
        }
        return this._isLazyLoadedSection || false;
    }

    protected get isLazyTop() {
        return this.isTopNode && this.isLazyLoadedSection;
    }

    lazyDiv: RefObject<HTMLDivElement>;

    protected subscribeScroll() {
        if ( this.isLazyTop ) {
            this.unsubscribeScroll = scrollSubject.subscribe( this.onScroll.bind( this ) );
        }
    }

    onScroll() {
        const viewportInfo = this.viewportInfo();

        if ( viewportInfo ) {
            sectionVisibleSubject.dispatch( {
                type: null,
                content: viewportInfo
            } );

            this.lastVisible = viewportInfo;
        }
    }

    private relativeToViewport(): RelativeToViewport {
        const rect = this.lazyDiv.current!.getBoundingClientRect(),
            sectionTop = rect.top,
            windowHeight = window.innerHeight;

        if ( sectionTop > windowHeight ) {
            return RelativeToViewport.Below;
        }

        if ( sectionTop < 0 ) {
            return RelativeToViewport.Above;
        }

        return RelativeToViewport.Within;
    }

    protected viewportInfo(): ViewportNotification | undefined {
        if ( !this.lazyDiv || !this.lazyDiv.current ) {
            return undefined;
        }

        const el = this.lazyDiv.current!,
            visible: boolean = isScrolledIntoView( el ),
            nodeId = this.id;

        return nodeId ? { nodeId, visible } : undefined;
    }

    componentDidUpdate(): void {
        this.notifyScrollY( Signum.Positive );
    }

    componentDidMount(): void {
        this.notifyScrollY( Signum.Positive );
        this.subscribeScroll();
        scrollToLastElement();
    }

    componentWillUnmount(): void {
        this.notifyScrollY( Signum.Negative );
        if ( this.unsubscribeScroll ) {
            if ( this.lastVisible ) {
                sectionVisibleSubject.dispatch( {
                    type: null,
                    content: { nodeId: this.id, visible: false }
                } );
            }
            this.unsubscribeScroll();
        }
    }

    private notifyScrollY( signum: Signum ) {
        if ( !this.shouldDisplay ) return;

        const el = this.lazyDiv.current;
        if ( el ) {
            const relativeViewport = this.relativeToViewport();
            if ( relativeViewport === RelativeToViewport.Above ) {
                let scroll = el.clientHeight;
                if ( signum === Signum.Negative ) {
                    scroll *= -1;
                }
                promptScrollYSubject.dispatch( { type: null, content: scroll } );
            }
        }
    }

    shouldComponentUpdate( nextProps: Readonly<ComponentArg>, nextState: Readonly<{}>, nextContext: any ): boolean {
        const arg = this.props;
        return !arg.node && !arg.mixedContent &&
            ( nextProps.node !== undefined || nextProps.mixedContent !== undefined );
    }

    render(): ReactNode {
        if ( !this.shouldDisplay ) {
            return null;
        }

        const children = defaultChildren( this.props );

        return <>
        <div className={this.divClass} id={this.id} ref={this.lazyDiv} key={this.id}>
            {children}
        </div>
        {this.renderDialog(children, this.id)}
        {this.renderFootnote(children, this.id)}
        </>
    }
}
