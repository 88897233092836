import { compareCustomerCode, isPublic, isSdCommerce } from "../component/util/checkConfig";
import { customerCodes } from "../constants/customer-codes";
import { languageCodes, languageNames } from "../constants/languages";
import { LanguageInfo } from "../types/language-info";

function getSupportedLanguages(): LanguageInfo[]  {

    const supportedLanguages: LanguageInfo[] = [];
    const english: LanguageInfo = { code: languageCodes.en, name: languageNames.english};

    const norwegian: LanguageInfo = { code: languageCodes.nb, name: languageNames.norwegian};
    if (compareCustomerCode(customerCodes.svv)) {
        supportedLanguages.push(norwegian);
    } else {
        supportedLanguages.push(english);
        if ((isSdCommerce() || isPublic()) && !compareCustomerCode(customerCodes.so)) {
            const french: LanguageInfo = { code: languageCodes.fr, name: languageNames.french};
            supportedLanguages.push(french);

            const german: LanguageInfo = { code: languageCodes.de, name: languageNames.german};
            supportedLanguages.push(german);
        } else {
            supportedLanguages.push(norwegian);
        }
    }
    return supportedLanguages;
}

export { getSupportedLanguages }