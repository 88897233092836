import React from 'react';
import { LazyBase } from '../lazy/lazy-base';
import { TableDialog } from './table-dialog';

class TableWrap extends LazyBase<{}> {
  protected renderDialog(
    componentToRender: JSX.Element | null,
    parentId: string
  ): JSX.Element | null {
    if (!componentToRender) {
      return null;
    }

    return <TableDialog children={componentToRender} tableKey={parentId} />;
  }

  protected get divClass(): string {
    let contentType = this.props!.node!.a['content-type'];
    return 'sts-table-wrap' + (contentType ? ' ' + contentType : '');
  }

  protected renderFootnote(
    componentToRender: JSX.Element | null,
    parentId: string
  ): JSX.Element | null {
    return null;
  }
}

export { TableWrap };
