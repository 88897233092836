import { filterDocumentStore } from ".";
import { RequirementType } from "../../types/requirement.type";
import { FilterDocumentState, ViewOnlyCategory } from "./filter-document.state";

function IsFilterApplied(): boolean {
  const filterState: FilterDocumentState = filterDocumentStore.getState();
  return filterState.filterIsApplied;
}

function CurrentFilteredCategory(): ViewOnlyCategory | undefined {
  if (!IsFilterApplied()) {
    return undefined;
  }

  const filterState: FilterDocumentState = filterDocumentStore.getState();

  return filterState.viewOnlyCategory;
} 

function selectedRequirementTypes(): RequirementType[] {
  if (!IsFilterApplied()) {
    return [];
  }

  if (!IsFilterCategoryRequirements()) {
    return [];
  }

  const filterState: FilterDocumentState = filterDocumentStore.getState();
  
  return Array.from(filterState.selectedRequirementTypes.values());
}

function IsFilterCategoryRequirements() {
  return CurrentFilteredCategory() === ViewOnlyCategory.requirements;
}

function IsFilterCategoryThemes() {
  return CurrentFilteredCategory() === ViewOnlyCategory.themes;
}

function selectedThemes() : string[] {
  if (!IsFilterApplied()) {
    return [];
  }

  if (!IsFilterCategoryThemes()) {
    return [];
  }

  const filterState: FilterDocumentState = filterDocumentStore.getState();
  
  return filterState.selectedThemes;
}

export {IsFilterApplied, CurrentFilteredCategory, selectedRequirementTypes, selectedThemes, IsFilterCategoryRequirements, IsFilterCategoryThemes}