import { NodeId, JsonNodeType } from './json-node.type';
import { CyNode } from './graph.type';
import { RequirementType } from './requirement.type';
import { DbType } from './db.type';
import { TocData } from './toc-data';
import { TextSearchResult, IdSiblingString, Position, SortedDisplayOrderEntry } from './search.type';
import { LazySerializableState } from '../component/lazy/lazy-serializable.state';

export enum WorkerMessageType {
  allScrollableSectionData = 'allScrollableSectionData',
  children = 'children',
  directChildren = 'directChildren',
  directParents = 'directParents',
  filteredRequirements = 'filteredRequirements',
  flatRenderedTextSize = 'flatRenderedTextSize',
  fullJson = 'fullJson',
  getById = 'getById',
  getClosestParentSectionById = 'getClosestParentSectionById',
  getClosestRequirementById = 'getClosestRequirementById',
  getClosestTableById = 'getClosestTableById',
  getClosestFigureById = 'getClosestFigureById',
  getClosestPartialEnquiryById = 'getClosestPartialEnquiryById',
  lazyState = 'lazyState',
  level = 'level',
  nodeOrder = 'nodeOrder',
  parents = 'parents',
  pathToId = 'pathToId',
  standardTocData = 'standardTocData',
  workerError = 'workerError',

  //    search
  calculateOwnMatches = 'calculateOwnMatches',
  calculateChildMatches = 'calculateChildMatches',
  calculateToc2Match = 'calculateToc2Match',
  calculateSortedInDisplayOrder = 'calculateSortedInDisplayOrder'
}

export type Uuid = string;

export interface WorkerMessage<Content> {
  requestId: Uuid
  content: Content
}

export type ToWorker =
  [WorkerMessageType.allScrollableSectionData, NodeId[]]
  | [WorkerMessageType.calculateChildMatches, Map<NodeId, number>]
  | [WorkerMessageType.calculateOwnMatches, TextSearchResult[]]
  | [WorkerMessageType.calculateSortedInDisplayOrder, Map<IdSiblingString, Position[]>]
  | [WorkerMessageType.calculateToc2Match, SortedDisplayOrderEntry[]]
  | [WorkerMessageType.children, [NodeId, string, DbType]]
  | [WorkerMessageType.directChildren, [NodeId, string, DbType]]
  | [WorkerMessageType.directParents, [NodeId, string, DbType]]
  | [WorkerMessageType.filteredRequirements, [NodeId[], Set<RequirementType>]]
  | [WorkerMessageType.flatRenderedTextSize, NodeId[]]
  | [WorkerMessageType.fullJson, [JsonNodeType, boolean, string[]]]
  | [WorkerMessageType.getById, NodeId]
  | [WorkerMessageType.getClosestParentSectionById, NodeId]
  | [WorkerMessageType.getClosestRequirementById, NodeId]
  | [WorkerMessageType.getClosestFigureById, NodeId]
  | [WorkerMessageType.getClosestTableById, NodeId]
  | [WorkerMessageType.getClosestPartialEnquiryById, NodeId]
  | [WorkerMessageType.lazyState, LazySerializableState]
  | [WorkerMessageType.level, NodeId]
  | [WorkerMessageType.parents, [NodeId, string, DbType]]
  | [WorkerMessageType.pathToId, NodeId]

export type FromWorker =
  [WorkerMessageType.allScrollableSectionData, JsonNodeType[]]
  | [WorkerMessageType.calculateChildMatches, Map<NodeId, number>]
  | [WorkerMessageType.calculateOwnMatches, Map<NodeId, number>]
  | [WorkerMessageType.calculateSortedInDisplayOrder, SortedDisplayOrderEntry[]]
  | [WorkerMessageType.calculateToc2Match, Map<NodeId, [NodeId, number]>]
  | [WorkerMessageType.children, CyNode[]]
  | [WorkerMessageType.directChildren, CyNode[]]
  | [WorkerMessageType.directParents, CyNode[]]
  | [WorkerMessageType.filteredRequirements, NodeId[]]
  | [WorkerMessageType.flatRenderedTextSize, Map<NodeId, number>]
  | [WorkerMessageType.getById, CyNode | null]
  | [WorkerMessageType.getClosestParentSectionById, NodeId | null]
  | [WorkerMessageType.getClosestRequirementById, NodeId | null]
  | [WorkerMessageType.getClosestFigureById, NodeId | null]
  | [WorkerMessageType.getClosestTableById, NodeId | null]
  | [WorkerMessageType.getClosestPartialEnquiryById, NodeId | null]
  | [WorkerMessageType.level, number]
  | [WorkerMessageType.nodeOrder, Map<NodeId, number>]
  | [WorkerMessageType.parents, CyNode[]]
  | [WorkerMessageType.pathToId, CyNode[]]
  | [WorkerMessageType.standardTocData, TocData[]]
  | [WorkerMessageType.workerError, string[]]
