import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

const LoadingComponent = ({ active, children }) => {
    return (
      <LoadingOverlay
        active={active}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: '100px',
            '& svg circle': {
              stroke: '#1841aa'
            }
          }),
          overlay: (base) => ({
            ...base,
            background: '#f5f5f5'
          })
         
        }}
      >
      {children}
    </LoadingOverlay>
    )
}

export default LoadingComponent;