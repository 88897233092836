import { Action } from 'redux';
import { ScrollNotification } from '../../types/lazy.type';

export interface ScrollState {
    content: ScrollNotification[]
}

const maxHistory = 5;

export interface ScrollAction extends Action {
    content: ScrollNotification;
}

function scrollReducer(
    _previousState: ScrollState = { content: [] },
    action: ScrollAction ): ScrollState {

    const newState: ScrollNotification[] = [ ..._previousState.content ];
    if ( newState.length > maxHistory ) {
        newState.shift();
    }
    if ( action.content ) {
        newState.push( action.content );
    }

    return { content: newState };
}

export { scrollReducer };
