import React from 'react';
import FootnoteItem from '../../../features/footnote/footnoteItem';
import { ComponentArg } from '../../../types/component-arg';
import { defaultChildren } from '../../generic/default-children';
import ModalComponent from '../modalComponent';

function FootnoteModal(props: ComponentArg): JSX.Element | null {
  const rid = props.node!.a['rid'];

  if (rid) {
    return <ModalComponent arg={props} nodeToRender={rid}>
            <FootnoteItem
              rid={rid}
              arg={props}
              hideFootnoteNumber={true}
              footnoteInRegularFont={true}
            />
      </ModalComponent> 
  } else {
    return defaultChildren(props);
  }
}



export { FootnoteModal };
