import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PublishedDocumentHeader.module.less';
import { getActiveCustomer, isExternalLink } from '../../../util/util';
import { storeUrl } from '../../../../../component/util/checkConfig';
import ScreenReaderOnly from '../../../../../component/util/screenReaderOnly';

const PublishedDocumentHeader = () => {
  const { t } = useTranslation();

  const themeName = `${getActiveCustomer()}Theme`;
  const logo = require(`../../../themes/${themeName}/assets/images/logo.svg`);
  
  return (
    <header className={styles.header}>
      <a
        href={storeUrl()}
        className={styles.homeLink}
        rel={isExternalLink(storeUrl()) ? 'noopener' : ''}
      >
        <img src={logo} alt="logo" className={styles.logo} />
        {isExternalLink(storeUrl()) && (
          <ScreenReaderOnly>{ t('External link') }</ScreenReaderOnly>
        )}
      </a>
    </header>
  );
};

export default PublishedDocumentHeader;
