import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren, defaultChildrenMixed } from '../generic/default-children';
import { key } from '../util/key';
import styles from './fn.module.css';
import { TagEnum } from '../../types/tag-enum';

function Fn(arg: ComponentArg): JSX.Element {
  const id = key(arg);

  let children = arg.node?.c ?? [];

  const label = children.find((c) => c.x?.tag === TagEnum.Label);

  if (!label) {
    return (
      <div className="fn" id={id} key={id}>
        {defaultChildren(arg)}
      </div>
    );
  }

  // Filter out label, will put the other child nodes in a separate container
  children = children.filter((c) => c.x?.tag !== TagEnum.Label);

  return (
    <div className={`fn ${styles.footnote}`} id={id} key={id}>
      {defaultChildrenMixed([label], key(arg), arg.t)}
      <div className={styles.footnoteContents}>
        {defaultChildrenMixed(children, key(arg), arg.t)}
      </div>
    </div>
  );
}

export { Fn };
