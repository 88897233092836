import { createSlice } from '@reduxjs/toolkit';
import store from '../../../../../redux/store';
import { Comment, UserCommentTeam } from '../../../redux/types';

export interface CommentingFrameworkState {
  commentingMode?: CommentingMode;
  comments: Comment[];
  userCommentTeams: UserCommentTeam[];
  selectedComment?: Comment;
  commentAuthor: string;
}

export enum CommentingMode {
  ManageComment = 'manageComment',
  ReviewComments = 'reviewComments',
}

let initialState: CommentingFrameworkState = {
  comments: [],
  userCommentTeams: [],
  commentAuthor: '',
};

const state = createSlice({
  name: 'CommentingFrameworkState',
  initialState,
  reducers: {
    setCommentingMode(state, action) {
      state.commentingMode = action.payload;
    },
    setComments(state, action) {
      state.comments = action.payload;
    },
    setSelectedComment(state, action) {
      state.selectedComment = action.payload;
    },
    setCommentAuthor(state, action) {
      state.commentAuthor = action.payload;
    },
    clearSelectedComment(state) {
      delete state.selectedComment;
    },
    addComment(state, action) {
      state.comments.push(action.payload);
    },
    updateComment(state, action) {
      const index = state.comments.findIndex(
        (comment) => comment.id === action.payload.id
      );

      state.comments[index] = action.payload;
    },
    removeComment(state, action) {
      const remainingComments = state.comments.filter(
        (comment) => comment.id !== action.payload
      );

      state.comments = remainingComments;
    },
    attachFile(state, action) {
      const { commentId, attachments } = action.payload;
      const index = state.comments.findIndex(
        (comment) => comment.id === commentId
      );
      const newAttachments = [
        ...state.comments[index].attachments,
        ...attachments,
      ];
      state.comments[index].attachments = newAttachments;
      if (state.selectedComment) {
        state.selectedComment.attachments = newAttachments;
      }
    },
    deleteFile(state, action) {
      const { commentId, attachmentId } = action.payload;
      const index = state.comments.findIndex(
        (comment) => comment.id === commentId
      );
      const newAttachments = state.comments[index].attachments.filter(
        (a) => a.id !== attachmentId
      );
      state.comments[index].attachments = newAttachments;
      if (state.selectedComment) {
        state.selectedComment.attachments = newAttachments;
      }
    },
    setUserCommentTeams(state, action) {
      state.userCommentTeams = action.payload;
    },
  },
});

const editComment = (commentToEdit: Comment | undefined) => {
  store.dispatch(setCommentingMode(CommentingMode.ManageComment));
  store.dispatch(setSelectedComment(commentToEdit));
};

export const {
  setCommentingMode,
  setComments,
  setSelectedComment,
  clearSelectedComment,
  addComment,
  updateComment,
  removeComment,
  attachFile,
  deleteFile,
  setUserCommentTeams,
  setCommentAuthor
} = state.actions;

export default state.reducer;

export { editComment };
