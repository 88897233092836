import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function longDesc( arg: ComponentArg ): JSX.Element {
    return <p key={ key( arg ) }>{ defaultChildren( arg ) }</p>;
}

export { longDesc };
