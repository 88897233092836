
function getContentLanguageCode( documentContentLanguageCode: string | undefined ): string  {

    let contentLanguageCode = 'en';
    if (documentContentLanguageCode) {
        contentLanguageCode = documentContentLanguageCode;
        if (contentLanguageCode.toLowerCase() === 'nb') {
            contentLanguageCode = 'no';
        }
    }
    return contentLanguageCode.toUpperCase();
}

export { getContentLanguageCode }