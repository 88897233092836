import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tableState } from '../../redux/tables/selector';
import { RootState } from '../../redux/reducer';
import { lockTableData, unlockTableData } from '../../redux/tables/actions';

export interface TableDialogProps extends WithTranslation {
  tableKey: string;
  children: JSX.Element;
}

function Component(prop: TableDialogProps) {
  const [showTableInModal, setShowTableInModal] = useState(false);
  const table = useSelector((state) =>
    tableState(state as RootState, prop.tableKey)
  );

  const openDialog = () => {
    lockTableData();
    setShowTableInModal(true);
  };
  const closeDialog = () => {
    setShowTableInModal(false);
    unlockTableData();
  };

  const { children, t } = prop;
  return (
    <>
      <button
        type="button"
        onClick={openDialog}
        className="tableModalToggle"
        hidden={table.isShowTableInDialogVisisble}
      >
        {t('Open table in separate window')}
      </button>
      <Dialog
        open={showTableInModal}
        onClose={closeDialog}
      >
        <button
          type="button"
          onClick={closeDialog}
          className="modalCloseButton"
          aria-label={t('Close')}
        />
        {children}
      </Dialog>
    </>
  );
}

const TableDialog = withTranslation()(Component);

export { TableDialog };
