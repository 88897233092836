
export class StorageService {

	public readFromStorage(key: string): any {
		const data = localStorage.getItem(key);
		if (data != null && data !== undefined) {
			return JSON.parse(data);
		}
		return;
	}

	public writeToStorage(key: string, value: any): void {
		if (value != null && value !== undefined) {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.removeItem(key);
		}
	}

	public removeFromStorage(key: string): void {
		if (key != null && key !== undefined) {
			localStorage.removeItem(key);
		}
	}
}
