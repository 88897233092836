import * as React from 'react';

type Props = {
    children?: React.ReactNode
  };

const ScreenReaderOnly: React.FC<Props> = ({children}) => (
    <span className="invisible">{children}</span>
);

export default ScreenReaderOnly;
