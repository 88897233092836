import React from 'react';

import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function ref(arg: ComponentArg): JSX.Element {
    return <li className="ref" key={key(arg)}>{defaultChildren(arg)}</li>;
}

export { ref };
