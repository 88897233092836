import { createSlice } from "@reduxjs/toolkit";
import ScrollToInput from "./ScrollInput";
export interface ScrollState {
  idToScrollTo: string,
  scrollableItems: string[],
  updatedAt: number,
};

export const nothingToScrollTo = ''

const initialState: ScrollState = {
  idToScrollTo: nothingToScrollTo,
  scrollableItems: [],
  updatedAt: 0
}

export function newTimestamp() : number {
  return Math.floor(Date.now());
}

const scroll = createSlice({
  name: 'ScrollToItem',
  initialState,
  reducers: {
    scrollToItem(state, action) {
      const payload = action.payload as ScrollToInput;
      state.idToScrollTo = payload.idToScrollTo;
      state.updatedAt = newTimestamp();
    },
    scrollToLastItem(state) {
      state.updatedAt = newTimestamp();
    },
    clearScroll(state) {
      state.idToScrollTo = nothingToScrollTo;
      state.updatedAt = newTimestamp();
    },
    addScrollId(state, action) {
      const payload = action.payload as ScrollToInput[];
      const arr = payload.map((value: ScrollToInput) => value.idToScrollTo)
      const uniqueItems = [...Array.from(new Set(arr))];
      state.scrollableItems = uniqueItems;
    }
  }
});

export const {
  scrollToItem,
  clearScroll,
  addScrollId,
  scrollToLastItem,
} = scroll.actions

export default scroll.reducer