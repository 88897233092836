import React from 'react';
import { representativeImage } from './image-data';

const Representative = () => {
  return (
    <div>
      <img src={representativeImage} alt="representative" />
    </div>
  );
};

export default Representative;
