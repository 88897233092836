import React from 'react';
import { ComponentArg } from '../../../types/component-arg';
import { CreateCssBuilder } from '../../util/cssBuilder';
import { SecGeneric } from './SecGeneric';

export interface SectionProps {
  component: ComponentArg;
  elementKey: string;
}

const isReq = (type: string): boolean => {
  const result = 'reqs' === type;
  return result;
};

const isSpec = (type: string): boolean => {
  const result = 'spec' === type;
  return result;
};

const hasTitle = (component: ComponentArg): boolean => {
  const node = component.node!.c;
  const titleComponent = node.filter((x) => 'title' === x.x!.tag);

  if (titleComponent.length === 0) {
    return false;
  }

  var result = titleComponent[0].x!.c.filter((x) => x.x == null).length > 0;
  return result;
};

const isInline = (type: string, component: ComponentArg): boolean => {
  var result = !hasTitle(component) && (isReq(type) || isSpec(type));
  return result;
}

const Sec = ({ component, elementKey }: SectionProps) => {
  const secType = component.node!.a['sec-type'];
  const cssBuilder = CreateCssBuilder();
  cssBuilder.AddClass(isReq(secType) ? 'sts-section-requirement' : '');
  cssBuilder.AddClass(isSpec(secType) ? 'sts-section-specification' : '');
  cssBuilder.AddClass(hasTitle(component) ? 'sts-section-hasTitle' : '');
  cssBuilder.AddClass(isInline(secType, component) ? 'sts-section-inline' : '');

  return (
    <div className={cssBuilder.Build()}>
      <SecGeneric {...component} key={elementKey} />
    </div>
  );
};

export default Sec;
