import React, { Component } from 'react';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { ComponentArg } from '../../types/component-arg';

class Label extends Component<ComponentArg, {}> {

    render() {

        const directParent = this.props.node!.a['direct-parent'];

        if (directParent === 'app') {
            return (
                <h1 className="sts-app-header" key={ key( this.props  ) }>
                    { defaultChildren(  this.props ) }
                </h1>           
            );   
        }
        else if (directParent === 'list-item') {
            return (
                <span className="sts-label list-item-first-label" key={ key( this.props  ) }>
                    { defaultChildren(  this.props ) }
                </span>
            );
        } else {
            return (
                <span className="sts-label" key={ key( this.props  ) }>
                    { defaultChildren(  this.props ) }
                </span>
            );
        }
    }
}

export { Label };
