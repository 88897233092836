import { Button } from 'antd';
import { ButtonSize } from 'antd/lib/button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ScreenReaderOnly from '../../../../../component/util/screenReaderOnly';
import { AriaHasPopup } from '../../../redux/types';
import { isExternalLink } from '../../../util/util';
import Icon, { IconProps, IconType } from '../Icon/Icon';
import styles from './IconTextButton.module.less';

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

type IconTextButtonProps = {
  onClick?: React.MouseEventHandler,
  iconType: IconType,
  iconWidth?: IconProps['width'],
  iconHeight?: IconProps['height'],
  className?: string,
  buttonText: string,
  textStyles?: string,
  iconRight?: boolean,
  url?: string,
  buttonSize?: ButtonSize,
  ariaExpanded?: boolean,
  ariaHasPopup?: AriaHasPopup,
  ariaControls?: string,
  textAlign?: TextAlign,
  hidden?: boolean,
  disabled?: boolean,
  strokeWidth?: string | number,
  ariaLabel?: string,
  width?: string,
}

// Use any because we reference several different types of elements.
const IconTextButton = React.forwardRef<any, IconTextButtonProps>(({
  iconType,
  iconWidth = 24,
  iconHeight = 24,
  buttonText = '',
  onClick,
  className = '',
  textStyles = '',
  iconRight,
  url,
  buttonSize = 'large',
  ariaExpanded,
  ariaHasPopup,
  ariaControls,
  textAlign = TextAlign.Center,
  hidden,
  disabled,
  strokeWidth,
  ariaLabel,
  width,
}, ref) => {
  const { t } = useTranslation();

  const buttonClasses = `${iconRight ? styles.iconRight : styles.button} ${className}`;

  const sharedProps = {
    hidden,
    style: { width },
    'aria-label': ariaLabel,
    ref,
    disabled,
  }

  const buttonSizeClasses = {
    small: 'ant-btn-sm',
    middle: 'ant-btn-md',
    large: 'ant-btn-lg',
  }

  const buttonContent = (
    <>
      <Icon
        type={iconType}
        width={iconWidth}
        height={iconHeight}
        strokeWidth={strokeWidth}
        className="iconTextButtonIcon"
        ariaHidden
      />
      <span className={`${styles.textStyles} ${textStyles}`} style={{ textAlign }}>{buttonText}</span>
    </>
  );

  const linkClasses = `${buttonClasses} ${buttonSize ? buttonSizeClasses[buttonSize] : ''} ant-btn`;

  if (url && isExternalLink(url)) {
    return (
      <a href={url} className={linkClasses} {...sharedProps} rel="noopener">
        {buttonContent}
        <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
      </a>
    );
  } else if (url) {
    if (disabled) {
      // Remove href when link is disabled to prevent interaction with it.
      // We can't remove the href when using react-router's Link component,
      // so we need to render an <a> tag.
      return <a className={linkClasses} {...sharedProps}>{buttonContent}</a>;
    }
    return (
      <Link to={url} className={linkClasses} {...sharedProps}>
        {buttonContent}
      </Link>
    );
  } else {
    return (
      <Button
        onClick={onClick}
        className={buttonClasses}
        size={buttonSize}
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        aria-controls={ariaControls}
        {...sharedProps}
      >
        {buttonContent}
      </Button>
    );
  }
});

export default IconTextButton;
