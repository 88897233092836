import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';

function GraphicCaptionLabel( arg: ComponentArg ): JSX.Element {
    return  (<>{defaultChildren( arg ) }&nbsp;&mdash;&nbsp;</>);
}

function GraphicCaptionTitle( arg: ComponentArg ): JSX.Element {
    return  (<>{defaultChildren( arg ) }</>);
}

function GraphicCaption( arg: ComponentArg ): JSX.Element {
    return  <figcaption>{defaultChildren( arg ) }</figcaption>;
}

function GraphicCaptionWithButton(
    arg: ComponentArg & {
        onClick: React.MouseEventHandler;
        ariaExpanded: boolean;
        imageId: string;
    },
): JSX.Element {
    return (
        <figcaption>
            <button
                className="image-toggle"
                type="button"
                onClick={arg.onClick}
                aria-expanded={arg.ariaExpanded}
                aria-controls={`#${arg.imageId}`}
            >
                {defaultChildren(arg)}
            </button>
        </figcaption>
    );
}

export { GraphicCaption, GraphicCaptionLabel, GraphicCaptionTitle, GraphicCaptionWithButton };