const siteName = 'Viewer';

const customerCodes = {
    nsai: 'nsai',
    sd: 'sd',
    snv: 'snv',
    so: 'so',
    vegvesen: 'vegvesen',
    svv: 'svv'
};

export {siteName, customerCodes};
