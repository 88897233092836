import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import styles from './consentDialog.module.css';

interface Props {
  onClose: () => void;
  onAcceptTermsAndConditions: () => void;
}

const ConsentDialog = ({ onClose, onAcceptTermsAndConditions }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={onClose} PaperProps={{ className: styles.dialog }}>
      <button
        type="button"
        onClick={onClose}
        className="modalCloseButton"
        aria-label={t('Close')}
      />
      <main>
        <p>{t('FreeStoreStandards.TermsAndConditions')}</p>
        <br />
        <div>
          <button onClick={onAcceptTermsAndConditions}>
            {t('FreeStoreStandards.Accept')}
          </button>
          <button onClick={onClose}>{t('FreeStoreStandards.Reject')}</button>
        </div>
      </main>
    </Dialog>
  );
};

export default ConsentDialog;
