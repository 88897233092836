import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentArg } from '../../types/component-arg';
import { key } from '../util/key';


const ProtectedContentNote = (arg: ComponentArg) => {
    const { t } = useTranslation();
    return (
        <div key={ key( arg ) } className="sts-protected-content-note">
            <p>{t('ProtectedContentNoteText')}</p>
        </div>
    )
}

export { ProtectedContentNote };
