import { Component } from 'react';
import { ComponentArg } from '../../types/component-arg';
import { TagEnum } from '../../types/tag-enum';
import { nodeWithTagSelectors } from '../../graph/nodeWithTagSelectors';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';
import { DbType } from '../../types/db.type';
import { makeWorkerPromise } from '../../redux/worker/stores/workerRequestFactory';
import { parentsStore } from '../../redux/worker/stores/parents.store';
import { levelStore } from '../../redux/worker/stores/level.store';
import { scrollToLastElement } from '../../features/scroll/actions';

export enum TitleType {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    ListItem = 'listItem',
    Ref = 'ref',
    Fn = 'fn',
    Annex = 'annex',
    TableWrap = 'tableWrap',
    Caption = 'caption',
    DefList = 'defList',
    Std = 'std',
    Unknown = 'unknown'
}

export interface TitleTypeState {
    titleType?: string;
    sectionId?: string;
}

export interface TitleDetails {
    titleType?: string;
    sectionId?: string;
}

class TitleBase extends Component<ComponentArg, TitleTypeState> {
    constructor( p: ComponentArg ) {
        super( p );
        this.state = {};
    }

    protected _isMounted: boolean = false;

    componentDidMount() {
        this._isMounted = true;

        TitleBase.saveTitleTypeInState( this.props )
            .then( titleDetails => {
                if ( this._isMounted ) {
                    this.setState( { titleType: titleDetails!.titleType, sectionId: titleDetails!.sectionId  } );
                    scrollToLastElement();
                }
            } );
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    private static async saveTitleTypeInState( arg: ComponentArg ): Promise<TitleDetails | undefined> {
        const id = arg.node!.id;
        let titleType: string | undefined;
        let sectionId: string | undefined;

        const selector = nodeWithTagSelectors( [ TagEnum.ListItem, TagEnum.Ref, TagEnum.RefList, TagEnum.Fn, TagEnum.App,
                TagEnum.Caption, TagEnum.TableWrap, TagEnum.DefList, TagEnum.Std, TagEnum.Sec ] ),
            data4worker: ToWorker = [ WorkerMessageType.directParents, [ id, selector, DbType.full ] ],
            directParentNodes = await makeWorkerPromise( data4worker, parentsStore );

        if ( directParentNodes && directParentNodes.length ) {
            const directParent = directParentNodes[ 0 ];
            switch ( directParent.tag ) {
                case TagEnum.Caption:
                    titleType = TitleType.Caption;
                    break;
                case TagEnum.TableWrap:
                    titleType = TitleType.TableWrap;
                    break;
                case TagEnum.App:
                    titleType = TitleType.Annex;
                    break;
                case TagEnum.ListItem:
                    titleType = TitleType.ListItem;
                    break;
                case TagEnum.Ref:
                    titleType = TitleType.Ref;
                    break;
                case TagEnum.Fn:
                    titleType = TitleType.Fn;
                    break;
                case TagEnum.DefList:
                    titleType = TitleType.DefList;
                    break;
                case TagEnum.Std:
                    titleType = TitleType.Std;
                    break;
                case TagEnum.Sec:
                    sectionId = directParent.id;
                    break;
            }
        }

        if ( !titleType ) {
            const level = await makeWorkerPromise( [ WorkerMessageType.level, id ], levelStore );
            switch ( level ) {
                case 1:
                    titleType = TitleType.H1;
                    break;
                case 2:
                    titleType = TitleType.H2;
                    break;
                case 3:
                    titleType = TitleType.H3;
                    break;
                case 4:
                    titleType = TitleType.H4;
                    break;
                case 5:
                    titleType = TitleType.H5;
                    break;
                case 6:
                    titleType = TitleType.H6;
                    break;
                default:
                    titleType = TitleType.H6;
                    break;
            }
        }
        return { titleType: titleType,  sectionId: sectionId};
    }
}

export { TitleBase };
