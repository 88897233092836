import { createStore, Reducer, Store } from 'redux';

import { ShowErrorState } from './show-error.state';
import { ShowErrorAction, ShowErrorActionType } from './types';
import { showErrorReducer } from './showErrorReducer';

const showErrorStore: Store<ShowErrorState, ShowErrorAction> =
    createStore(
        showErrorReducer as Reducer<ShowErrorState, ShowErrorAction>
    );

enum ErrorTypes {
    UrlNotFound = 'UrlNotFound',
    GlobalError = 'GlobalError'
}

function showError(errorText: string) {
    showErrorStore.dispatch({
        type: ShowErrorActionType.addError,
        errorText
    });
}

function globalError(errorText: string) {
    showErrorStore.dispatch({
        type: ShowErrorActionType.globalError,
        errorText
    });
}

export { showErrorStore, showError, globalError, ErrorTypes };
