import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countOfFoundItems, isSearchRunning, searchStore } from '../../../redux/search';
import { highlightText } from '../../generic/mixed-content-highlight';
import { getDateInfo } from "../../util/getDates";
import { SearchState } from '../../../redux/search/search.reducer';
import { Position } from '../../../types/search.type';
import { idSibling2string } from '../../side-bar/search-document/idSibling';
import * as _ from 'lodash';

export interface RequirementValidFromProps {
    validFromDate: string;    
    parentId: string;
}

interface PositionForState {
    startPosition: number,
    offset: number,
}

const RequirementValidFrom = (props: RequirementValidFromProps) => {
   
    const { t } = useTranslation();
    const {validFromDate, parentId}  = props;
    const [highlightPositions, setHighlightPositions] = useState<PositionForState[]>([]);
    const myIdSiblingString = useMemo(() => idSibling2string( { id: parentId, siblingIdx: 4 } ), [parentId]);
    
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [itemsCountInSearch, setItemsCountInSearch] = useState(0);
    const [refreshHighlighting, setRefreshHighlighting] = useState(_.now());

    useEffect(() => {
        const handleSearchStoreChanges = () => {
                const internalSearchStoreState: SearchState = searchStore.getState();
                setSearchInProgress(isSearchRunning(internalSearchStoreState));

                if (!isSearchRunning(internalSearchStoreState)) {
                    setItemsCountInSearch(countOfFoundItems(internalSearchStoreState));
                }
            }
        const unsubscribe = searchStore.subscribe(() => handleSearchStoreChanges() );
        
            return () => {
                unsubscribe();
            };
    }, []);

    useEffect(() => {
        setRefreshHighlighting(_.now());
    }, [searchInProgress, itemsCountInSearch]);

    useEffect(() => {
        // Update state of highlighting
        const searchStoreState: SearchState = searchStore.getState();
        const myNewPositions: Position[] = searchStoreState.resultMap.get( myIdSiblingString ) || [];
        setHighlightPositions(myNewPositions.map(x => { return {
                startPosition: x[0],
                offset: x[1],
            } as PositionForState }));
    }, [refreshHighlighting, myIdSiblingString]);

    const renderText = ( text?: string ): string | JSX.Element | null => {
        if ( !text ) return null;
        
        if ( highlightPositions.length === 0 ) {
            return <>{text}</>;
        } 
    
        const highlightPostionsToPass = highlightPositions.map(x => { return { "0": x.startPosition, "1": x.offset } as Position })
    
    return <>
            {highlightText( [ text ], highlightPostionsToPass, undefined )}
        </>;
    } 
    
    if (validFromDate) {
        const dateInfo = getDateInfo(+validFromDate, 'L');
        return (
            <time className="requirementValidFrom" dateTime={ dateInfo.dateInternal }>{t('Valid from')}{' '}{renderText(dateInfo.dateFormatted)}</time>
        );
    }        
    return null;    
}

export { RequirementValidFrom }