import React, { useRef, useEffect } from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function StyledContent(  props: ComponentArg ): JSX.Element {

    const spanRef: React.RefObject<HTMLTableHeaderCellElement> = useRef(null);

    useEffect(() => {
        const style = props.node!.a && props.node!.a['style'];
        if (style) {
            spanRef.current!.setAttribute('style', style);
        }
    }, [ props.node]);

    return <span ref={ spanRef } key={ key( props ) }>{ defaultChildren( props ) }</span>;
}

export { StyledContent };
