import * as assert from 'assert';
import { FilterDocumentState} from './filter-document.state';
import { FilterDocumentAction, FilterDocumentActionType } from './types';
import { RequirementType } from '../../types/requirement.type';

function newState(): FilterDocumentState {
    return {
        filterIsApplied: false,
        selectedRequirementTypes: new Set<RequirementType>(),
        viewOnlyCategory: undefined,
        selectedThemes: [],
    };
}

export function filterDocumentReducer(
    prevState: FilterDocumentState = newState(),
    action: FilterDocumentAction ): FilterDocumentState {

    let result = { ...prevState };

    switch ( action.type ) {
        case FilterDocumentActionType.clearFilter:
            result = newState();
            break;
        case FilterDocumentActionType.selectViewOnlyCategory:
            result.filterIsApplied = true;
            result.viewOnlyCategory = action.viewOnlyCategory;
            break;
        case FilterDocumentActionType.addRequirementType:
            assert.ok( action.requirementType, 'action.requirementType is required' );
            result.selectedRequirementTypes.add( action.requirementType! );
            break;
        case FilterDocumentActionType.addRequirementTypeAll:
            result.selectedRequirementTypes = new Set<RequirementType>( Object.values( RequirementType ) );
            break;
        case FilterDocumentActionType.removeRequirementType:
            assert.ok( action.requirementType, 'action.requirementType is required' );
            result.selectedRequirementTypes.delete( action.requirementType! );
            break;
        case FilterDocumentActionType.removeRequirementTypeAll:
            result.selectedRequirementTypes = new Set();
            break;
        case FilterDocumentActionType.setThemes:
            result.selectedThemes = action.selectedThemes!;
            break;
    }

    return result;
}
