import { createSlice } from "@reduxjs/toolkit";

interface IdIndexForJson {
  key: string,
  values: string[]
}

export class IdIndex {
  idIndex: Map<string, Set<string>>;

  constructor() {
    this.idIndex = new Map<string, Set<string>>();
  }

  addItem(nodeId: string, value: string) {
    if (nodeId.length < 1) {
      throw new RangeError(`nodeId should not be empty`);
    }

    if (value.length < 1) {
      throw new RangeError(`value should not be empty`);
    }

    const existingSetofIds = this.idIndex.get(value);

    if (existingSetofIds === undefined) {
      this.idIndex.set(value, new Set<string>());
      this.addItem(nodeId, value);
      return;
    }

    existingSetofIds!.add(nodeId);
  }

  getValuesOfId(nodeId: string) : string[] {
    const result: string[] = [];
    this.idIndex.forEach((ids: Set<string>, value: string) => {
      if (ids.has(nodeId)) {
        result.push(value);
      }
    });

    return result;
  }

  valueContainsId(values: string[], id: string) : boolean {
    if (values.length < 1) {
      return true;
    }

    return values.map( value => {
      const ids = this.idIndex.get(value);

      if (ids === undefined) {
        return false;
      }

      return ids.has(id);
    })
    .filter(hasItem => hasItem)
    .length > 0;
  }

  toJson(): string {
    const result: IdIndexForJson[] = [];
    
    this.idIndex.forEach((value: Set<string>, key: string) => {
      const jsonReady: IdIndexForJson = {
        key: key,
        values: Array.from(value)
      }

      result.push(jsonReady);
    });
    
    return JSON.stringify(result);
  }

  loadJson(json: string) {
    this.idIndex = new Map<string, Set<string>>();
    const deserializedJson: IdIndexForJson[] = JSON.parse(json);
    deserializedJson.forEach(value => {
      const newSet = new Set(value.values);
      this.idIndex.set(value.key, newSet);
    })
  }
}

function IdIndexToRedux(index: IdIndex) : string {
  var result = index.toJson();
  return result;
}

export function reduxStateToIdIndex(index: string) : IdIndex {
  var result = new IdIndex();
  
  if (index === '') {
    return result;
  }

  result
  .loadJson(index);
  return result;
}

export interface SearchIdAndPropertiesState {
  vocabTerm: string,
  themes: string,
};

export interface SearchIdAndPropertiesInput {
  id: string,
  vocabTerm: string,
  themes: string[],
};

const initialState: SearchIdAndPropertiesState = {
  vocabTerm:  '',
  themes:  '',
}

const searchIdAndProperties = createSlice({
  name: 'SearchIdAndProperties',
  initialState,
  reducers: {
    addVocabTerms(state, action) {
      const payload = action.payload as SearchIdAndPropertiesInput[];
      const idIndex = reduxStateToIdIndex(state.vocabTerm);
      payload.forEach(itemToAdd => {
        idIndex.addItem(itemToAdd.id, itemToAdd.vocabTerm);
      });
      state.vocabTerm = IdIndexToRedux(idIndex);
    },
    addThemes(state, action) {
      const payload = action.payload as SearchIdAndPropertiesInput[];
      const idIndex = reduxStateToIdIndex(state.themes);
      payload.forEach(itemToAdd => {
        itemToAdd.themes.forEach(theme => {
          idIndex.addItem(itemToAdd.id, theme);
      });
    });
    state.themes = IdIndexToRedux(idIndex);
    }
  }
});

export const {
  addThemes,
  addVocabTerms,
} = searchIdAndProperties.actions

export default searchIdAndProperties.reducer