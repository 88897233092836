import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/reducer';
import { FeaturesState } from './index';

export interface FeatureInformation {
  enabled: boolean;
}

const root = (state: RootState) => state.FeaturesToggle;
const featureEnquiry = createSelector(
  root,
  (state: FeaturesState): FeatureInformation => {
    return {
      enabled: state.enquiry.enabled,
    };
  }
);

const featureSelection = createSelector(
  root,
  (state: FeaturesState): FeatureInformation => {
    return {
      enabled: state.selection,
    };
  }
);

const featureFavourites = createSelector(
  root,
  (state: FeaturesState): FeatureInformation => {
    return {
      enabled: state.favourites,
    };
  }
);

const isEnquiryPublished = createSelector(
  root,
  (state: FeaturesState): boolean => {
    return state.enquiry.published;
  }
);

const isEnquiryPartial = createSelector(
  root,
  (state: FeaturesState): boolean => {
    return state.enquiry.partial;
  }
);

export {
  featureEnquiry,
  featureSelection,
  featureFavourites,
  isEnquiryPublished,
  isEnquiryPartial,
};
