import * as Stream from 'stream';
import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from '@redux-saga/core';

import { SearchAction, searchReducer, SearchState } from './search.reducer';
import { searchSaga, searchStreamIn, dispatch2searchStore } from './search.saga';

const middleware = createSagaMiddleware(),
    searchStore: Store<SearchState, SearchAction> =
        createStore(
            searchReducer,
            applyMiddleware( middleware )
        );

middleware.run( searchSaga );

const searchStreamOut: Stream.Writable = new Stream.Writable( {
    objectMode: true,
    write( chunk: SearchAction, encoding: string, callback: ( error?: ( Error | null ) ) => void ): void {
        searchStore.dispatch( chunk );
        callback();
    }
} );

searchStreamIn.pipe( searchStreamOut );

export { searchStore, dispatch2searchStore };

const isSearchRunning = (state: SearchState): boolean => state.searchInProgress !== undefined;
const countOfFoundItems = (state: SearchState): number => state.resultArray.length;

export { isSearchRunning, countOfFoundItems };