import { createSlice } from '@reduxjs/toolkit';

export interface FootnoteState {
  containerId: string;
  footnoteId: string;
}

class Footnotes {
  _items: FootnoteState[];

  constructor(items: FootnoteState[]) {
    this._items = items;
  }

  addFootnote(item: FootnoteState) {
    this._items = this._items.filter(
      (footnote) => footnote.footnoteId !== item.footnoteId
    );
    this._items.push(item);
  }
}

const initialState: FootnoteState[] = [];

const footnote = createSlice({
  name: 'FootnotesState',
  initialState,
  reducers: {
    addFootnote(state, action) {
      const footnoteState = new Footnotes(state as FootnoteState[]);
      footnoteState.addFootnote(action.payload as FootnoteState);
      return footnoteState._items;
    },
  },
});

export const { addFootnote } = footnote.actions;

export default footnote.reducer;
