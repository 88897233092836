import { NodeId } from '../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';
import { makeWorkerPromise } from '../../redux/worker/stores/workerRequestFactory';
import { getClosestParentSectionByIdStore } from '../../redux/worker/stores/getClosestParentSectionById.store';

function getClosestParentSectionById(nodeId: NodeId): Promise<NodeId | null> {
  const data4worker: ToWorker = [WorkerMessageType.getClosestParentSectionById, nodeId];
  return makeWorkerPromise(data4worker, getClosestParentSectionByIdStore);
}

function getClosestRequirementById(nodeId: NodeId): Promise<NodeId | null> {
  const data4worker: ToWorker = [WorkerMessageType.getClosestRequirementById, nodeId];
  return makeWorkerPromise(data4worker, getClosestParentSectionByIdStore);
}

function getClosestTableById(nodeId: NodeId): Promise<NodeId | null> {
  const data4worker: ToWorker = [WorkerMessageType.getClosestTableById, nodeId];
  return makeWorkerPromise(data4worker, getClosestParentSectionByIdStore);
}

function getClosestFigureById(nodeId: NodeId): Promise<NodeId | null> {
  const data4worker: ToWorker = [WorkerMessageType.getClosestFigureById, nodeId];
  return makeWorkerPromise(data4worker, getClosestParentSectionByIdStore);
}

function getClosestPartialEnquiryById(nodeId: NodeId): Promise<NodeId | null> {
  const data4worker: ToWorker = [WorkerMessageType.getClosestPartialEnquiryById, nodeId];
  return makeWorkerPromise(data4worker, getClosestParentSectionByIdStore);
}

export {
  getClosestParentSectionById,
  getClosestRequirementById,
  getClosestFigureById,
  getClosestTableById,
  getClosestPartialEnquiryById
};
