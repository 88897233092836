import * as React from 'react';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { featureFavourites } from "../../redux/features/selectors";
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { FavouriteRequirementInfo } from '../../types/favourite-requirement-info';
import Auth from '../authentication/auth';
import { ReactComponent as StarIcon } from '../../themes/basetheme/assets/icons/star.svg';
import { useTranslation } from 'react-i18next';
import { getAppConfiguration } from '../../redux/store';
export interface FavouriteRequirementProps {
    productId: string;
    requirementId: string; 
    isFavourite: boolean;
}

const FavouriteRequirement = (props: FavouriteRequirementProps) => {
    const [ isFavourite, setIsFavourite ] = useState( props.isFavourite );
    const favourites = useSelector(featureFavourites);
    const { t } = useTranslation();

    const favouriteRequirementHandler = () =>  {
        const currentIsFavourite = !isFavourite;
        const auth: Auth | undefined = standardStateStore.getState().auth;
        if (auth) {
            const apiUrl = `${getAppConfiguration().standardsViewerApiUrl}/requirement`;

            const favouriteRequirementInfo: FavouriteRequirementInfo = {
                productVariantId: props.productId,
                requirementId: props.requirementId,
                isDelete: !currentIsFavourite
            }
            auth.postProtectedApiData( apiUrl, favouriteRequirementInfo ).then(() => {
                setIsFavourite(currentIsFavourite);
            });
        }
    }

    const helpText = isFavourite ? t('Favourites.Remove') : t('Favourites.Add');
  
    if (favourites.enabled === true) {    
        return (
            <button 
                className="favouriteRequirement"
                onClick={favouriteRequirementHandler}
                aria-label={helpText}
                title={helpText}
            >
                <StarIcon className={ isFavourite ? 'starIconSelected' : 'starIcon' }/>
            </button>
        );
    }

    return null;
}


export { FavouriteRequirement }
