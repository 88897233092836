import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { SortedDisplayOrderEntry } from '../../../types/search.type';

type Content = WorkerStoreItem<SortedDisplayOrderEntry[]>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const sortedInDisplayOrderStore: Store<S, A> = createStore( identityReducer as Reducer );

export { sortedInDisplayOrderStore };
