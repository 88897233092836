import { nodeWithTagSelectors } from '../../../../../graph/nodeWithTagSelectors';
import { childrenStore } from '../../../../../redux/worker/stores/children.store';
import { getByIdStore } from '../../../../../redux/worker/stores/getById.store';
import { parentsStore } from '../../../../../redux/worker/stores/parents.store';
import { makeWorkerPromise } from '../../../../../redux/worker/stores/workerRequestFactory';
import { DbType } from '../../../../../types/db.type';
import { JsonNodeTypeGraph } from '../../../../../types/graph.type';
import { TagEnum } from '../../../../../types/tag-enum';
import {
  ToWorker,
  WorkerMessageType,
} from '../../../../../types/workerMessage.type';
import { ElementDetails } from './DocumentFractionWrapperState';

enum ChildrenNodeDepth {
  All,
  Direct,
}

const getFirstNodeText = (nodes: JsonNodeTypeGraph[], tag: TagEnum): string => {
  const node = nodes.find((n) => n.tag === tag);
  if (!node) {
    return '';
  }
  const firstChild = node.c[0];
  if (!firstChild) {
    return '';
  }
  return firstChild.t ?? '';
};

const getNodeCaption = (node: JsonNodeTypeGraph): string[] => {
  return [node.a['req-number'], ''];
};

const getChildrenCaption = (
  children: JsonNodeTypeGraph[],
  childrenTags: TagEnum[]
): string[] => {
  return childrenTags.map((tag) => getFirstNodeText(children, tag));
};

const getElementCaption = (
  tag: TagEnum,
  node: JsonNodeTypeGraph,
  children: JsonNodeTypeGraph[],
  childrenTags: TagEnum[]
): string[] => {
  // extracted from node attributes
  if (tag === TagEnum.NamedContent) {
    return getNodeCaption(node);
  }
  // extracted from children nodes
  return getChildrenCaption(children, childrenTags);
};

const getChildrenTags = (tag: TagEnum): TagEnum[] => {
  switch (tag) {
    case TagEnum.Sec:
    case TagEnum.RefList:
    case TagEnum.App:
    case TagEnum.Math:
      return [TagEnum.Label, TagEnum.Title];
    case TagEnum.TermSec:
      return [TagEnum.Label, TagEnum.TbxTerm];
    case TagEnum.TableWrap:
      return [TagEnum.TableCaptionLabel, TagEnum.TableCaptionTitle];
    case TagEnum.Fig:
      return [TagEnum.GraphicCaptionLabel, TagEnum.GraphicCaptionTitle];
    default:
      return [];
  }
};

const getChildrenNodeDepth = (tag: TagEnum): ChildrenNodeDepth => {
  if (tag === TagEnum.RefList || tag === TagEnum.App) {
    return ChildrenNodeDepth.Direct;
  }
  return ChildrenNodeDepth.All;
};

const getParents = async (
  elementKey: string,
  tags: TagEnum[]
): Promise<JsonNodeTypeGraph[]> => {
  const request: ToWorker = [
    WorkerMessageType.parents,
    [elementKey, nodeWithTagSelectors(tags), DbType.full],
  ];
  const parents = await makeWorkerPromise(request, parentsStore);
  return parents;
};

const getLookupElementId = async (
  elementId: string,
  tag: TagEnum
): Promise<string> => {
  // in case of formulas, the selection details must be extracted from a sibling of the selection
  // so we get the parent first and then lookup the label and title children of that parent
  if (tag === TagEnum.Math) {
    const parents = await getParents(elementId, [TagEnum.MathWrapper]);
    const parent = parents[0];
    return parent ? parent.id : elementId;
  }
  return elementId;
};

export const getElementInformation = async (
  elementKey: string
): Promise<ElementDetails | null> => {
  const getElementByIdRequest: ToWorker = [
    WorkerMessageType.getById,
    elementKey,
  ];
  const elementNode = await makeWorkerPromise(
    getElementByIdRequest,
    getByIdStore
  );

  if (!elementNode) {
    return null;
  }

  const id = elementNode.id;
  const tag = elementNode.tag as TagEnum;
  const lookupElementId = await getLookupElementId(id, tag);

  const childrenTags = getChildrenTags(tag);
  const childrenNodeDepth = getChildrenNodeDepth(tag);

  let request: ToWorker = [
    WorkerMessageType.children,
    [lookupElementId, nodeWithTagSelectors(childrenTags), DbType.full],
  ];

  if (childrenNodeDepth === ChildrenNodeDepth.Direct) {
    request = [
      WorkerMessageType.directChildren,
      [lookupElementId, nodeWithTagSelectors(childrenTags), DbType.full],
    ];
  }

  const children = await makeWorkerPromise(request, childrenStore);

  const [label, title] = getElementCaption(
    tag,
    elementNode,
    children,
    childrenTags
  );

  return {
    id,
    tag,
    label,
    title,
  };
};
