import { applyMiddleware, createStore, Reducer, Store } from 'redux';
import createSagaMiddleware, { Saga } from '@redux-saga/core';

import { GraphService } from '../../graph/graph.service';
import { TocService } from '../../component/lazy/toc.service';
import {
  RequestUrlData,
  StandardState,
  StandardStateAction,
  StandardStateActionType,
} from './standard-state.types';
import Auth from '../../component/authentication/auth';
import { StorageService } from '../../storage/storage.service';
import * as assert from 'assert';
import { Base64 } from '../../types/image/image.types';
import { InitLazyStateRequirements } from '../../component/lazy/init-state/init-lazy-state.requirements';
import { SearchService } from '../../component/side-bar/search-document/search.service';
import { StandardDto } from '../../types/standard-dto';
import { DispatchStream } from '../stream/dispatch.stream';
import { InitLazyStateThemes } from '../../component/lazy/init-state/init-lazy-state.themes';
import { CollectionDto } from '../../types/collection-dto';

function standardReducer(
  previousState: StandardState = new StandardState(),
  action: StandardStateAction,
): StandardState {
  switch (action.type) {
    case StandardStateActionType.setAuth:
      previousState.auth = action.content as Auth;
      break;
    case StandardStateActionType.callApi:
      previousState.requestUrlData = action.content as RequestUrlData;
      if (previousState.graphService) {
        previousState.graphService.cleanup();
      }
      break;
    case StandardStateActionType.setStandardDto:
      previousState.standardDto = action.content as StandardDto;
      break;
    case StandardStateActionType.setCollectionDto:
      previousState.collectionDto = action.content as CollectionDto;
      break;
    case StandardStateActionType.setGraphService:
      previousState.graphService = action.content as GraphService;
      break;
    case StandardStateActionType.setSearchService:
      previousState.searchService = action.content as SearchService;
      break;
    case StandardStateActionType.setTocService:
      previousState.tocService = action.content as TocService;
      break;
    case StandardStateActionType.setStorageService:
      previousState.storageService = action.content as StorageService;
      break;
    case StandardStateActionType.setPdfContentBase64:
      previousState.pdfContentBase64 = action.content as Base64;
      break;
    case StandardStateActionType.setMessageType:
      previousState.messageType = action.content as string;
      break;
    case StandardStateActionType.setInitLazyStateRequirements:
      previousState.initLazyStateRequirements =
        action.content as InitLazyStateRequirements;
      break;
    case StandardStateActionType.setInitLazyStateThemes:
      previousState.initLazyStateThemes = action.content as InitLazyStateThemes;
      break;
  }
  return previousState;
}

const middleware = createSagaMiddleware(),
  standardStateStore: Store<StandardState, StandardStateAction> = createStore(
    standardReducer as Reducer<StandardState, StandardStateAction>,
    applyMiddleware(middleware),
  );

let sagaIsInit: boolean = false;

function initStandardStateStoreSaga(saga: Saga<any[]>): void {
  assert.ok(!sagaIsInit, 'invalid attempt to re-init saga');
  sagaIsInit = true;
  middleware.run(saga);
}

class StandardStateDispatcher extends DispatchStream<StandardStateAction> {
  protected act(m: StandardStateAction): void {
    standardStateStore.dispatch(m);
  }
}

const standardStateStoreDispatcher = new StandardStateDispatcher();

export {
  standardStateStore,
  standardStateStoreDispatcher,
  initStandardStateStoreSaga,
};
