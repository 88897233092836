function rotateTableCell(style: string, cellRef: React.RefObject<HTMLTableCellElement>): string {
    var hasRorate = style.indexOf('transform: rotate(-90deg);') !== -1;
    if (hasRorate === true) {
        style = style.replace('transform: rotate(-90deg);', '');
        cellRef.current!.setAttribute('class', 'sts-rotate-left');
    }

    return style;
}

export { rotateTableCell };
