import { topRibbonHeightDekstop } from '../../constants/json-node';

//		https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
function isScrolledIntoView( el: HTMLElement ): boolean {
    const rect = el.getBoundingClientRect(),
        elemTop = rect.top,
        elemBottom = rect.bottom;

    // Partially visible elements return true:
    return elemTop < window.innerHeight && elemBottom >= topRibbonHeightDekstop;
}

function isWithinScrollThreshold( el: HTMLElement ): boolean {
    const rect = el.getBoundingClientRect();

    // Element is within viewport and margin below (margin is 1 viewport height).
    const isWithinThresholdBelow = rect.top < window.innerHeight * 2;

    // Element is within margin above viewport (margin is 1 viewport height).
    const isWithinThresholdAbove = rect.top > -window.innerHeight;

    return isWithinThresholdBelow || isWithinThresholdAbove;
}

export { isScrolledIntoView, isWithinScrollThreshold };
