import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function annexType( arg: ComponentArg ): JSX.Element {
    return <div className="sts-app-header" key={ key( arg ) }>{ defaultChildren( arg ) }</div>;
}

export { annexType };
