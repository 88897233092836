import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function tr( arg: ComponentArg ): JSX.Element {
    return <tr key={ key( arg ) }>{ defaultChildren( arg ) }</tr>;
}

export { tr };
