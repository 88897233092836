import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface VocabTermInfoProps {
    vocabTerm: string;    
}

const VocabTermInfo = (props: VocabTermInfoProps) => {
   
    const { t } = useTranslation(),
        {vocabTerm}  = props;
    
    return (
        <span className="vocabTerm">{t(vocabTerm) + ': '}</span>
    );
}

export { VocabTermInfo }