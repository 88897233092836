import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { MixedContent } from '../../types/json-node.type';
import { TagEnum } from '../../types/tag-enum';
import { key } from '../util/key';

function subPart( arg: ComponentArg ): JSX.Element {
    const id = key(arg);
    const stdMeta = arg.node!.c.filter(x => x.x?.tag === TagEnum.StdMeta);
    const text = getText(stdMeta[0]);
    return (
        <div className="titleWrap">
            <h1 className="titleHeading1" key={ id }>{ text }</h1>
        </div>
    )
}

function getText(metaStd: MixedContent): string {
    const titleWrap = metaStd.x!.c.filter(c => c.x?.tag === TagEnum.TitleWrap)[0];
    const mainTitle = titleWrap.x!.c.filter(c => c.x?.tag === TagEnum.Main)[0];
    return mainTitle.x!.c[0].t ?? '';
}

export { subPart };