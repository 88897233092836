import React from 'react';
import { ComponentArg } from '../../../../types/component-arg';
import Hidden from '../../hidden';

export interface KeyProps {
  component: ComponentArg;
}; 

const Key = ({component}: KeyProps) => {
    return <Hidden {...component} />;
}

export const IsKey = (contentType: string) => {
    return 'key' === contentType;
}

export default Key;