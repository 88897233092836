import { customerCodes } from "../../constants/customer-codes";
import { productOwners } from "../../constants/product-owners";
import { FeatureEnquiry } from "../../redux/features";
import { getAppConfiguration } from "../../redux/store";

function isSdCommerce() {
    return compareProductOwner( productOwners.sdcommerce);
}

function  compareProductOwner( compareWith: string ): boolean {
    return getAppConfiguration().productOwner === compareWith;
}

function isPublic() {
    return getAppConfiguration().isPublic;
}

function getCustomerCode(customerCode?: string): string {
    customerCode = customerCode || getAppConfiguration().customerCode || 'sd';
    if (customerCode === customerCodes.svv) {
        return customerCodes.vegvesen;
    } 
    return customerCode;
}

function compareCustomerCode( compareWith: string ): boolean {
    return getAppConfiguration().customerCode === compareWith;
}

function productOwner(): string {
    if (isSdCommerce()) {
        return productOwners.sdcommerce;
    } else {
        return productOwners.at;
    } 
}

const isEnquiryEnabled = (): boolean => {
    return getAppConfiguration().features.enquiry.enabled;
}

const featureEnquiryState = (): FeatureEnquiry => {
    const result: FeatureEnquiry = {
        enabled: isEnquiryEnabled(),
        published: false,
        partial: false,
    }

    return result;
}

const isFavouritesEnabled = (): boolean => {
    return getAppConfiguration().features.favourites;
}

const isSelectionEnabled = (): boolean => {
    return getAppConfiguration().features.selection ||
        isEnquiryEnabled();
}

function compareIsUsingAlternateLanguageSwitcher( compareWith: string ): boolean {
    return getAppConfiguration().languageSwitcherStyle === compareWith;
}


const isShowCopyrightInfoEnabled = (): boolean => {
    return getAppConfiguration().showCopyrightInfo;
}

const urlToEnquiry = (): string => {
    return getAppConfiguration().features.enquiry.spaUrl;
}

const storeUrl = (): string => getAppConfiguration().features.enquiry.storeUrl || '';
const customerUrl = (): string => getAppConfiguration().features.enquiry.customerPageUrl || '';

const getUserGuideUrl = () => {
    const customerCode = getCustomerCode();

    if (customerCode !== (customerCodes.vegvesen || customerCodes.svv)) {
        return null;
    }
    
    if (isEnquiryEnabled()) {
        return 'https://standardsdigital.atlassian.net/wiki/spaces/SaaSD/pages/2925756683/SVV+-+Enquiry+-+User+Guide';
    }

    return 'https://standardsdigital.atlassian.net/wiki/spaces/SVVP/pages/2967502855/SD+Viewer';
}

const isFootnoteVisible = (): boolean => {
    return getAppConfiguration().features.footnoteVisible;
}

const isExpandCollapseEnabled = (): boolean => {
    return getAppConfiguration().features.expandCollapseEnabled;
}

const isUsingAlternateLanguageSwitcher = (): boolean => {
    return compareIsUsingAlternateLanguageSwitcher('link-to-alternate-language');
}

const getEnquiryUILanguages = (): string[] | undefined => getAppConfiguration().uiLanguages?.split(',');

const getSupportedStandardsInNEKPlugin = () : string[] => [
    'NEK 400', 
    'NEK 320', 
    'NEK 399', 
    'NEK 410', 
    'NEK 420', 
    'NEK 440',
    'NEK 445',
    'NEK 600',
    'NEK 700',
    'NEK EN 60204-1'
];

const validateCopyAvailability = (): boolean => {
    return getAppConfiguration().features.validateCopyAvailability;
}

const canSeeProductMetadataEmail = (): boolean => {
    return getAppConfiguration().features.productMetadataShowEmail;
}

export { 
    isSdCommerce, 
    compareProductOwner, 
    isPublic, 
    getCustomerCode, 
    compareCustomerCode, 
    productOwner, 
    isEnquiryEnabled, 
    isFavouritesEnabled, 
    isSelectionEnabled,
    urlToEnquiry, 
    featureEnquiryState,
    storeUrl,
    customerUrl,
    getUserGuideUrl,
    isFootnoteVisible,
    isExpandCollapseEnabled,
    isUsingAlternateLanguageSwitcher,
    getEnquiryUILanguages,
    getSupportedStandardsInNEKPlugin,
    validateCopyAvailability,
    canSeeProductMetadataEmail,
    isShowCopyrightInfoEnabled,
}
