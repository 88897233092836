import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/reducer';
import { FootnoteState } from './footnote.State';

const items = (state: RootState) => state.Footnotes;
const containerId = (state: RootState, containerId: string) => containerId;

const itemsInFootnotes = createSelector(
  [items, containerId],
  (state: FootnoteState[], containerId: string): FootnoteState[] => {
    return state.filter((value) => value.containerId === containerId);
  }
);

export { itemsInFootnotes };
