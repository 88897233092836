import { combineReducers } from 'redux';
import documentFractionWrapperState from '../features/selection/components/molecules/DocumentFractionWrapper/DocumentFractionWrapperState';
import commentingFrameworkState from '../features/enquiry/components/organisms/CommentingFramework/CommentingFrameworkState';
import FeaturesToggle from './features';
import scrollState from '../features/scroll/ScrollState';
import searhMarkState from '../features/searchmarkcontainer/SearchMarkState';
import searchIdAndPropertiesState from '../features/searchIdAndProperties/searchIdAndProperties.State';
import tables from './tables';
import hiddenComponentsState from './hide-component/hideComponents.State'
import footnoteState from '../features/footnote/footnote.State';
import expandCollapseState from './expand-collapse';
import fileTypeState from './file-type';
import { appConfigurationApi } from './services/appConfigurationApi';
import { configurationSlice } from './slices/configurationSlice';
import { imageApi } from './services/imageApi';
import { freeStoreStandardsApi } from './services/freeStoreStandardsApi';

export const rootReducer = combineReducers({
  DocumentFractionWrapperState: documentFractionWrapperState,
  CommentingFrameworkState: commentingFrameworkState,
  FeaturesToggle: FeaturesToggle,
  ScrollState: scrollState,
  HightlightState: searhMarkState,
  SearchIdAndProperties: searchIdAndPropertiesState,
  TablesState: tables,
  HiddenItems: hiddenComponentsState,
  Footnotes: footnoteState,
  ExpandCollapseState: expandCollapseState,
  FileTypeState: fileTypeState,
  [appConfigurationApi.reducerPath]: appConfigurationApi.reducer,
  [configurationSlice.name]: configurationSlice.reducer,
  [imageApi.reducerPath]: imageApi.reducer,
  [freeStoreStandardsApi.reducerPath]: freeStoreStandardsApi.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
