import { JsonNodeType, NodeId } from '../types/json-node.type';
import { ProductInfo } from '../types/product-info';
import { workerPath } from '../constants/json-node';
import { TocData } from '../types/toc-data';
import { ToWorker, WorkerMessageType, WorkerMessage, FromWorker, Uuid } from '../types/workerMessage.type';
import { uuid } from '../component/util/uuid';
import { workerStoreFacade } from '../redux/worker/stores/workerStoreFacade';
import { DispatchStream } from '../redux/stream/dispatch.stream';
import { ShowErrorInstance } from '../singleton';
import { getAppConfiguration } from '../redux/store';

export class GraphService extends DispatchStream<WorkerMessage<FromWorker>> {
    constructor( fullModel: JsonNodeType ) {
        super();
        this._fullJsonData = fullModel;
        this.initWorker();
        const appConfig = getAppConfiguration();
        const shouldAddMetadataToToC = appConfig.features.enquiry.commentOnMetadata;
        const validMetadataLanguages = appConfig.features.enquiry.validMetadataLanguages?.split(',') ?? [];
        console.log({appConfig, shouldAddMetadataToToC, validMetadataLanguages})
        this.sendToWorker(
          [WorkerMessageType.fullJson, [fullModel, shouldAddMetadataToToC, validMetadataLanguages]],
          uuid(),
        );
    }

    cleanup() {
        this._worker.terminate();
    }

    public productInfo?: ProductInfo;

    private readonly _fullJsonData?: JsonNodeType;
    private _standardTocData: TocData[];
    private _nodeOrder: Map<NodeId, number>;
    private _worker: Worker;

    getLanguageCode() : string {
        if (this.productInfo && this.productInfo.availableLanguages && this.productInfo.availableLanguages.length) {
            return this.productInfo.availableLanguages[0].languageCode.toLowerCase();
        }
        return '';
    }

    getProductNumber() : string {
        let productNumber = '';
        if (this.productInfo && this.productInfo.meta) {
            productNumber = this.productInfo.meta.productNumber.part1.text;
            if ( this.productInfo.meta.productNumber.part2 ) {
                productNumber = productNumber + this.productInfo.meta.productNumber.part2.text;
            }
        }
        return productNumber;
    }

    get fullJsonData(): JsonNodeType | undefined {
        return this._fullJsonData;
    }

    get standardTocData(): TocData[] {
        return this._standardTocData;
    }

    set standardTocData( value: TocData[] ) {
        this._standardTocData = value;
    }

    get nodeOrder(): Map<NodeId, number> {
        return this._nodeOrder;
    }

    set nodeOrder( value: Map<NodeId, number> ) {
        this._nodeOrder = value;
    }

    get isReady(): boolean {
        return this._nodeOrder !== undefined &&
            this._standardTocData !== undefined &&
            this._fullJsonData !== undefined;
    }

    nodeId2Title( nodeId: NodeId ): string | undefined {
        const item: TocData | undefined = this.standardTocData.find( item1 => item1.id === nodeId );
        return item ? item.title : undefined;
    }

    sendToWorker( requestContent: ToWorker, requestId: Uuid ): void {
        const requestMessage: WorkerMessage<ToWorker> = {
            requestId,
            content: requestContent
        };
        this._worker.postMessage( requestMessage );
    }

    protected initWorker() {
        this._worker = new Worker( workerPath + '?' + (Math.random() * 1000000));
        this._worker.onmessage = this.onWorkerMessageReceived.bind( this );
    }

    protected onWorkerMessageReceived( e: MessageEvent ): void {
        this.dispatch( e.data );
    }

    protected act( m: WorkerMessage<FromWorker> ): void {
        this.dispatchWorkerResponse2stores( m );
    }

    protected dispatchWorkerResponse2stores( responseMessage: WorkerMessage<FromWorker> ): void {
        if ( GraphService.responseIsError( responseMessage.content ) ) {
            ShowErrorInstance.Fn( JSON.stringify( responseMessage.content ) );
        } else {
            workerStoreFacade.call( this, responseMessage );
        }
    }

    private static responseIsError( responseContent: FromWorker ): boolean {
        const isError = responseContent[ 0 ] === WorkerMessageType.workerError;
        if ( isError ) {
            const errorMessages = responseContent[ 1 ] as string[];
            ShowErrorInstance.Fn( errorMessages.join( '; ' ) );
        }
        return isError;
    }
}
