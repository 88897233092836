import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { storageKeys } from '../storage/storage-keys';
import en from './locales/en.json';
import nb from './locales/nb.json';
import fr from './locales/fr.json';
import de from './locales/de.json';
import 'moment/locale/nb';
import 'moment/locale/de';
import 'moment/locale/fr';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

const options: InitOptions = {
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    resources: {
        nb: {
            translation: nb
        },
        en: {
            translation: en
        },
        fr: {
            translation: fr
        },
        de: {
            translation: de
        },
    },
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    returnNull: false,
};

const languageCode = localStorage.getItem(storageKeys.LANGUAGE_CODE);
if (languageCode) {
    const lang = JSON.parse(languageCode);
    options.lng = lang;
    document.documentElement.lang = lang;
}

i18n
    .use(initReactI18next)
    .init(options).then();

export default i18n;
