import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import urlJoin from 'url-join';

import { getIdentityConfig, getMetadataOidc } from './auth-const';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { storageKeys } from '../../storage/storage-keys';
import { ShowErrorInstance } from '../../singleton';
import { isPublic, isEnquiryEnabled, urlToEnquiry } from '../util/checkConfig';
import { queryStrings } from '../../constants/query-strings';
import { WithRouterProps } from '../../withRouter';
import { useLocation } from 'react-router-dom';
import { jwtDecode, JwtPayload } from 'jwt-decode';

class Auth {
  userManager: UserManager;
  user: User;
  accessToken?: string;

  constructor() {
    if (!isPublic()) {
      this.userManager = new UserManager({
        ...getIdentityConfig(),
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        metadata: {
          ...getMetadataOidc(),
        },
      });

      this.userManager.events.addSilentRenewError((e) => {
        ShowErrorInstance.Fn(e.message);
      });
    }
  }

  startAuthentication = async (
    router: WithRouterProps,
    urlQueryString?: string | undefined,
  ): Promise<boolean> => {
    const accessTokenQueryName = 'access_token';
    // Check if there is an access token in the URL search params. If so, use that for authentication
    const searchParams = router.searchParams;
    const hash = router.location.hash;
    const hashSerchParams = new URLSearchParams(hash.substring(hash.indexOf('?')));
    const accessToken =
      hashSerchParams.get(accessTokenQueryName) ??
      searchParams.get(accessTokenQueryName);
    if (accessToken) {
      this.accessToken = accessToken;
      await this.completeAuthenticationWithAccessToken(accessToken);
      // Remove token from URL
      searchParams.delete(accessTokenQueryName);
      router.location.hash.substring(0, hash.indexOf('?'));

      const location: ReturnType<typeof useLocation> = {
        ...router.location,
        hash:
          hashSerchParams.size > 0 ? `${hash.substring(0, hash.indexOf('?'))}` : '',
        search: searchParams.size > 0 ? `?${searchParams.toString()}` : '',
      };
      router.navigate(location);
      return true;
    }

    const storageService = standardStateStore.getState().storageService!;
    storageService.writeToStorage(storageKeys.REDIRECT_ON_LOGIN, router.location);

    let externalClient = '';
    if (urlQueryString) {
      const queryStringValues = queryString.parse(urlQueryString);
      if (queryStringValues) {
        var externalClientValue = queryStringValues[queryStrings.externalClient];
        if (externalClientValue) {
          externalClient = externalClientValue.toString();
        }
      }
    }

    await this.userManager.signinRedirect({
      extraQueryParams: {
        externalClient: externalClient,
        registerPageUrl: isEnquiryEnabled()
          ? `${urlJoin(urlToEnquiry(), 'register')}`
          : '',
        resendActivationPageUrl: isEnquiryEnabled()
          ? `${urlJoin(urlToEnquiry(), 'resend-activation-link')}`
          : '',
        originalFullReturnUrl: window.location.href,
      },
    });

    return false;
  };

  logOut = (): Promise<void> => {
    return this.userManager.signoutRedirect();
  };

  completeAuthenticationWithAccessToken = async (accessToken: string) => {
    const parsedToken = jwtDecode<JwtPayload & { preferred_username?: string }>(
      accessToken,
    );

    this.user = new User({
      access_token: accessToken,
      profile: {
        aud: parsedToken.aud ?? '',
        exp: parsedToken.exp ?? 0,
        iat: parsedToken.iat ?? 0,
        iss: parsedToken.iss ?? '',
        sub: parsedToken.sub ?? '',
        preferred_username: parsedToken.preferred_username,
      },
      expires_at: parsedToken.exp,
      token_type: 'Bearer',
    });
  };

  completeAuthentication = (router: WithRouterProps): Promise<void> => {
    return this.userManager.signinRedirectCallback().then((user) => {
      this.user = user;
      const storageService = standardStateStore.getState().storageService!;
      const redirectLocation = storageService.readFromStorage(
        storageKeys.REDIRECT_ON_LOGIN,
      );
      const url =
        redirectLocation && redirectLocation !== 'undefined'
          ? redirectLocation
          : '/';
      router.navigate(url, { replace: true });
      storageService.removeFromStorage(storageKeys.REDIRECT_ON_LOGIN);
    });
  };

  isLoggedIn = (): boolean => {
    return this.user != null && !this.user.expired;
  };

  getAccessToken = async (): Promise<string> => {
    if (this.accessToken) {
      return this.accessToken;
    }

    const user = await this.userManager.getUser();
    return user?.access_token ?? '';
  };

  getProtectedApiData = (
    url: string,
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<any>> =>
    this.getAccessToken().then((userToken: string) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
      axios.defaults.headers.common['Allow-Control-Allow-Origin'] = '*';
      return axios.get(url, config);
    });

  postProtectedApiData = (url: string, data: any): Promise<AxiosResponse<any>> =>
    this.getAccessToken().then((userToken: string) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
      axios.defaults.headers.common['Allow-Control-Allow-Origin'] = '*';

      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
      };

      return axios.post(url, data, config);
    });

  getPublicApiData = (url: string, config: any): Promise<AxiosResponse<any>> => {
    axios.defaults.headers.common['Allow-Control-Allow-Origin'] = '*';
    return axios.get(url, config);
  };

  setBearerToken = async (headers: Headers) => {
    const userToken = await this.getAccessToken();
    headers.set('Authorization', `Bearer ${userToken}`);
  };
}

export default Auth;
