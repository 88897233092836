import { ComponentArg } from '../../types/component-arg';
import { JsonNodeTypeBranch, NodeId } from '../../types/json-node.type';
import { uuid } from './uuid';

function key( arg: ComponentArg ): NodeId {
    return nodeKey(arg.node);
}

function nodeKey( node: JsonNodeTypeBranch | undefined ): NodeId {
    return ( node && node.id ) || uuid();
}

function ridKey( arg: ComponentArg ): NodeId {
    return ( arg.node && arg.node.a[ 'rid' ] ) || '';
}

export { key, nodeKey, ridKey };
