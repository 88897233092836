import React from 'react';
import styles from './eform.module.css';
import { ComponentArg } from '../../types/component-arg';
import { EformContentType, eformContentTypes } from './constants';
import Counterparty from './counterparty/counterparty';
import Representative from './representative/representative';
import Realestate from './realestate/realestate';
import Billing from './billing/billing';
import Timelimits from './timelimits/timelimits';
import Signatures from './signatures/signatures';

interface Props {
  arg: ComponentArg;
}

const EForm = ({ arg }: Props) => {
  if (!arg.node) {
    return null;
  }

  const contentType = arg.node?.a['content-type'];
  const vocab = arg.node?.a['vocab'];

  let component: React.JSX.Element | null = null;

  switch (contentType) {
    case EformContentType.counterparty:
      component = <Counterparty vocab={vocab} />;
      break;
    case EformContentType.representative:
      component = <Representative />;
      break;
    case EformContentType.realestate:
      component = <Realestate vocab={vocab} />;
      break;
    case EformContentType.billing:
      component = <Billing vocab={vocab} />;
      break;
    case EformContentType.timelimits:
      component = <Timelimits />;
      break;
    case EformContentType.signatures:
      component = <Signatures />;
      break;
    default:
      console.error(`No Eform component found for content type: ${contentType}`);
      break;
  }

  return <div className={styles.wrapper}>{component}</div>;
};

export default EForm;
export { EformContentType, eformContentTypes };
