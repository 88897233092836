import { some, find, partition, reduce, flattenDeep } from 'lodash';
import { NodeIdTextSize } from '../types/json-node.type';
import { TocData } from '../types/toc-data';

export interface TocDataChildren {
    id: string;
    parent?: string;
    children: TocDataChildren[];
    originalOrder: number;
    textChildrenSize: number;
}

/**        API
 * */
function flatOrder( tocData: TocData[] ): NodeIdTextSize[] {
    const children: TocDataChildren[] = tocData.map( mapType );
    return flatOrder_( children );
}

function flatOrder_( children: TocDataChildren[] ): NodeIdTextSize[] {
    const nested = nest( children );
    reorder( nested );
    if ( nested.length === 0 ) {
        return [];
    }
    const array2: NodeIdTextSize[][] = nested.map( item => [ tocData2idText( item ), ...flatOrder_( item.children ) ] );
    return flattenDeep( array2 ) as NodeIdTextSize[];
}

function tocData2idText( item: TocDataChildren ): NodeIdTextSize {
    return {
        nodeId: item.id,
        textChildrenSize: item.textChildrenSize
    };
}

function reorder( list: TocDataChildren[] ): void {
    list.sort( comparer );
    list.forEach( item => reorder( item.children ) );
}

function comparer( a: TocDataChildren, b: TocDataChildren ): -1 | 0 | 1 {
    if ( a.originalOrder > b.originalOrder ) {
        return 1;
    }

    if ( a.originalOrder < b.originalOrder ) {
        return -1;
    }

    throw Error( 'duplicate originalOrder' );
}

function nest( acc: TocDataChildren[] ): TocDataChildren[] {
    const predicate1 = ( item: TocDataChildren ) => noChildren( acc, item ),
        leaves = acc.filter( predicate1 ),
        acc1 = reduce( leaves, attachToParent, acc );

    return acc.length === acc1.length ? acc1 : nest( acc1 );
}

function attachToParent( acc: TocDataChildren[], current: TocDataChildren ): TocDataChildren[] {

    const mparent: TocDataChildren | undefined = findParent( acc, current );
    if ( !mparent ) {
        return acc;
    }

    return attachToParent_( [ mparent, current ], acc );
}

function attachToParent_(
    parentChildTuple: [ TocDataChildren, TocDataChildren ],
    list: TocDataChildren[]
): TocDataChildren[] {
    const parent = parentChildTuple[ 0 ],
        child = parentChildTuple[ 1 ],
        parentsPartitioned = partition( list, n => n.id === parent.id ),
        parent1: TocDataChildren[] = parentsPartitioned[ 0 ],
        exParent1: TocDataChildren[] = parentsPartitioned[ 1 ],
        childrenPartitioned = partition( exParent1, n => n.id === child.id ),
        child1: TocDataChildren[] = childrenPartitioned[ 0 ],
        exChild1: TocDataChildren[] = childrenPartitioned[ 1 ],
        parent2 = parent1[ 0 ],
        child2 = child1[ 0 ];

    if ( parent2 && child2 ) {
        parent2.children.unshift( child2 ); // add child2 to the end of the list
        exChild1.unshift( parent2 );
        return exChild1;
    } else {
        return list;
    }
}

function findParent( acc: TocDataChildren[], n: TocDataChildren ): TocDataChildren | undefined {
    return find( acc, b => b_isParent_a( n, b ) );
}

function noChildren( acc: TocDataChildren[], n: TocDataChildren ): boolean {
    return !( some( acc, b => b_isChild_a( n, b ) ) );
}

function b_isChild_a( a: TocDataChildren, b: TocDataChildren ): boolean {
    return b.parent !== undefined &&
        b.parent === a.id;
}

function b_isParent_a( a: TocDataChildren, b: TocDataChildren ): boolean {
    return b_isChild_a( b, a );
}

function mapType( toc: TocData, idx: number ): TocDataChildren {
    return {
        id: toc.id,
        parent: toc.parent,
        children: [],
        originalOrder: idx,
        textChildrenSize: toc.textChildrenSize
    };
}

export {
    flatOrder,
    nest,
    reorder,
    findParent,
    noChildren,
    b_isParent_a,
    b_isChild_a
};
