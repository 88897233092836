import React, { Component } from 'react';

import { ComponentArg } from '../../types/component-arg';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { ImageKey } from '../../types/image/image.types';
import { ImgModal } from './imgModal';
import { scrollToLastElement } from '../../features/scroll/actions';

export interface GraphicProp extends ComponentArg {
    isinViewport( el: HTMLImageElement ): boolean;
    isInline: boolean;
}

class Graphic extends Component<GraphicProp> {
    constructor( p: GraphicProp ) {
        super( p );
        this.setImageKey();
    }

    private setImageKey() {
        let imageId = this.imageId;
        if ( imageId ) {
            imageId = imageId.toLowerCase();
            this.imageKey = {
                productId: standardStateStore.getState().requestUrlData!.productId!,
                organization: standardStateStore.getState().requestUrlData!.organization,
                imageId,
                xmlFileInfo: standardStateStore.getState().graphService!.productInfo!.xmlFileInfo
            };
        }
    }

    imageKey: ImageKey;

    private get imageId(): string | undefined {
        const node = this.props.node;
        return node ? node.a[ 'xlink:href' ] : undefined;
    }

    private get isInsideRequirement(): boolean {
        const node = this.props.node;
        return node && node.a[ 'is-inside-requirement' ] ? true: false;
    }

    render() {
        scrollToLastElement();
        const imageId = this.imageId,
            isInsideRequirement = this.isInsideRequirement,
            imageTitle = imageId ? imageId : 'Default Image';

        return ( <ImgModal
          {...this.props}
          imageKey={this.imageKey}
          imageTitle={imageTitle}
          isInsideRequirement={isInsideRequirement}
          isInline={this.props.isInline}/> );
    }
}

export { Graphic };
