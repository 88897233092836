function toggleBodyClass( className: string, on: boolean ) {
    const body = document.querySelector( 'body' );
    if ( body ) {
        if ( on ) {
            body.classList.add( className );
        } else {
            body.classList.remove( className );
        }
    }
}

export { toggleBodyClass };
