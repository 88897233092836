import { NavigationStrategy, ScrollNotification } from '../../../types/lazy.type';
import { AboveBelow, UpdateSectionService } from '../update-section.service';
import { TocService } from '../toc.service';

class UserScrollStrategy
    implements NavigationStrategy {
    constructor(
        private updateSectionService: UpdateSectionService
    ) { }

    onScroll( notification: ScrollNotification ): void {
        const updateService = this.updateSectionService;

        const trimAbove = UpdateSectionService.shouldSectionsTrim( notification, AboveBelow.above ),
            trimBelow = UpdateSectionService.shouldSectionsTrim( notification, AboveBelow.below ),
            expandAbove = UpdateSectionService.shouldSectionsExpand( notification, AboveBelow.above ),
            expandBelow = UpdateSectionService.shouldSectionsExpand( notification, AboveBelow.below );

        if ( trimAbove ) {
            UpdateSectionService.trimSections( AboveBelow.above );
        }

        if ( trimBelow ) {
            UpdateSectionService.trimSections( AboveBelow.below );
        }

        if ( expandAbove ) {
            updateService.expandSections( AboveBelow.above );
        }

        if ( expandBelow ) {
            updateService.expandSections( AboveBelow.below );
        }

        if ( trimAbove || trimBelow || expandAbove || expandBelow ) {
            TocService.notifyUpdateSections();
        }
    }
}

export { UserScrollStrategy };
