import { GraphService } from '../../../graph/graph.service';
import { LazyState } from '../lazy.state';
import { TocData } from '../../../types/toc-data';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { allScrollableSectionDataStore } from '../../../redux/worker/stores/allScrollableSectionData.store';
import { WorkerMessageType, ToWorker } from '../../../types/workerMessage.type';

abstract class InitLazyStateBase {
  constructor(
    protected graphService: GraphService,
    protected state: LazyState,
  ) {
    this._standardTocData = graphService.standardTocData;
  }

  async initData(): Promise<void> {
    await this.setLazyLoadedIds();
    await this.setflatRenderedTextSize();
    await this.setSectionData();
  }

  protected abstract setLazyLoadedIds(): Promise<void>;

  protected abstract setflatRenderedTextSize(): Promise<void>;

  protected readonly _standardTocData: TocData[];

  protected async setSectionData() {
    const graphService = this.graphService,
      fullModel = graphService.fullJsonData,
      state = this.state;

    if (fullModel) {
      const allLazyLoadedIdsInDisplayOrder = state.allLazyLoadedIdsInDisplayOrder,
        data4worker: ToWorker = [
          WorkerMessageType.allScrollableSectionData,
          allLazyLoadedIdsInDisplayOrder,
        ];

      const promise = makeWorkerPromise(data4worker, allScrollableSectionDataStore);
      state.allScrollableSectionData = await promise;
    }
  }
}

export { InitLazyStateBase };
