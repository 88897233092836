import { InitLazyStateBase } from './init-lazy-state.base';
import { NodeId } from '../../../types/json-node.type';
import { compact, uniq } from 'lodash';
import { ProductInfo } from '../../../types/product-info';
import { ToWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { flatRenderedTextSizeStore } from '../../../redux/worker/stores/flatRenderedTextSize.store';

export abstract class InitLazyStateProductInfo extends InitLazyStateBase {
  protected abstract getIds(productInfo: ProductInfo): NodeId[];

  private _ids: NodeId[];

  protected async setLazyLoadedIds(): Promise<void> {
    this._ids = this.state.allLazyLoadedIdsInDisplayOrder = this.uniqueCompactIds();
  }

  private uniqueCompactIds(): NodeId[] {
    const ids1: NodeId[] = this.getIds(this.graphService.productInfo!);
    return uniq(compact(ids1));
  }

  protected async setflatRenderedTextSize(): Promise<void> {
    const state = this.state,
      data4worker: ToWorker = [WorkerMessageType.flatRenderedTextSize, this._ids],
      promise = makeWorkerPromise(data4worker, flatRenderedTextSizeStore);

    await promise.then((map) => (state.flatRenderedTextSize = map));
  }
}
