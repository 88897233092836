import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function indexTerm( arg: ComponentArg ): JSX.Element | null {

    const hideElement =  arg.node!.a['hide-element'];
    if (hideElement) {
        return null;
    }

    if (arg.node!.c && arg.node!.c.length) {
        const childNode = arg.node!.c[0];
        if (childNode.x!.tag === 'term' && childNode.x!.c && childNode.x!.c.length === 1 ) {
            if (childNode.x!.c[0].t) {
                return (
                    <span className="index-term" key={ key( arg ) }>{ childNode.x!.c[0].t }</span>
                );
            }
        }
    }

    return (
        <span className="index-term" key={ key( arg ) }>{ defaultChildren( arg ) }</span>
    );
}

export { indexTerm };
