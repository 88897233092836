import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { ComponentArg } from '../../types/component-arg';
import { isFootnoteVisible } from '../../component/util/checkConfig';
import { itemsInFootnotes } from './footnote.Selector';
import FootnoteItem from './footnoteItem';

type FootnoteProps = {
  elementKey: string;
  arg: ComponentArg;
};

const Footnote = ({ elementKey, arg }: FootnoteProps) => {
  const footnotes = useSelector((state) =>
    itemsInFootnotes(state as RootState, elementKey)
  );

  if (isFootnoteVisible() === false) {
    return null;
  }

  const items = footnotes.map((fn) => {
    const key = `${fn.containerId}:${fn.footnoteId}`;
    return (
      <li>
        <FootnoteItem key={key} rid={fn.footnoteId} arg={arg} />
      </li>
    );
  });

  return <ul>{items}</ul>;
};

export default Footnote;
