import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function def(arg: ComponentArg): JSX.Element {
  const directParent = arg.node!.a['direct-parent'];
  if (directParent === 'def-item') {
    return (
      <span className="def" key={key(arg)}>
        {defaultChildren(arg)}
      </span>
    );
  } else {
    return (
      <div className="def" key={key(arg)}>
        {defaultChildren(arg)}
      </div>
    );
  }
}

export { def };
