import { JsonNodeType, MixedContent, MixedContentEnum } from '../types/json-node.type';

function emptyJson(): JsonNodeType {
    const front: JsonNodeType = {
            tag: 'front',
            a: {},
            c: [],
            id: 'f1'
        },
        frontMixed: MixedContent = {
            e: MixedContentEnum.XmlNode,
            x: front
        };
    return {
        id: '1',
        c: [ frontMixed ],
        a: {},
        tag: 'standard'
    };
}

export { emptyJson };
