import { min, max } from 'lodash';
import { LazySerializableState } from './lazy-serializable.state';
import { NodeId } from '../../types/json-node.type';

function nodes2Indices( idSet: Set<NodeId>, lazyState: LazySerializableState ): [ number, number ] {
    const indexArray: number[] = [],
        nodeIds: IterableIterator<NodeId> = idSet.values(),
        array: NodeId[] = Array.from( nodeIds );

    for ( const nodeId of array ) {
        const idx = lazyState.allLazyLoadedIdsIndices.get( nodeId );
        if ( idx !== undefined ) indexArray.push( idx );
    }

    const min1 = min( indexArray ),
        max1 = max( indexArray ),
        defined = ( n: number | undefined ) => n === undefined ? -1 : n;

    return [ defined( min1 ), defined( max1 ) ];
}

export { nodes2Indices };
