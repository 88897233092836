import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function bold( arg: ComponentArg ): JSX.Element {
    return <b key={ key( arg ) }>{ defaultChildren( arg ) }</b>;
}

export { bold };
