import { RequirementType } from '../../types/requirement.type';

export enum ViewOnlyCategory {
    figures = 'figures',
    tables = 'tables',
    requirements = 'requirements',
    themes = 'themes',
    commentingEnabled = 'commentingEnabled'
}

export interface FilterDocumentState {
    filterIsApplied: boolean,
    viewOnlyCategory?: ViewOnlyCategory,
    selectedRequirementTypes: Set<RequirementType>,
    selectedThemes: string[],
}
