export default class CssBuilder {
  cssClasses: string[];

  constructor() {
    this.cssClasses = [];
  }

  AddClass(cssClass: string): CssBuilder {
    if (cssClass === '')
    {
      return this;
    }

    this.cssClasses.push(cssClass);
    return this;
  }

  Build(): string {
    const result = this.cssClasses.join(' ');
    return result;
  }
}

export function CreateCssBuilder(): CssBuilder {
  let result = new CssBuilder();
  return result;
}