
export type IdSiblingString = string;

export interface IndexEntry<Id> {
    id: Id
    textContent: string,
    vocabTerm: string,
    themes: string[],
}

export interface IndexEntryProperties {
    themes: string[],
    vocabTerm: string,
}

export function EmptyProperties(): IndexEntryProperties {
    const emptyValue: IndexEntryProperties = {
        vocabTerm: '',
        themes: [],
    };

    return emptyValue;
}

export type Position = [ number, number ];

export interface ResultMatch {
    matchedString: string
    position: Position[]
}

export interface TextSearchResult {
    id: IdSiblingString,
    resultMatches: ResultMatch[]
}

export type SortedDisplayOrderEntry = [ IdSiblingString, Position ];
