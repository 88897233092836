import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/reducer'
import { newTimestamp, nothingToScrollTo, ScrollState } from './ScrollState';

export interface ScrollStateInformation {
  idToScrollTo: string,
  timestamp: number,
}

const documentWrapper = (state: RootState) => state.ScrollState

const itemToScrollTo = createSelector(documentWrapper,  (wrapper: ScrollState): ScrollStateInformation => {
  if (!isScrollValid(wrapper)) {
    return {
      idToScrollTo: nothingToScrollTo,
      timestamp: wrapper.updatedAt,
    }
  }

  return {
    idToScrollTo: wrapper.idToScrollTo,
    timestamp: wrapper.updatedAt
  }
});

const scrollableItems = createSelector(documentWrapper,  (wrapper: ScrollState): string[] => {
  return wrapper.scrollableItems.slice();
});

function isScrollValid(state: ScrollState): boolean {
  const currentTimestamp = newTimestamp();
  const scrollTimeToLive = 5 * 1000;
  if (currentTimestamp - state.updatedAt > scrollTimeToLive) {
    return false;
  }

  return true;
}

export { itemToScrollTo, scrollableItems }