import * as assert from 'assert';
import { reverse } from 'lodash';

import { JsonNodeType, MixedContent, MixedContentEnum, JsonNodeTypeBranch } from '../types/json-node.type';
import { CyNode } from '../types/graph.type';

function getBranch( fullModel: JsonNodeType, path: CyNode[] ): JsonNodeTypeBranch {
    if ( path.length === 0 ) {
        throw Error( 'expect non-empty path' );
    }
    const topDownPath = reverse( [ ...path ] );
    let currentNode: JsonNodeTypeBranch = fullModel;
    topDownPath.forEach( ( node: CyNode ) => {
        const child: MixedContent = currentNode.c[ node.siblingIdx ];
        assert.ok( child && child.e === MixedContentEnum.XmlNode, 'expect child of type XmlNode' );
        assert.ok( child.x, 'expect child.x' );
        currentNode = child.x as JsonNodeTypeBranch;
    } );

    const result = { ...currentNode };
    result.isTop = true;
    return result;
}

export { getBranch };
