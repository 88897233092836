import moment from 'moment';

class DebugTimer {
    constructor(private logComment: string) {}

    private begin_?: moment.Moment;
    private lap_?: moment.Moment;

    begin(): void {
        console.debug(`%cbegin ${this.logComment}`, 'color:green;');
        this.begin_ = moment();
        this.lap_ = moment();
    }

    lap(lap: string): void {
        this.clock(lap, this.lap_, 'silver');
        this.lap_ = moment();
    }

    end(): void {
        this.clock('end', this.begin_, 'red');
        this.begin_ = undefined;
        this.lap_ = undefined;
    }

    private clock(step: string, start: moment.Moment | undefined, color: string): void {
        if (!start) {
            return;
        }
        const end = moment(),
            duration = moment.duration(end.diff(start)),
            asSeconds = duration.asSeconds(),
            asMs = duration.asMilliseconds(),
            display = asSeconds > 0.09 ? `${asSeconds} s.` : `${asMs} ms.`;

        console.debug(`%c${step} ${this.logComment} took ${display}`, `color:${color};`);
    }
}

export { DebugTimer };
