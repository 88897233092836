import React, { useEffect } from "react";
import { Tooltip } from "@mui/material";
import { isMobileSafari } from 'react-device-detect';

export interface TooltipWrapperProps {
    title: JSX.Element;
    termTitle?: string;
}

function TooltipWrapper( props: TooltipWrapperProps ): JSX.Element {

    const [open, setOpen] = React.useState(false);

    function handleTooltipClose() {
        setOpen(false);
    }

    function handleTooltipOpen() {       
        setOpen(!open);       
    }

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);  

    function handleTouchStart() {
        setOpen(false);
    }    

    const tooltipContent = (
        <span className="hasTooltip textHint" onClick={ handleTooltipOpen }  tabIndex={ 0 }>
            { props.termTitle! }
        </span>
    );

    if (isMobileSafari) {      
        return (           
            <Tooltip 
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={ props.title }>{ tooltipContent }
            </Tooltip>                      
        );       
    } else {
        return ( 
            <Tooltip 
                title={ props.title }
                disableTouchListener>{ tooltipContent }
            </Tooltip> 
        );
    }   
}

export { TooltipWrapper }