import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { storeUrl, urlToEnquiry } from '../../../../../component/util/checkConfig';
import styles from './DocumentClosedPage.module.less';
import PublishedDocumentHeader from '../../organisms/PublishedDocumentHeader/PublishedDocumentHeader';
import Header from '../../organisms/Header/Header';

export interface DocumentClosedProps {
  published: boolean;
}

const DocumentClosedPage = ({ published }: DocumentClosedProps) => {
  const { t } = useTranslation();

  const redirectToStore = () => {
    window.location.href = storeUrl();
  }

  const redirectToEnquiry = () => {
    window.location.href = urlToEnquiry();
  }

  return (
    <>
      {published ? <PublishedDocumentHeader /> : <Header />}
      <main className="viewerMain" aria-label={t('MainContent')}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            {t('Enquiry.ClosedForCommenting')}
          </h2>
          {published ? (
            <Button type="primary" size="large" onClick={redirectToStore}>
              {t('Enquiry.RedirectToStore')}
            </Button>
          ) : (
            <Button type="primary" size="large" onClick={redirectToEnquiry}>
              {t('Enquiry.RedirectToEnquiry')}
            </Button>
          )}
        </div>
      </main>
    </>
  )
}

export default DocumentClosedPage;