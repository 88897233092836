import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { NodeId } from '../../../types/json-node.type';

type Content = WorkerStoreItem<NodeId | null>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const getClosestParentSectionByIdStore: Store<S, A> = createStore( identityReducer as Reducer );

export { getClosestParentSectionByIdStore };
