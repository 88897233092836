import { reduce } from 'lodash';

import { InitLazyStateBase } from './init-lazy-state.base';
import { NodeIdTextSize } from '../../../types/json-node.type';
import { TocData } from '../../../types/toc-data';
import { lazyLoadedSections } from '../../../graph/lazyLoadedSections';

export class InitLazyStateStandard extends InitLazyStateBase {
  private tocDataWithSize: NodeIdTextSize[];

  protected async setLazyLoadedIds(): Promise<void> {
    this.tocDataWithSize = lazyLoadedSections(this._standardTocData);
    this.state.allLazyLoadedIdsInDisplayOrder = this.tocDataWithSize.map(
      (item) => item.nodeId,
    );
  }

  protected async setflatRenderedTextSize() {
    const state = this.state;
    this.tocDataWithSize.forEach((item) => {
      const netTextSize = this.calcNetTextSize(item);
      state.flatRenderedTextSize.set(item.nodeId, netTextSize);
    });
  }

  private calcNetTextSize(item: NodeIdTextSize): number {
    const directChildrenSize: number = reduce(
      this._standardTocData.filter((item1) => item1.parent === item.nodeId),
      (acc: number, item2: TocData) => item2.textChildrenSize + acc,
      0,
    );

    return item.textChildrenSize - directChildrenSize;
  }
}
