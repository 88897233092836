import React from 'react';
import { ComponentArg } from '../../../../types/component-arg';
import ValueRender, { canRender, ValueProps } from './value-render';

const jaNeiComponent = 'Ja ☐ Nei ☐'.toLowerCase();

const ValueKeyword = ({ component, vocab }: ValueProps) => {
  const vocabValue = 'keyword';
  if (canRender({ component, vocab, vocabValue })) {
    var components = component!.node!.c.filter(
      (x) => x.t == null || !x.t.toLowerCase().includes(jaNeiComponent)
    );
    var componentsJaNei = component!.node!.c.filter(
      (x) => x.t != null && x.t.toLowerCase().includes(jaNeiComponent)
    );

    var componentsComponentArg: ComponentArg = {
      ...component,
      node: {
        ...component.node!,
        c: components,
      },
    };

    var componentsJaNeiComponentArg: ComponentArg = {
      ...component,
      node: {
        ...component.node!,
        c: componentsJaNei,
      },
    };

    const renderComponents = (
      component: ComponentArg,
      countOfComponents: number
    ): JSX.Element | null => {
      if (countOfComponents === 0) {
        return null;
      }

      return (
        <>
          {'('}
          <ValueRender
            component={component}
            vocab={vocab}
            vocabValue={vocabValue}
          />
          {')'}
        </>
      );
    };

    const renderJaNeiComponents = (
      component: ComponentArg,
      countOfComponents: number
    ): JSX.Element | null => {
      if (countOfComponents === 0) {
        return null;
      }

      return (
        <>
          <ValueRender
            component={component}
            vocab={vocab}
            vocabValue={vocabValue}
          />
        </>
      );
    };

    return (
      <>
        {renderComponents(componentsComponentArg, components.length)}
        {renderJaNeiComponents(componentsJaNeiComponentArg, componentsJaNei.length)}
      </>
    );
  }

  return null;
};

export default ValueKeyword;
