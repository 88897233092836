import React, { Component } from 'react';
import { Unsubscribe } from 'redux';
import { cloneDeep, isEqual } from 'lodash';

import { ComponentArg } from '../../types/component-arg';
import { isNode, isText, NodeId } from '../../types/json-node.type';
import { JsonNodeInstance } from '../../singleton';
import { IdSibling, idSibling2string } from '../side-bar/search-document/idSibling';
import { searchStore } from '../../redux/search';
import { SearchState } from '../../redux/search/search.reducer';
import { IdSiblingString, Position, SortedDisplayOrderEntry } from '../../types/search.type';
import { highlightText } from './mixed-content-highlight';
import { highlightedContainer } from '../../features/searchmarkcontainer/SearchMarkSelector';
import store from '../../redux/store';
import { addItem } from '../../features/searchmarkcontainer/SearchMarkState';
import SearchMarkInput from '../../features/searchmarkcontainer/SearchMarkInput';

export interface MixedContentProp extends ComponentArg {
    parentNodeId: NodeId;
    siblingIdx: number;
}


interface PositionForState {
    startPosition: number,
    offset: number,
}

interface MixedContentState {
    highlightPositions: PositionForState[];
    bookmarkedPosition?: Position;
}

class MixedContentComponent
    extends Component<MixedContentProp, MixedContentState> {
        private storeUpdatedUnsubscribe: Unsubscribe = () => false;

    constructor( p: MixedContentProp ) {
        super( p );
        const idSibling: IdSibling = { id: p.parentNodeId, siblingIdx: p.siblingIdx };
        this.myIdSiblingString = idSibling2string( idSibling );
        this.state = { highlightPositions: [], bookmarkedPosition: undefined };
    }

    private readonly myIdSiblingString: IdSiblingString;
    private searchStoreUnsubscribe: Unsubscribe = () => false;
    private _isMounted = false;

    componentDidMount(): void {
        this._isMounted = true;
        const { mixedContent } = this.props;
        if ( mixedContent && isText( mixedContent ) ) {
            this.searchStoreUnsubscribe = searchStore.subscribe( this.copySearchStoreResult2ComponentState.bind( this ) );
            this.copySearchStoreResult2ComponentState();
        }

        this.storeUpdatedUnsubscribe = store.subscribe(this.storeUpdated.bind( this ));
    }

    componentDidUpdate( prevProps: Readonly<MixedContentProp>, prevState: Readonly<MixedContentState>, snapshot?: any ): void {
        // TODO: need to better understand what impact this has, but this is completely wrong as it
        //causes endless loop - this copy method sets state, which in turn calls this update again
        /*
        const delayed = this.copySearchStoreResult2ComponentState.bind( this );
        delay( delayed, 0 );   //  delay is necessary to prevent endless loop
        */
    }

    componentWillUnmount(): void {
        this._isMounted = false;
        this.searchStoreUnsubscribe();
        this.setState(
            { highlightPositions: [] }
        );
        this.storeUpdatedUnsubscribe();
    }

    storeUpdated() {
        if (this.props.mixedContent?.x?.id) {
            const state = store.getState();
            const isMarkedAsContainer = highlightedContainer(state, this.props.mixedContent.x.id);
            const doIdIsInList = highlightedContainer(state, this.props.parentNodeId);
            if (isMarkedAsContainer) {
                if (!doIdIsInList) {
                    const input: SearchMarkInput = {
                        parentid: this.props.parentNodeId,
                    }
                    store.dispatch(addItem(input));
                }
            }
        }
    }

    copySearchStoreResult2ComponentState() {
        const searchStoreState: SearchState = searchStore.getState(),
            myNewPositions: Position[] = searchStoreState.resultMap.get( this.myIdSiblingString ) || [],
            newBookmarkedPosition: Position | undefined = this.bookmarkedPosition( searchStoreState ),
            { highlightPositions, bookmarkedPosition } = this.state;

        if ( this._isMounted &&
            ( newBookmarkedPosition !== bookmarkedPosition ||
                !isEqual( myNewPositions, highlightPositions )
                || ( highlightPositions.length < 1 )
            )
        ) {
            this.setState( {
                highlightPositions: myNewPositions.map(x => { return {
                    startPosition: x[0],
                    offset: x[1],
                } as PositionForState }),
                bookmarkedPosition: newBookmarkedPosition,
            } );
        }
    }

    private bookmarkedPosition( searchStore: SearchState ): Position | undefined {
        const entry: SortedDisplayOrderEntry | undefined = searchStore.sortedDisplayOrder[ searchStore.bookmark ];

        if ( !entry ) return undefined;

        const [ idSibling, position ] = entry!;
        return idSibling === this.myIdSiblingString ? cloneDeep( position ) : undefined;
    }

    render() {
        const { mixedContent, t } = this.props;
        if ( !mixedContent ) return null;

        const isText1 = isText( mixedContent ) && mixedContent.t,
            isNode1 = isNode( mixedContent ) && mixedContent.x;

        if ( isText1 ) return this.renderText( mixedContent.t );
        if ( isNode1 ) return JsonNodeInstance.Fn( { node: mixedContent.x, t } );
        throw Error( `invalid mixedContent: ${JSON.stringify( mixedContent )}` );
    }

    renderText( text?: string ): string | JSX.Element | null {
        if ( !text ) return null;
        const { highlightPositions, bookmarkedPosition } = this.state;
        if ( highlightPositions.length === 0 ) {
            return <span className="mixed-content-text">{text}</span>;
        } 

        const highlightPostionsToPass = highlightPositions.map(x => { return { "0": x.startPosition, "1": x.offset } as Position })

return <>
            {highlightText( [ text ], highlightPostionsToPass, bookmarkedPosition )}
        </>;
    }
}

export { MixedContentComponent };
