import { NodeId, JsonNodeType } from '../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { GraphService } from '../../graph/graph.service';
import { uuid } from '../util/uuid';

/**    send to worker  */
class LazySerializableState {
    allCount: number = 0;
    allLazyLoadedIdsIndices: Map<NodeId, number> = new Map();
    allLazyLoadedIdSet: Set<NodeId> = new Set();
    flatRenderedTextSize: Map<NodeId, number> = new Map();
    allScrollableSectionData: JsonNodeType[] = [];
    allLazyLoadedIdsInDisplayOrder: NodeId[] = [];
}

class LazySerializableStateDirty extends LazySerializableState {
    constructor( state?: LazySerializableState ) {
        super();
        if ( !state ) return;

        this._allCount = state.allCount;
        this._allLazyLoadedIdSet = new Set( state.allLazyLoadedIdSet );
        this._allLazyLoadedIdsIndices = new Map( state.allLazyLoadedIdsIndices );
        this._allScrollableSectionData = Array.from( state.allScrollableSectionData );
        this._allLazyLoadedIdsInDisplayOrder = Array.from( state.allLazyLoadedIdsInDisplayOrder );
    }

    protected _allLazyLoadedIdsInDisplayOrder: NodeId[] = [];
    public send2workerOnDirty: boolean = false;

    private _isDirty: boolean = true;
    protected get isDirty(): boolean {
        return this._isDirty;
    }

    protected set isDirty( value: boolean ) {
        this._isDirty = value;

        if ( this.send2workerOnDirty ) {
            const serializable: LazySerializableState = this.extractSerializable(),
                data4worker: ToWorker = [ WorkerMessageType.lazyState, serializable ],
                graph: GraphService = standardStateStore.getState().graphService!;

            graph.sendToWorker(data4worker, uuid());
        }
    }

    /**    pass safe idx!
     * */
    idx2NodeId( idx: number ): NodeId | undefined {
        return this.allLazyLoadedIdsInDisplayOrder[ idx ];
    }

    private _allCount: number = 0;
    // @ts-ignore
    get allCount(): number {
        return this._allCount;
    }

    set allCount( value: number ) {
        this._allCount = value;
        this.isDirty = true;
    }

    private _allLazyLoadedIdsIndices: Map<NodeId, number> = new Map();
    // @ts-ignore
    get allLazyLoadedIdsIndices(): Map<NodeId, number> {
        return this._allLazyLoadedIdsIndices;
    }

    set allLazyLoadedIdsIndices( value: Map<NodeId, number> ) {
        this._allLazyLoadedIdsIndices = value;
        this.isDirty = true;
    }

    private _allLazyLoadedIdSet: Set<NodeId> = new Set();
    // @ts-ignore
    get allLazyLoadedIdSet(): Set<NodeId> {
        return this._allLazyLoadedIdSet;
    }

    set allLazyLoadedIdSet( value: Set<NodeId> ) {
        this._allLazyLoadedIdSet = value;
        this.isDirty = true;
    }

    private _flatRenderedTextSize: Map<NodeId, number> = new Map();
    // @ts-ignore
    get flatRenderedTextSize(): Map<NodeId, number> {
        return this._flatRenderedTextSize;
    }

    set flatRenderedTextSize( value: Map<NodeId, number> ) {
        this._flatRenderedTextSize = value;
        this.isDirty = true;
    }

    private _allScrollableSectionData: JsonNodeType[] = [];
    // @ts-ignore
    get allScrollableSectionData(): JsonNodeType[] {
        return this._allScrollableSectionData;
    }

    set allScrollableSectionData( value: JsonNodeType[] ) {
        this._allScrollableSectionData = value;
        this.isDirty = true;
    }

    private extractSerializable(): LazySerializableState {
        const result = new LazySerializableState();
        result.allCount = this.allCount;
        result.allLazyLoadedIdSet = new Set( this.allLazyLoadedIdSet );
        result.allLazyLoadedIdsIndices = new Map( this.allLazyLoadedIdsIndices );
        result.allLazyLoadedIdsInDisplayOrder = Array.from( this._allLazyLoadedIdsInDisplayOrder );
        result.allScrollableSectionData = Array.from( this.allScrollableSectionData );
        result.flatRenderedTextSize = new Map( this.flatRenderedTextSize );
        return result;
    }
}

export { LazySerializableState, LazySerializableStateDirty };
