import { Dialog } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { NodeId } from '../../../types/json-node.type';
import { IRelatedFootnote } from '../../../features/footnote/footnoteItem';
import getRelatedFootnoteNode from '../../../features/footnote/getRelatedFootnoteNode';
import { key } from '../../util/key';
import { ComponentArg } from '../../../types/component-arg';
import { defaultChildren } from '../../generic/default-children';
import { useTranslation } from 'react-i18next';

export interface ModalComponentProps {
    arg: ComponentArg,
    nodeToRender: NodeId,
    children: JSX.Element
} 

function ModalComponent(props: ModalComponentProps): JSX.Element | null {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false),
      [scroll, setScroll] = useState<DialogProps['scroll']>(
        props.arg.useScrollTypeBody ? 'body' : 'paper'
      );
  
    const [relatedFootnoteNodeState, setRelatedFootnoteNodeState] =
      useState<IRelatedFootnote>({ relatedFootnoteNode: undefined });
  
    const handleClickOpen = (scrollType: DialogProps['scroll']) => {
      setOpen(true);
      setScroll(scrollType);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    function callback(e: React.MouseEvent) {
      e.preventDefault();
      handleClickOpen(props.arg.useScrollTypeBody ? 'body' : 'paper');
    }
  
    const key1 = key(props.arg),
      rid: NodeId = props.nodeToRender;
  
    let footnoteModalContentHtml = undefined;
  
    useEffect(
      function () {
        let isSubscribed = true;
        getRelatedFootnoteNode(rid).then((value) => {
          if (isSubscribed) {
            setRelatedFootnoteNodeState({ relatedFootnoteNode: value });
          }
        });
  
        return () => {
          isSubscribed = false;
        };
      },
      [rid]
    );
  
    if (relatedFootnoteNodeState.relatedFootnoteNode) {
      let labelNodeProps: ComponentArg | undefined = undefined;
      if (
        relatedFootnoteNodeState.relatedFootnoteNode!.c &&
        relatedFootnoteNodeState.relatedFootnoteNode!.c.length
      ) {
        const labelNode = relatedFootnoteNodeState.relatedFootnoteNode!.c[0];
        if (labelNode!.x!.tag === 'label') {
          labelNodeProps = {
            node: labelNode.x,
            t: props.arg.t,
          };
          setRelatedFootnoteNodeState({
            relatedFootnoteNode: {
              ...relatedFootnoteNodeState.relatedFootnoteNode,
              c: relatedFootnoteNodeState.relatedFootnoteNode!.c.slice(1),
            },
          });
        }
      }
  
      footnoteModalContentHtml = (
        <>
          <button key={key1} onClick={callback} className='footnoteButton'>
            {defaultChildren(props.arg)}
          </button>
          <Dialog
            PaperProps={{ square: true }}
            fullWidth={false}
            maxWidth={false}
            className='footnote textInfo'
            scroll={scroll}
            onClose={handleClose}
            aria-labelledby={'modal' + key1}
            open={open}
          >
            {labelNodeProps && labelNodeProps.node ? (
              <h2 id={'modal' + key1} className='modalHeading'>
                {defaultChildren(labelNodeProps!)}
              </h2>
            ) : null}
              <button type="button" onClick={handleClose} className="modalCloseButton" aria-label={t('Close')} />
            {props.children}
          </Dialog>
        </>
      );
    }
  
    return footnoteModalContentHtml || null;
  }

export default ModalComponent;
