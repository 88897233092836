import { createSlice } from "@reduxjs/toolkit";
import SearchMarkInput from "./SearchMarkInput";

export interface SearchMarkState {
  parentsOfHighlightedText: string[]
};

const initialState: SearchMarkState = {
  parentsOfHighlightedText: [],
}

const searchmark = createSlice({
  name: 'SearchMarkContainer',
  initialState,
  reducers: {
    addItems(state, action) {
      const payload = action.payload as SearchMarkInput[];
      const arr = [...Array.from(state.parentsOfHighlightedText)];
      arr.push(...Array.from(payload.map((value: SearchMarkInput) => value.parentid)));
      const uniqueItems = [...Array.from(new Set(arr))];
      state.parentsOfHighlightedText = uniqueItems;
    },
    addItem(state, action) {
      const payload = action.payload as SearchMarkInput;
      const arr = [...Array.from(state.parentsOfHighlightedText)];
      arr.push(payload.parentid);
      const uniqueItems = [...Array.from(new Set(arr))];
      state.parentsOfHighlightedText = uniqueItems;
    },
    removeItem(state, action) {
      const payload = action.payload as SearchMarkInput;
      const arr = state.parentsOfHighlightedText.filter(item => item !== payload.parentid);
      state.parentsOfHighlightedText = arr;
    },
    clearAll(state) {
      state.parentsOfHighlightedText = [];
    },
  }
});

export const {
  addItem,
  removeItem,
  clearAll,
  addItems,
} = searchmark.actions

export default searchmark.reducer