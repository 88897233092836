import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';

type Content = WorkerStoreItem<number | null>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const levelStore: Store<S, A> = createStore( identityReducer as Reducer );

export { levelStore };
