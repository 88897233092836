import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function strike( arg: ComponentArg ): JSX.Element {
    return <del key={ key( arg ) }>{ defaultChildren( arg ) }</del>;
}

export { strike };
