import React, { useState } from 'react';
import { ComponentArg } from '../../../types/component-arg';
import { key } from '../../util/key';
import { defaultChildren } from '../../generic/default-children';
import { useTranslation } from 'react-i18next';
import ScreenReaderOnly from '../../util/screenReaderOnly';
import ConsentDialog from './consentDialog';
import { loadingSpinnerSubject } from '../../../redux/identity/loadingSpinnerSubject';
import { useLazyGetAccessTokenQuery } from '../../../redux/services/freeStoreStandardsApi';

interface Props {
  arg: ComponentArg;
  storeUrl: string;
}

const StoreLink = ({ arg, storeUrl }: Props) => {
  const { t } = useTranslation();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [getAccessToken] = useLazyGetAccessTokenQuery();

  const onAcceptTermsAndConditions = async () => {
    setShowTermsAndConditions(false);
    loadingSpinnerSubject.dispatch({ type: null, content: { visible: true } });

    try {
      const response = await getAccessToken().unwrap();

      const urlWithAccessToken = storeUrl.includes('?')
        ? `${storeUrl}&access_token=${response.accessToken}`
        : `${storeUrl}?access_token=${response.accessToken}`;

      // Open the link
      window.open(urlWithAccessToken, '_blank')?.focus();
    } catch (error) {
      console.error(error);
    }

    loadingSpinnerSubject.dispatch({ type: null, content: { visible: false } });
  };

  return (
    <>
      <a
        className="link"
        key={key(arg)}
        onClick={(event) => {
          event.preventDefault();
          setShowTermsAndConditions(true);
        }}
        target="_blank"
        rel="noopener noreferrer"
        href={storeUrl}
      >
        {defaultChildren(arg)}
        <ScreenReaderOnly>{t('External link')}</ScreenReaderOnly>
      </a>
      {showTermsAndConditions && (
        <ConsentDialog
          onClose={() => setShowTermsAndConditions(false)}
          onAcceptTermsAndConditions={() => void onAcceptTermsAndConditions()}
        />
      )}
    </>
  );
};

export default StoreLink;
