import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/reducer";
import { IdIndex, reduxStateToIdIndex, SearchIdAndPropertiesState } from "./searchIdAndProperties.State";

const idandprops = (state: RootState) => state.SearchIdAndProperties

const metatags = createSelector([idandprops],  (state: SearchIdAndPropertiesState): IdIndex => {
  return reduxStateToIdIndex(state.vocabTerm);
});

const themes = createSelector([idandprops],  (state: SearchIdAndPropertiesState): IdIndex => {
  return reduxStateToIdIndex(state.themes);
});

export { metatags, themes }