import { applyMiddleware, createStore, Reducer, Store } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { throttleReducer, ThrottleState } from './throttleReducer';
import { ThrottleAction, throttleSaga } from './throttleSaga';
import { NodeId } from '../../types/json-node.type';

type S = ThrottleState<NodeId>;
type A = ThrottleAction<NodeId>;
const middleware = createSagaMiddleware(),
    highlightToCSubject: Store<S, A> = createStore(
        throttleReducer as Reducer,
        applyMiddleware( middleware ) );

middleware.run( throttleSaga );

export { highlightToCSubject };
