import { select, takeEvery, call } from '@redux-saga/core/effects';
import { standardStateStore } from '../standard-state/standard-state.store';
import { FilterDocumentState, ViewOnlyCategory } from './filter-document.state';
import { FilterDocumentActionType } from './types';
import { Action } from 'redux';
import { TocService } from '../../component/lazy/toc.service';

function* updateRequirementsState(action: Action<string>) {
  const standardState = standardStateStore.getState(),
    instanceRequirements = standardState.initLazyStateRequirements,
    instanceThemes = standardState.initLazyStateThemes,
    tocService = standardState.tocService,
    thisState: FilterDocumentState = yield select();

  // Skip filtering document if filter is not applied (e.g. if the filter state is set
  // but the filter is not yet used). This also fixes https://standardsdigital.atlassian.net/browse/SVSD-196
  // where the document was reloaded even though there were no filter changes
  if (!thisState.filterIsApplied) {
    if (action.type === FilterDocumentActionType.clearFilter) {
      // ClearFilter action still needs to reload document to remove filter
      reloadDocument(tocService);
    }
    return;
  }

  if (thisState.viewOnlyCategory === ViewOnlyCategory.requirements) {
    instanceRequirements.requirementTypes = new Set(
      thisState.selectedRequirementTypes,
    );
    yield call([instanceRequirements, instanceRequirements.initData]);
  } else if (thisState.viewOnlyCategory === ViewOnlyCategory.themes) {
    instanceThemes.themes = thisState.selectedThemes;
    yield call([instanceThemes, instanceThemes.initData]);
  }

  reloadDocument(tocService);
}

const reloadDocument = (tocService: TocService | undefined) => {
  if (tocService) {
    tocService.documentLoad();
  }
};

const actOnTypes: FilterDocumentActionType[] = Object.values(
  FilterDocumentActionType,
);

function* filterDocumentSaga() {
  yield takeEvery(actOnTypes, (action) => updateRequirementsState(action));
}

export { filterDocumentSaga };
