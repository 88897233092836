import { createSlice } from '@reduxjs/toolkit';

export type FileTypeState = {
    selectedFileType?: string;
}

export const initialState: FileTypeState = {
    selectedFileType: undefined,
};

const fileTypeState = createSlice({
    name: 'FileTypeState',
    initialState,
    reducers: {
        setSelectedFileType(state, action) {
            state.selectedFileType = action.payload;
        }
    }
});

export const { setSelectedFileType } = fileTypeState.actions;

export default fileTypeState.reducer;
