import { Button } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath, useLocation, matchRoutes } from 'react-router-dom';
import { Language } from '../../../redux/types';
import { getCurrentLanguage} from '../../../util/util';
import { isUsingAlternateLanguageSwitcher, getEnquiryUILanguages } from '../../../../../component/util/checkConfig';
import styles from './LanguageSelector.module.less';
import { languages } from '../../../constants/languages';

export type LanguageSelectorProps = {
  className?: string;
  hidden?: boolean;
};


const getUiLanguages = () => {
  const uiLanguages = getEnquiryUILanguages();
  if (uiLanguages?.length) {
    const enquiryLanguageList = Object.values(languages);
    return enquiryLanguageList.filter(({ code }) =>
      uiLanguages.includes(code),
    );
  }
  return Object.values(languages);
};

const routes = [{ path: "/product/:id/:languageCode?" }]

const useCurrentPath = () => {
  const location = useLocation()
  const route = matchRoutes(routes, location)

  return route ? route[0] : undefined;
}


const LanguageSelector = ({className = ''}: LanguageSelectorProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const routeMatch = useCurrentPath();
  const [currentLanguage, setCurrentLanguage] = React.useState(
    getCurrentLanguage()
  );

  const updateLanguageInUrl = (languageCode: Language) => {
    let path = generatePath(routeMatch!.route.path, {
      ...routeMatch!.params,
      languageCode,
    });

    navigate(path);
  }

  const handleLanguageChange = (languageCode: Language) => {
    localStorage.setItem('language_code', JSON.stringify(languageCode));
    i18n.changeLanguage(languageCode);
    setCurrentLanguage(languageCode);
    updateLanguageInUrl(languageCode);
  };

  const availableLanguages = getUiLanguages();

  if (availableLanguages.length < 2) {
    return null;
  }

  if (
    isUsingAlternateLanguageSwitcher() &&
    availableLanguages.length === 2
  ) {
    const languageToSet = availableLanguages.find(
      ({ code }) => code !== currentLanguage,
    );

    return (
      <div className={styles.languageSelectorAlternate}>
        <Button
          type="text"
          className={`${styles.alternateLanguage} ${className}`}
          onClick={() => handleLanguageChange(languageToSet?.code as Language)}
          size="large"
        >
          {languageToSet?.longName}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.languageSelector}>
      {availableLanguages.map((language) => (
        <Button
          type="text"
          className={
            `${
              language.code === currentLanguage
                ? styles.activeLanguage
                : styles.language
            } ${className}`
          }
          key={language.code}
          onClick={() => handleLanguageChange(language.code as Language)}
          size="large"
        >
          {language.shortName}
        </Button>
      ))}
    </div>
  );
};

export default LanguageSelector;
