import * as assert from 'assert';

import { ComponentArg } from './types/component-arg';

class Singleton<Fn> {
    private _fn?: Fn;

    set Fn( value: Fn ) {
        this._fn = value;
    }

    get Fn(): Fn {
        assert.ok( this._fn, 'instance method not initialized' );
        return this._fn!;
    }
}

export type JsonNode = ( arg: ComponentArg ) => JSX.Element | null;
export type ShowError = ( arg: string ) => void;

const JsonNodeInstance = new Singleton<JsonNode>(),
    ShowErrorInstance = new Singleton<ShowError>();

export { JsonNodeInstance, ShowErrorInstance };
