import React from 'react'
import { useGetAppConfigurationQuery } from './redux/services/appConfigurationApi'

const ApiConfigurationWrapper = ({children}: {children: React.JSX.Element}) => {
  const {isLoading, isError, error} = useGetAppConfigurationQuery();

  if (isLoading) {
    return null;
  }

  if (isError) {
    console.log("Error getting configuration, will keep using config from env variables")
    console.error(error);
  }

  // App configuration loaded from backend and should be cached in redux store
  return (
    <>
      {children}
    </>
  )
}

const AppConfigurationWrapper = ({children}: {children: React.JSX.Element}) => {
  const useApiConfiguration = process.env.REACT_APP_USE_API_ENDPOINT === 'true';

  if (useApiConfiguration) {
    return <ApiConfigurationWrapper>{children}</ApiConfigurationWrapper>
  }

  return <>{children}</>
}

export default AppConfigurationWrapper
