import React from 'react';
import {ReactComponent as SdLogo} from '../../themes/sdTheme/assets/images/logo_viewer.svg';
import {ReactComponent as SdEnquiryLogo} from '../../features/enquiry/themes/sdTheme/assets/images/logo.svg';
import {ReactComponent as SoLogo} from '../../themes/soTheme/assets/images/logo_viewer.svg';
import {ReactComponent as SnvLogo} from '../../themes/snvTheme/assets/images/logo_viewer.svg';
import {ReactComponent as SvvLogo} from '../../themes/vegvesenTheme/assets/images/logo_viewer.svg';
import {ReactComponent as NsaiLogo} from '../../themes/nsaiTheme/assets/images/logo_viewer.svg';
import {customerCodes} from '../../constants/customer-codes';
import { isEnquiryEnabled } from '../util/checkConfig';

interface LogoProps {
    clientCode: string | undefined;
}

function Logo({ clientCode }: LogoProps): JSX.Element {
    switch(clientCode) {
        case customerCodes.so:
            return <SoLogo />;
        case customerCodes.snv:
            return <SnvLogo />;
        case customerCodes.svv:
        case customerCodes.vegvesen:
            return <SvvLogo />;
        case customerCodes.nsai:
            return <NsaiLogo />;
        case customerCodes.sd:
        return isEnquiryEnabled() ? <SdEnquiryLogo /> : <SdLogo />;
        default:
            return <SdLogo />;
    }
}

export default Logo;
