import React from 'react';
import { isEqual } from 'lodash';
import * as assert from 'assert';

import { Position } from '../../types/search.type';

function highlightText(
    accumulated: ( string | JSX.Element )[],
    sortedMatched: Position[],
    bookmarkedPosition?: Position ): ( string | JSX.Element )[] {

    let prefix = accumulated[ 0 ];

    if ( sortedMatched.length < 1 || typeof prefix !== 'string' || prefix.length < 1 ) return accumulated;

    const trimmedPrefix = trimText( prefix );

    const position: Position = sortedMatched.pop()!,
        start: number = position[ 0 ],
        end: number = start + position[ 1 ],
        selection: string = trimmedPrefix.trimmedText.slice( start, end ),
        postfix: string = trimmedPrefix.trimmedText.slice( end ),
        isCurrent: boolean = isEqual( position, bookmarkedPosition );

    assert.ok( position[ 1 ] > 0, '0 match length' );

    accumulated.shift();
    prefix = trimmedPrefix.trimmedText.slice( 0, start );

    const selectionWithLeadingSpace: TrimResult = {
        trimmedText: selection,
        trimmedLeft: trimmedPrefix.trimmedLeft
    };

    const result = [ prefix, highlightedText( selectionWithLeadingSpace, isCurrent, accumulated.length ), postfix ];
    if ( prefix.length < 1 ) {
        result.shift();
    }
    if ( postfix.length < 1 ) {
        result.pop();
    }

    return highlightText( [ ...result, ...accumulated ], sortedMatched, bookmarkedPosition );
}

interface TrimResult {
    trimmedLeft: boolean
    trimmedText: string
}

function trimText( text: string ): TrimResult {
    const trimmed = text.trimLeft();
    return {
        trimmedLeft: trimmed.length < text.length,
        trimmedText: trimmed
    };
}

function highlightedText( trimmedText: TrimResult, isCurrent: boolean, key: number ): JSX.Element {
    const cssClass = `searchMark ${isCurrent ? 'current' : ''}`.trim(),
        leftSpace = trimmedText.trimmedLeft ? ' ' : '';
    return <mark className={cssClass} tabIndex={0} key={key}>
        {leftSpace}
        {trimmedText.trimmedText}
    </mark>;
}

export { highlightText };
