import { Store, Unsubscribe } from 'redux';
import { standardStateStore } from '../../standard-state/standard-state.store';
import { GraphService } from '../../../graph/graph.service';
import { Uuid, ToWorker } from '../../../types/workerMessage.type';
import { ShowErrorInstance } from '../../../singleton';
import { uuid } from '../../../component/util/uuid';
import { WA as A, WS as S } from '../../../types/workerStore.type';

class WorkerRequestFactory<T> {
    constructor( store: Store<S<T>, A<T>>, onResponseReceived: ( ( data: T ) => void ) ) {
        this.unsubscribe = store.subscribe( this.storeListener( store, onResponseReceived ).bind( this ) );
    }

    public readonly unsubscribe: Unsubscribe;
    private _myRequestUuid?: Uuid;

    private get myRequestUuid() {
        return this._myRequestUuid;
    }

    public makeRequest( data: ToWorker ): void {
        const graph: GraphService | undefined = standardStateStore.getState().graphService;

        if ( graph ) {
            this._myRequestUuid = uuid();
            graph.sendToWorker( data, this._myRequestUuid );
        } else {
            ShowErrorInstance.Fn( 'GraphService is not yet ready' );
        }
    }

    private storeListener(
        store: Store<S<T>, A<T>>,
        onResponseReceived: ( data: T ) => void ): () => void {
        return function( this: WorkerRequestFactory<T> ) {
            const response: S<T> = store.getState();

            if ( response.content && response.content!.requestId === this.myRequestUuid ) {
                this.unsubscribe();
                onResponseReceived( response.content!.content );
            }
        }.bind( this );
    }
}

function makeWorkerPromise<T>( data: ToWorker, store: Store<S<T>, A<T>> ): Promise<T> {
    return new Promise<T>( resolve => {
        const facade = new WorkerRequestFactory( store, resolve );
        facade.makeRequest.call( facade, data );
    } );
}

export { makeWorkerPromise };
