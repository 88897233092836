import React from "react";
import { useSelector } from "react-redux";
import { clearScrollToElement } from "./actions";
import { itemToScrollTo } from "./ScrollSelector";

type ScrollWrapperProps = {
    elementKey: string,
    children?: React.ReactNode,
  }
  
  const ScrollWrapper = ({
    elementKey,
    children,
  }: ScrollWrapperProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const scrollToItem = useSelector(itemToScrollTo);

    const wheelMovedHandler = (event: React.WheelEvent) => {
      clearScrollToElement();
    }

    if (elementKey === scrollToItem.idToScrollTo) {
        ref.current?.scrollIntoView();        
    }

    return <div ref={ref} onWheel={wheelMovedHandler}>{children}</div>
  }

  export default ScrollWrapper;