import React from "react";
import { Header } from "../../component/header";
import { useSelector } from "react-redux";
import { featureEnquiry, isEnquiryPublished } from "../../redux/features/selectors";
import HeaderEnquiry from '../../features/enquiry/components/organisms/Header/Header';
import PublishedDocumentHeader from '../../features/enquiry/components/organisms/PublishedDocumentHeader/PublishedDocumentHeader';

const SwitchableHeader = () => {
  const enquiry = useSelector(featureEnquiry);
  const published = useSelector(isEnquiryPublished);

  if (enquiry.enabled) {
    return published 
      ? <PublishedDocumentHeader /> 
      : <HeaderEnquiry />;
  }

  return <Header />;
};

export default SwitchableHeader;
