import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { defaultComponent } from '../generic/default-component';

function langSet( arg: ComponentArg ): JSX.Element {

    let langAttribute = arg.node!.a['xml:lang'];
    if (langAttribute === 'no') {
        langAttribute = 'nb';
    }
    if (document.documentElement.lang !== langAttribute) {
        return (
            <div lang={ langAttribute } key={ key( arg ) }>{ defaultChildren( arg ) }</div>
        );
    }   
    return (defaultComponent( arg ));
}

export { langSet };
