import { JsonNodeType, NodeId } from '../../types/json-node.type';
import { NavigationType, SectionSelection } from '../../types/lazy.type';
import { LazySerializableStateDirty } from './lazy-serializable.state';

class LazyState extends LazySerializableStateDirty {

    tocSelection?: SectionSelection;
    tocSelectionNext?: SectionSelection;

    firstRenderedIdx = -1;
    lastRenderedIdx = -1;

    visibleSections: Set<NodeId> = new Set<NodeId>();
    visibleToC: Set<NodeId> = new Set<NodeId>();

    private _navigationType: NavigationType;

    get navigationType(): NavigationType {
        if ( !this._navigationType ) {
            this._navigationType = NavigationType.userScroll;
        }
        return this.tocSelectInProgress ? NavigationType.tocSelect : this._navigationType;
    }

    set navigationType( value: NavigationType ) {
        this._navigationType = value;
    }

    get tocSelectInProgress(): boolean {
        return this.tocSelection !== undefined;
    }

    // @ts-ignore
    get allLazyLoadedIdsInDisplayOrder(): NodeId[] {
        return this._allLazyLoadedIdsInDisplayOrder;
    }

    set allLazyLoadedIdsInDisplayOrder( value: NodeId[] ) {
        if (!value || !value.length) {
            value = [];
        }
        this._allLazyLoadedIdsInDisplayOrder = value;
        value.forEach( ( nodeId: NodeId, idx: number ) => {
            this.allLazyLoadedIdsIndices.set( nodeId, idx );
        } );
        this.allCount = value.length;
        this.allLazyLoadedIdSet = new Set( value );
    }

    isLazyLoadedNode( nodeId: NodeId ): boolean {
        return this.allLazyLoadedIdSet.has( nodeId );
    }

    safeSectionIdx( idx: number ): number {
        let result = Math.min( this.allLazyLoadedIdsInDisplayOrder.length - 1, idx );
        result = Math.max( 0, result );
        return result;
    }

    get renderedIdxRange(): [ number, number ] {
        const from1 = this.firstRenderedIdx,
            from2 = this.safeSectionIdx( from1 ),
            to1 = this.lastRenderedIdx,
            to2 = this.safeSectionIdx( to1 );

        return [ from2, to2 ];
    }

    get renderedSections(): JsonNodeType[] {
        const range = this.renderedIdxRange;
        return this.allScrollableSectionData.slice( range[ 0 ], range[ 1 ] + 1 );
    }

    get enabledForCommenting(): JsonNodeType[] {
        // TODO: Move filtering to worker, as it will not work with search.
        const filteredData = this.allScrollableSectionData.filter(
            ( item ) => item.a && item.a[ 'specific-use' ] === 'partial-enquiry'
        );
        const range = this.renderedIdxRange;
        return filteredData.slice( range[ 0 ], range[ 1 ] + 1 );
    }
}

export { LazyState };
