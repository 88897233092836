import { addTable, defaultTableItem, lockTable, TableItem, unlockTable } from ".";
import { nodeWithTagSelectors } from "../../graph/nodeWithTagSelectors";
import { DbType } from "../../types/db.type";
import { TagEnum } from "../../types/tag-enum";
import { ToWorker, WorkerMessageType } from "../../types/workerMessage.type";
import store from "../store";
import { parentsStore } from "../worker/stores/parents.store";
import { makeWorkerPromise } from "../worker/stores/workerRequestFactory";

export const addTableData = (table: TableItem) => {

  const selector = nodeWithTagSelectors( [ TagEnum.TableWrap ] ),
  data4worker: ToWorker = [ WorkerMessageType.directParents, [ table.tableId, selector, DbType.full ] ];

  makeWorkerPromise( data4worker, parentsStore )
  .then(x => {
    if (x.length === 0) {
      return;
    }

    const newTableItem = defaultTableItem();
    newTableItem.isShowTableInDialogVisisble = table.isShowTableInDialogVisisble;
    newTableItem.tableId = table.tableId;
    newTableItem.tableWrapId = x[0].id;

    store.dispatch(addTable(newTableItem));
  });
};

export const lockTableData = () => {
  store.dispatch(lockTable());
}

export const unlockTableData = () => {
  store.dispatch(unlockTable());
}