import { Key } from 'ts-key-enum';

class KeyMap {
  doKeysMatch = (key1: string, key2: string): boolean => {
    return  key1 === key2;
  }

  isArrowDown = (key: string):boolean => this.doKeysMatch(key, Key.ArrowDown);
  isArrowUp = (key: string):boolean => this.doKeysMatch(key, Key.ArrowUp);
  isEnd = (key: string):boolean => this.doKeysMatch(key, Key.End);
  isHome = (key: string):boolean => this.doKeysMatch(key, Key.Home);
  isEnter = (key: string):boolean => this.doKeysMatch(key, Key.Enter);
  isSpace = (key: string):boolean => this.doKeysMatch(key, " ");
  isEscape = (key: string):boolean => this.doKeysMatch(key, Key.Escape);
}

const Keys = new KeyMap();

export default Keys;