import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { key } from '../util/key';

function privateChar( arg: ComponentArg ): JSX.Element {
    const charName = arg.node!.a['name'];
    const id = key(arg);
    return (
    <>
        <span id={id} className='sts-private-character' data-name={charName}>
            {codeToCharacter(charName)}
        </span>
    </>
    )
}

function codeToCharacter(characterCode: string) {
    let result = '';

    switch (characterCode) {
        case 'A1_start':
            result = '!';
            break;
        case 'A1_end':
            result = '"';
            break;
        case 'A2_start':
            result = '#';
            break;
        case 'A2_end':
            result = '$';
            break;
        case 'A3_start':
            result = '%';
            break;
        case 'A3_end':
            result = '&';
            break;
        case 'AC_start':
            result = '˜';
            break;
        case 'AC_end':
            result = '™';
            break;
        case 'AC1_start':
            result = 'ˆ';
            break;
        case 'AC1_end':
            result = '‰';
            break;
        case 'AC2_start':
            result = 'Š';
            break;
        case 'AC2_end':
            result = '‹';
            break;
        case 'AC3_start':
            result = 'Œ';
            break;
        case 'AC3_end':
            result = 'Ž';
            break;
        default:
            break;
    }

    return result;
}

export { privateChar };
