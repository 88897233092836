import * as React from 'react';
import { getSectionRequirementThemes } from '../../redux/standard-state/standard-state.selectors';

export interface ThemesProps {
    sectionRequirementId: string;
}

const Themes = (props: ThemesProps) => {

    const sectionRequirementThemes = getSectionRequirementThemes();
   
    if (sectionRequirementThemes && sectionRequirementThemes.length) {
        const sectionRequirementItem = sectionRequirementThemes.find(x => x.sectionRequirementId === props.sectionRequirementId);
        if (sectionRequirementItem && sectionRequirementItem.themes && sectionRequirementItem.themes.length) {
            
            return (
                <div className="indexTermWrap">
                    {sectionRequirementItem.themes.map(theme => {
                            return (
                                <span key={ theme } className="index-term">{ theme }</span>
                            )
                        })
                    }
                </div>
            )
        }
    }
    return null;
}


export { Themes }
