import { nodeWithTagSelectors } from '../../../../../graph/nodeWithTagSelectors';
import { TagEnum } from '../../../../../types/tag-enum';
import {
  ToWorker,
  WorkerMessageType,
} from '../../../../../types/workerMessage.type';
import { NodeId } from '../../../../../types/json-node.type';
import { JsonNodeTypeGraph } from '../../../../../types/graph.type';
import { DbType } from '../../../../../types/db.type';
import { parentsStore } from '../../../../../redux/worker/stores/parents.store';
import { makeWorkerPromise } from '../../../../../redux/worker/stores/workerRequestFactory';
import { childrenStore } from '../../../../../redux/worker/stores/children.store';
import { WrapperSelectedInput } from './DocumentFractionWrapperState';

interface SectionInformation {
  id: string;
  title: string;
  label: string;
}

const getParentSections = async (
  elementKey: NodeId | undefined
): Promise<WrapperSelectedInput | null> => {
  if (!elementKey) {
    return null;
  }

  const selector = nodeWithTagSelectors([
    TagEnum.Sec,
    TagEnum.TermSec,
    TagEnum.RefList,
    TagEnum.App,
  ]);
  const data4worker: ToWorker = [
    WorkerMessageType.parents,
    [elementKey, selector, DbType.full],
  ];
  const sections = await makeWorkerPromise(data4worker, parentsStore);

  const dispatchInformation: WrapperSelectedInput = {
    elementId: elementKey,
    location: [],
  };

  for (const section of sections) {
    const sectionData = await getSectionInformation(
      section.id,
      section.tag as TagEnum
    );
    dispatchInformation.location.push({
      id: section.id,
      label: sectionData.label,
      title: sectionData.title,
    });
  }

  return dispatchInformation;
};

const getNodeData = async (
  nodeId: string,
  nodeType: TagEnum,
  destination: SectionInformation
): Promise<SectionInformation> => {
  const tagsToSearchFor = [TagEnum.Label];
  if (
    nodeType === TagEnum.Sec ||
    nodeType === TagEnum.RefList ||
    nodeType === TagEnum.App
  ) {
    tagsToSearchFor.push(TagEnum.Title);
  }

  if (nodeType === TagEnum.TermSec) {
    tagsToSearchFor.push(TagEnum.TbxTerm);
  }

  const selector = nodeWithTagSelectors(tagsToSearchFor);
  let data4worker: ToWorker = [
    WorkerMessageType.directChildren,
    [nodeId, selector, DbType.full],
  ];

  if (nodeType === TagEnum.TermSec) {
    data4worker = [WorkerMessageType.children, [nodeId, selector, DbType.full]];
  }

  const values = await makeWorkerPromise(data4worker, childrenStore);

  let result: SectionInformation = {
    ...destination,
  };

  values.forEach((value) => {
    result = transform(value, result);
  });
  return result;
};

const transform = (
  source: JsonNodeTypeGraph,
  destination: SectionInformation
): SectionInformation => {
  let result: SectionInformation = {
    ...destination,
  };

  if (source == null) {
    return result;
  }

  if (source.c == null) {
    return result;
  }

  const nodeData = source.c[0];

  if (nodeData == null) {
    return result;
  }

  const text = nodeData.t || '';

  result.id = source.id;

  if (source.tag === TagEnum.Title) {
    result.title = text;
  }

  if (source.tag === TagEnum.TbxTerm) {
    result.title = text;
  }

  if (source.tag === TagEnum.Label) {
    result.label = text;
  }

  return result;
};

const getSectionInformation = async (
  nodeId: string,
  nodeType: TagEnum
): Promise<SectionInformation> => {
  let result: SectionInformation = {
    id: '',
    label: '',
    title: '',
  };

  result = await getNodeData(nodeId, nodeType, result);
  const id = result.id;
  result = await getNodeData(id, nodeType, result);
  result.id = id;
  return result;
};

export default getParentSections;
