import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function listItem( arg: ComponentArg ): JSX.Element {
    return (
        <li key={ key( arg ) }>{ defaultChildren( arg ) }</li>
    );
}

export { listItem };
