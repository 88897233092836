import { createSlice } from '@reduxjs/toolkit';

const disabled = false;

export interface FeaturesState {
  enquiry: FeatureEnquiry;
  selection: boolean;
  favourites: boolean;
}

export interface FeatureEnquiry {
  enabled: boolean;
  published: boolean;
  partial: boolean;
}

let initialState: FeaturesState = {
  enquiry: {
    enabled: disabled,
    published: disabled,
    partial: disabled,
  },
  selection: disabled,
  favourites: disabled,
};

const FeaturesToggle = createSlice({
  name: 'FeaturesToggle',
  initialState,
  reducers: {
    setEnquiry(state, action) {
      state.enquiry = action.payload as FeatureEnquiry;
      state.selection = true;
    },
    setEnquiryPublished(state, action) {
      state.enquiry.published = action.payload;
    },
    setEnquiryPartial(state, action) {
      state.enquiry.partial = action.payload;
    },
    setFavourites(state, action) {
      state.favourites = action.payload.enabled;
    },
    setSelection(state, action) {
      state.selection = action.payload;
    },
    setAllFeatures(state, action) {
      const newState = action.payload as FeaturesState;
      state.enquiry = newState.enquiry;
      state.favourites = newState.favourites;
      state.selection = newState.selection;
    },
  },
});

export const {
  setEnquiry,
  setEnquiryPublished,
  setEnquiryPartial,
  setFavourites,
  setSelection,
  setAllFeatures,
} = FeaturesToggle.actions;

export default FeaturesToggle.reducer;
