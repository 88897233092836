import React from 'react';
import { ComponentArg } from '../../../types/component-arg';
import { FavouriteRequirement, FavouriteRequirementProps } from "../favourite-requirement";
import { RequirementInfo, RequirementInfoProps } from "../requirement/requirement-info";
import { RequirementValidFrom, RequirementValidFromProps } from "../requirement/requirement-valid-from";
import { Themes } from "../themes";
import { key } from '../../util/key';
import { getProductId, getFavoriteRequirementIds } from '../../../redux/standard-state/standard-state.selectors';
import { defaultChildren } from '../../generic/default-children';

export interface RequirementProps {
  component: ComponentArg;
}

const Requirement = ({ component }: RequirementProps) => {

  const key1 = key(component);
  const a = component.node!.a;

  let isFavourite = false;
  const favouritedRequirementIds = getFavoriteRequirementIds();

  if (favouritedRequirementIds && favouritedRequirementIds.length) {
    isFavourite = favouritedRequirementIds.findIndex(x => x === key1) > -1;
  }

  const favouriteRequirementProps: FavouriteRequirementProps = {
    productId: getProductId(),
    requirementId: key1,
    isFavourite: isFavourite
  };

  const requirementInfoProps: RequirementInfoProps = {
    reqNr: a['req-number'],
    vocabTerm: a['vocab-term']
  };

  const requirementValidFromProps: RequirementValidFromProps = {
    validFromDate: a['valid-from'],
    parentId: key1,
  };


  return (
    <div id={key1} className='sts-boxed-text requirement' key={key1}>
      <div className='requirementCaption'>
        <RequirementInfo {...requirementInfoProps} />
        <Themes sectionRequirementId={key1} />
        <div className='requirementRight'>
          <RequirementValidFrom {...requirementValidFromProps} />
          <FavouriteRequirement {...favouriteRequirementProps} />
        </div>
      </div>
      {defaultChildren(component)}
    </div>
  );
};

export default Requirement;
