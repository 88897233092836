import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'stream-browserify';
import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import * as ReactDOMClient from 'react-dom/client';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import AppConfigurationWrapper from './AppConfigurationWrapper';
import TranslationOverrideWrapper from './TranslationOverrideWrapper';

const container = document.getElementById('root')!;
const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <AppConfigurationWrapper>
      <TranslationOverrideWrapper>
        <App />
      </TranslationOverrideWrapper>
    </AppConfigurationWrapper>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
