import { WorkerMessage, FromWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { message2item, WorkerStoreItem } from './item.type';
import { CyNode } from '../../../types/graph.type';
import { NodeId, JsonNodeType } from '../../../types/json-node.type';
import { path2idStore } from './path2Id.store';
import { flatRenderedTextSizeStore } from './flatRenderedTextSize.store';
import { allScrollableSectionDataStoreDispatcher } from './allScrollableSectionData.store';
import { getByIdStore } from './getById.store';
import { filteredRequirementsStore } from './filteredRequirements.store';
import { parentsStore } from './parents.store';
import { childrenStore } from './children.store';
import { GraphService } from '../../../graph/graph.service';
import { TocData } from '../../../types/toc-data';
import { levelStore } from './level.store';
import { standardStateStoreDispatcher } from '../../standard-state/standard-state.store';
import { StandardStateActionType } from '../../standard-state/standard-state.types';
import { ownMatchesStore } from './ownMatchesStore';
import { toc2MatchStore } from './toc2MatchStore';
import { SortedDisplayOrderEntry } from '../../../types/search.type';
import { sortedInDisplayOrderStore } from './sortedInDisplayOrder.store';
import { childMatchesStore } from './childMatches.store';
import { getClosestParentSectionByIdStore } from './getClosestParentSectionById.store';

function workerStoreFacade(this: GraphService,
  message: WorkerMessage<FromWorker>): void {

  const type = message.content[0] as WorkerMessageType;
  switch (type) {
    case WorkerMessageType.standardTocData:
      const tocData1: WorkerStoreItem<TocData[]> = message2item(message);
      this.standardTocData = tocData1.content;
      checkResumeStandardStateInit(this);
      break;
    case WorkerMessageType.nodeOrder:
      const nodeOrder1: WorkerStoreItem<Map<NodeId, number>> = message2item(message);
      this.nodeOrder = nodeOrder1.content;
      checkResumeStandardStateInit(this);
      break;
    case WorkerMessageType.allScrollableSectionData:
      const allScrollable: WorkerStoreItem<JsonNodeType[]> = message2item(message);
      allScrollableSectionDataStoreDispatcher.dispatch({ type: null, content: allScrollable });
      break;
    case WorkerMessageType.flatRenderedTextSize:
      const flatRendered1: WorkerStoreItem<Map<NodeId, number>> = message2item(message);
      flatRenderedTextSizeStore.dispatch({ type: null, content: flatRendered1 });
      break;
    case WorkerMessageType.pathToId:
      const path2id1: WorkerStoreItem<CyNode[]> = message2item(message);
      path2idStore.dispatch({ type: null, content: path2id1 });
      break;
    case WorkerMessageType.getById:
      const getById1: WorkerStoreItem<CyNode | null> = message2item(message);
      getByIdStore.dispatch({ type: null, content: getById1 });
      break;
    case WorkerMessageType.filteredRequirements:
      const filteredRequire1: WorkerStoreItem<NodeId[]> = message2item(message);
      filteredRequirementsStore.dispatch({ type: null, content: filteredRequire1 });
      break;
    case WorkerMessageType.parents:
    case WorkerMessageType.directParents:
      const parents1: WorkerStoreItem<CyNode[]> = message2item(message);
      parentsStore.dispatch({ type: null, content: parents1 });
      break;
    case WorkerMessageType.children:
    case WorkerMessageType.directChildren:
      const children1: WorkerStoreItem<CyNode[]> = message2item(message);
      childrenStore.dispatch({ type: null, content: children1 });
      break;
    case WorkerMessageType.level:
      const level1: WorkerStoreItem<number | null> = message2item(message);
      levelStore.dispatch({ type: null, content: level1 });
      break;
    case WorkerMessageType.calculateOwnMatches:
      const ownMatches1: WorkerStoreItem<Map<NodeId, number>> = message2item(message);
      ownMatchesStore.dispatch({ type: null, content: ownMatches1 });
      break;
    case WorkerMessageType.calculateChildMatches:
      const childMatches1: WorkerStoreItem<Map<NodeId, number>> = message2item(message);
      childMatchesStore.dispatch({ type: null, content: childMatches1 });
      break;
    case WorkerMessageType.calculateToc2Match:
      const tocMatches1: WorkerStoreItem<Map<NodeId, [NodeId, number]>> = message2item(message);
      toc2MatchStore.dispatch({ type: null, content: tocMatches1 });
      break;
    case WorkerMessageType.calculateSortedInDisplayOrder:
      const sorted1: WorkerStoreItem<SortedDisplayOrderEntry[]> = message2item(message);
      sortedInDisplayOrderStore.dispatch({ type: null, content: sorted1 });
      break;
    case WorkerMessageType.getClosestParentSectionById:
      const closestParent1: WorkerStoreItem<NodeId | null> = message2item(message);
      getClosestParentSectionByIdStore.dispatch({ type: null, content: closestParent1 });
      break;
    case WorkerMessageType.getClosestRequirementById:
      const closestRequirementParent1: WorkerStoreItem<NodeId | null> = message2item(message);
      getClosestParentSectionByIdStore.dispatch({ type: null, content: closestRequirementParent1 });
      break;
    case WorkerMessageType.getClosestTableById:
      const closestTableParent1: WorkerStoreItem<NodeId | null> = message2item(message);
      getClosestParentSectionByIdStore.dispatch({ type: null, content: closestTableParent1 });
      break;
    case WorkerMessageType.getClosestFigureById:
      const closestFigureParent1: WorkerStoreItem<NodeId | null> = message2item(message);
      getClosestParentSectionByIdStore.dispatch({ type: null, content: closestFigureParent1 });
      break;
    case WorkerMessageType.getClosestPartialEnquiryById:
      const closestPartialEnqueryParent1: WorkerStoreItem<NodeId | null> = message2item(message);
      getClosestParentSectionByIdStore.dispatch({ type: null, content: closestPartialEnqueryParent1 });
      break;
    default:
      throw Error(`unexpected WorkerMessageType: ${type}`);
  }
}

function checkResumeStandardStateInit(graph: GraphService) {
  if (graph.isReady) {
    standardStateStoreDispatcher.dispatch({ type: StandardStateActionType.graphInstanceReady });
  }
}

export { workerStoreFacade };
