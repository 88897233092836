import { applyMiddleware, createStore, Reducer, Store } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { filterDocumentSaga } from './filter-document.saga';

import { FilterDocumentState } from './filter-document.state';
import { FilterDocumentAction, FilterDocumentActionType } from './types';
import { filterDocumentReducer } from './filter-document.reducer';

const middleware = createSagaMiddleware(),
    filterDocumentStore: Store<FilterDocumentState, FilterDocumentAction> =
        createStore(
            filterDocumentReducer as Reducer<FilterDocumentState, FilterDocumentAction>,
            applyMiddleware( middleware )
        );

middleware.run( filterDocumentSaga );

function clearFilterIfNecessary(): void {
    const isFiltered = filterDocumentStore.getState().filterIsApplied;
    if ( isFiltered ) {
        filterDocumentStore.dispatch( { type: FilterDocumentActionType.clearFilter } );
    }
}

export { filterDocumentStore, clearFilterIfNecessary };
