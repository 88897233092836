import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/reducer";
import { SearchMarkState } from "./SearchMarkState";

const highlightState = (state: RootState) => state.HightlightState
const parentIdToSearch = (state: RootState, parentId: string) => parentId

const highlightedContainer = createSelector([highlightState, parentIdToSearch],  (state: SearchMarkState, idToSearch: string): boolean => {
  return state.parentsOfHighlightedText.indexOf(idToSearch) > -1;
});

export { highlightedContainer }