import React from 'react';
import { billingNoSurchargeImage, billingSurchargeImage } from './image-data';

interface Props {
  vocab: string;
}

const Billing = ({ vocab }: Props) => {
  let src = '';
  switch (vocab) {
    case 'notshowsurcharge':
      src = billingNoSurchargeImage;
      break;
    case 'showsurcharge':
      src = billingSurchargeImage;
      break;
    default:
      console.error(`EForm RealEstate does not support vocab: ${vocab}`);
      break;
  }

  return (
    <div>
      <img src={src} alt={`billing-${vocab}`} />
    </div>
  );
};

export default Billing;
