import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/reducer';
import {
  ComponentId,
  HiddenComponentsState,
  fromReduxState,
} from './hideComponents.State';

const hiddenItems = (state: RootState) => state.HiddenItems;
const keyToLookFor = (state: RootState, key: ComponentId) => key;

const keyHidden = createSelector(
  [hiddenItems, keyToLookFor],
  (state: HiddenComponentsState, key: ComponentId): boolean => {
    const hiddenItems = fromReduxState(state.hiddenItems);
    return hiddenItems.valueContainsId(key);
  }
);

export { keyHidden };
