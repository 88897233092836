import { NodeId } from './json-node.type';

export interface SectionSelection {
    sectionNodeId: NodeId;
    navigateIdAfterLoadSection?: NodeId;
}

export interface ScrollNotification {
    //  rendered sections
    topBufferMargin: number
    bottomBufferMargin: number
    scrollTop: number
    scrollHeight: number
}

export type Visible = boolean;

export interface ViewportNotification {
    nodeId: NodeId;
    visible: Visible;
}

export enum NavigationType {
    userScroll = 'userScroll',
    tocSelect = 'tocSelect',
    rendering = 'rendering'
}

export interface NavigationStrategy {
    onScroll: ( notification: ScrollNotification ) => void
}
