import { createSlice } from "@reduxjs/toolkit";

export interface TableItem {
  tableId: string,
  isShowTableInDialogVisisble: boolean,
  tableWrapId: string,
}

export const defaultTableItem = (): TableItem => { return {
    tableId: '',
    isShowTableInDialogVisisble: false,
    tableWrapId: '',
  }
}

export interface TablesStateProps {
  items: TableItem[],
  isLocked: boolean,
}

export const initialState: TablesStateProps = {
  items: [],
  isLocked: false,
}

const TablesState = createSlice({
  name: 'TablesState',
  initialState,
  reducers: {
    addTable(state, action) {
      if (state.isLocked) {
        return;
      }

      const newItem = action.payload as TableItem;
      const newState = state.items.filter(x => x.tableId !== newItem.tableId);
      newState.push(newItem);
      state.items = newState;
    },
    lockTable(state) {
      state.isLocked = true;
    },
    unlockTable(state) {
      state.isLocked = false;
    }
  }
});

export const {
  addTable,
  lockTable,
  unlockTable,
} = TablesState.actions

export default TablesState.reducer