import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function fnGroup( arg: ComponentArg ): JSX.Element {
    const idKey = key( arg );
    return <div className="fn-group" id={ idKey } key={ idKey }>{ defaultChildren( arg ) }</div>;
}

export { fnGroup };
