import { createSlice } from '@reduxjs/toolkit';
import { appConfigurationApi } from '../services/appConfigurationApi';

export type AppConfiguration = {
  useConfigurationApi: boolean;
  language: string;
  production: boolean;
  standardsViewerApiUrl: string;
  standardsViewerWebAppUrl: string;
  stsServerUrl: string;
  accessTokenExpiringNotificationTime: number;
  checkSessionInterval: number;
  productOwner: string;
  customerCode: string;
  isPublic: boolean;
  supportEmail: string;
  features: {
    enquiry: {
      enabled: boolean;
      spaUrl: string;
      storeUrl: string;
      customerPageUrl: string;
      apiUrl: string;
      apiSubscriptionKey: string;
      commentOnMetadata: boolean;
      validMetadataLanguages: string;
    };
    favourites: boolean;
    footnoteVisible: boolean;
    expandCollapseEnabled: boolean;
    validateCopyAvailability: boolean;
    productMetadataShowEmail: boolean;
    committee: {
      url: string;
      references: string;
    };
    selection: boolean;
    translations: {
      [key: string]: { [key: string]: string };
    };
    freeStoreStandards?: {
      enabled: boolean;
      storeUrl: string;
    }
  };
  pdftronLicenseKey: string;
  languageSwitcherStyle: string;
  uiLanguages: string;
  showCopyrightInfo: boolean;
};

const initialState: AppConfiguration = {
  useConfigurationApi: false,
  language: process.env.REACT_APP_LANGUAGE ?? '',
  production: process.env.REACT_APP_PRODUCTION === 'true',
  standardsViewerApiUrl: process.env.REACT_APP_STANDARDS_VIEWER_API_URL ?? '',
  standardsViewerWebAppUrl: process.env.REACT_APP_STANDARDS_VIEWER_WEB_APP_URL ?? '',
  stsServerUrl: process.env.REACT_APP_STS_SERVER_URL ?? '',
  accessTokenExpiringNotificationTime:
    Number(process.env.REACT_APP_ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME) ?? 300,
  checkSessionInterval:
    Number(process.env.REACT_APP_CHECK_SESSION_INTERVAL) ?? 60000,
  productOwner: process.env.REACT_APP_PRODUCT_OWNER ?? '',
  customerCode: process.env.REACT_APP_CUSTOMER_CODE ?? '',
  isPublic: process.env.REACT_APP_IS_PUBLIC === 'true',
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL ?? '',
  features: {
    enquiry: {
      enabled: process.env.REACT_APP_FEATURES_ENQUIRY === 'true',
      spaUrl: process.env.REACT_APP_FEATURES_ENQUIRY_SPA_URL ?? '',
      storeUrl: process.env.REACT_APP_FEATURES_ENQUIRY_STORE_URL ?? '',
      customerPageUrl:
        process.env.REACT_APP_FEATURES_ENQUIRY_CUSTOMER_PAGE_URL ?? '',
      apiUrl: process.env.REACT_APP_FEATURES_ENQUIRY_API_URL ?? '',
      apiSubscriptionKey:
        process.env.REACT_APP_FEATURES_ENQUIRY_API_SUBSCRIPTION_KEY ?? '',
      commentOnMetadata:
        process.env.REACT_APP_FEATURES_ENQUIRY_COMMENT_ON_METADATA === 'true',
      validMetadataLanguages:
        process.env.REACT_APP_FEATURES_ENQUIRY_VALID_METADATA_LANGUAGES ?? '',
    },
    favourites: process.env.REACT_APP_FEATURES_FAVOURITES === 'true',
    footnoteVisible: process.env.REACT_APP_FEATURES_FOOTNOTE_VISIBLE === 'true',
    expandCollapseEnabled:
      process.env.REACT_APP_FEATURES_EXPANDCOLLAPSE_ENABLED === 'true',
    validateCopyAvailability:
      process.env.REACT_APP_FEATURES_VALIDATE_COPY_AVAILABILITY === 'true',
    productMetadataShowEmail:
      process.env.REACT_APP_FEATURES_PRODUCT_METADATA_SHOW_EMAIL === 'true',
    committee: {
      url: process.env.REACT_APP_FEATURES_COMMITTEE_URL ?? '',
      references: process.env.REACT_APP_FEATURES_COMMITTEE_URL_REFERENCES ?? '',
    },
    selection: process.env.REACT_APP_FEATURES_SELECTION === 'true',
    translations: process.env.REACT_APP_FEATURES_TRANSLATIONS
      ? JSON.parse(process.env.REACT_APP_FEATURES_TRANSLATIONS)
      : {},
  },
  pdftronLicenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY ?? '',
  languageSwitcherStyle: process.env.REACT_APP_LANGUAGE_SWITCHER_STYLE ?? '',
  uiLanguages: process.env.REACT_APP_UI_LANGUAGES ?? '',
  showCopyrightInfo: process.env.REACT_APP_SHOW_COPYRIGHT_INFO === 'true',
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      appConfigurationApi.endpoints.getAppConfiguration.matchFulfilled,
      (state, { payload }) => {
        if (!payload.useConfigurationApi) {
          return;
        }

        // Use reverse proxy /api url
        return { ...state, ...payload, ...{ standardsViewerApiUrl: '/api' } };
      },
    );
  },
});

export const { reducer: userReducer } = configurationSlice;
