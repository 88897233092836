import React, { useEffect, useMemo, useState } from 'react';
import getRelatedFootnoteNode from '../../../features/footnote/getRelatedFootnoteNode';
import { nodeWithTagSelectors } from '../../../graph/nodeWithTagSelectors';
import { childrenStore } from '../../../redux/worker/stores/children.store';
import { parentsStore } from '../../../redux/worker/stores/parents.store';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { ComponentArg } from '../../../types/component-arg';
import { DbType } from '../../../types/db.type';
import { JsonNodeTypeGraph } from '../../../types/graph.type';
import { JsonNodeTypeBranch } from '../../../types/json-node.type';
import { TagEnum } from '../../../types/tag-enum';
import { ToWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { defaultChildren } from '../../generic/default-children';
import { nodeKey } from '../../util/key';
import ModalComponent from '../modalComponent';

const getParent = async (id: string): Promise<JsonNodeTypeGraph> => {
  const selector = nodeWithTagSelectors([TagEnum.Std]),
    data4worker: ToWorker = [
      WorkerMessageType.directParents,
      [id, selector, DbType.full],
    ];
  const parents = await makeWorkerPromise(data4worker, parentsStore);
  const parent = parents[0];
  return parent;
};

const getReferenceId = async (id: string): Promise<JsonNodeTypeGraph> => {
  const selector = nodeWithTagSelectors([TagEnum.StdId]),
    data4worker: ToWorker = [
      WorkerMessageType.children,
      [id, selector, DbType.full],
    ];
  const stdIds = await makeWorkerPromise(data4worker, childrenStore);
  const stdId = stdIds[0];
  return stdId;
};

const getFullObject = async (
  id: string
): Promise<JsonNodeTypeBranch | undefined> => {
  const fullObject = await getRelatedFootnoteNode(id);
  return fullObject;
};

function StdRef(props: ComponentArg): JSX.Element | null {
  const { node } = props;
  const id = useMemo(() => nodeKey(node), [node]);
  const [referencedComponentId, setReferencedComponentId] = useState<string | undefined>(undefined);
  const [referencedObject, setReferencedObject] = useState<ComponentArg | undefined>(undefined);
  useEffect(() => {
    let isSubscribed = true;

    const isInsideFn = async () => {
      const parent = await getParent(id);

      if (isSubscribed) {
        const stdId = await getReferenceId(parent?.id);

        if (stdId === undefined) {
          return null;
        }

        if (isSubscribed) {
          const stdIdFullObject = await getFullObject(stdId.id);

          if (isSubscribed) {
            if (stdIdFullObject?.c.length === 0 || stdIdFullObject?.c[0].t === undefined) {
              return;
            }

            setReferencedComponentId(stdIdFullObject?.c[0].t);

            const referencedObject = await getFullObject(
              stdIdFullObject?.c[0].t
            );
            if (isSubscribed) {
              if (referencedObject === undefined) {
                return;
              }
              const argToPass: ComponentArg = {
                t: props.t,
                node: referencedObject,
              };

              setReferencedObject(argToPass);
            }
          }
        }
      }
    };

    isInsideFn();

    return () => {
      isSubscribed = false;
    };
  }, [id, props.t]);

  if (referencedComponentId === undefined) {
    return <>{defaultChildren(props)}</>;
  }

  if (referencedObject === undefined) {
    return <>{defaultChildren(props)}</>;
  }

  return (
    <ModalComponent arg={props} nodeToRender={referencedComponentId}>
      <ul>
        <li>{defaultChildren(referencedObject)}</li>
      </ul>
    </ModalComponent>
  );
}

export default StdRef;
