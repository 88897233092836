import React from 'react';
import ValueRender, { canRender, ValueProps } from './value-render';

const ValueSpecification = ({ component, vocab }: ValueProps) => {
  const vocabValue = 'specification';
  if (canRender({component, vocab, vocabValue})) {
    return (
      <>
        {'{'}
        <ValueRender
          component={component}
          vocab={vocab}
          vocabValue={vocabValue}
        />
        {'}'}
      </>
    );
  }

  return null;
};

export default ValueSpecification;