import React from 'react';
import { MixedContent } from '../../types/json-node.type';
import { useTranslation } from 'react-i18next';
import { MixedContentComponent } from './mixed-content-component';
import styles from './default-children-with-footnote.module.css';

interface Props {
  mixed: MixedContent[];
  footnotes: MixedContent[];
  parentNodeId: string;
}

const DefaultChildrenWithFootnote = ({ mixed, footnotes, parentNodeId }: Props) => {
  const { t } = useTranslation();

  const footnoteWords: { [key: number]: number[] } = {};
  const mixedRender: (React.JSX.Element | null)[] = mixed.map(
    (child: MixedContent, idx: number) => (
      <MixedContentComponent
        parentNodeId={parentNodeId}
        siblingIdx={idx}
        t={t}
        mixedContent={child}
        key={idx}
      />
    ),
  );

  // Go through footnotes and map all text fragments with the corresponding footnotes
  for (const footnote of footnotes) {
    const fnIndex = mixed.findIndex((c) => c.x?.id === footnote.x?.id);

    // Find text node that is before footnote.
    let textIndex = fnIndex - 1;
    let foundText = false;
    while (!foundText && textIndex >= 0) {
      const possibleTextNode = mixed[textIndex];
      if (!possibleTextNode || !possibleTextNode.t) {
        // Not a text node, look further back. Likely two footnotes on one word
        textIndex--;
        continue;
      }

      foundText = true;
      if (!(textIndex in footnoteWords)) {
        footnoteWords[textIndex] = [];
      }

      // Map footnote to the given text fragment
      footnoteWords[textIndex].push(fnIndex);
    }
  }

  // Amend the rendered components such that footnotes are tied together with
  // a given word to avoid wrapping footnote number in a separate line when rendered
  for (const entry of Object.entries(footnoteWords)) {
    const textNodeIndex = entry[0];
    const footnotes = entry[1];
    // Remove last word (footnote word) from the text fragment
    const textNode = mixed[+textNodeIndex];
    const text = textNode.t?.split(' ').filter((x) => x.length > 0) ?? [];

    // There is only one word in the text node, wrap all of it in a footnote container
    if (text.length === 1) {
      mixedRender[+textNodeIndex] = (
        <span className={styles.wordWithFootnote} key={textNodeIndex}>
          {textNode.t}
          {footnotes.map((fn) => {
            const footnote = mixed[fn];
            return (
              <MixedContentComponent
                parentNodeId={parentNodeId}
                siblingIdx={fn}
                t={t}
                mixedContent={footnote}
                key={fn}
              />
            );
          })}
        </span>
      );

      // Don't render duplicate footnotes
      for (const index of footnotes) {
        mixedRender[index] = null;
      }

      continue;
    }

    const footnoteWord = text?.pop();

    // Replace text render with the new text without word
    mixedRender[+textNodeIndex] = (
      <MixedContentComponent
        parentNodeId={parentNodeId}
        siblingIdx={+textNodeIndex}
        t={t}
        mixedContent={{ e: textNode.e, t: text.join(' '), x: textNode.x }}
        key={+textNodeIndex}
      />
    );

    const renderWordWithFootnotes = (
      <span className={styles.wordWithFootnote} key={footnotes[0]}>
        {` ${footnoteWord}`}
        {footnotes.map((fn) => {
          const footnote = mixed[fn];
          return (
            <MixedContentComponent
              parentNodeId={parentNodeId}
              siblingIdx={fn}
              t={t}
              mixedContent={footnote}
              key={fn}
            />
          );
        })}
      </span>
    );

    // Get first footnote index and remove it from the footnote array
    const firstFootnoteIndex = footnotes.shift();
    if (!firstFootnoteIndex) {
      continue;
    }

    // Replace first footnote index with word + all footnotes.
    mixedRender[firstFootnoteIndex] = renderWordWithFootnotes;
    for (const index of footnotes) {
      // To avoid duplicate footnote eleemnts, replace other footnote rendered
      // nodes with null, which tells React to ignore this during rendering
      mixedRender[index] = null;
    }
  }

  return <React.Fragment key={parentNodeId}>{mixedRender}</React.Fragment>;
};

export default DefaultChildrenWithFootnote;
