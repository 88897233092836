import React from 'react';
import { ComponentArg } from '../../../types/component-arg';
import { defaultChildren } from '../../generic/default-children';

function DefaultChildrenWithTrailingSpace(props: ComponentArg): JSX.Element | null {
  return (
    <>{defaultChildren(props)}<span> </span></>
  );
}

export default DefaultChildrenWithTrailingSpace;
