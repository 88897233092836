import { AboveBelow, UpdateSectionService } from './update-section.service';
import { lastScrollNotification, scrollSubject } from '../../redux/identity/scrollSubject';
import { NavigationStrategy, NavigationType, ScrollNotification } from '../../types/lazy.type';
import { RenderingStrategy } from './navigation-strategy/rendering.strategy';
import { TocSelectStrategy } from './navigation-strategy/toc-select.strategy';
import { UserScrollStrategy } from './navigation-strategy/user-scroll.strategy';
import { TocService } from './toc.service';
import { sectionVisibleSubject } from '../../redux/identity/sectionVisibleSubject';
import { getActiveLazyState } from '../../redux/standard-state/lazyStateProxy';
import { NodeId } from '../../types/json-node.type';

export class ScrollService {
    constructor( private updateSectionService: UpdateSectionService ) {
        this.initStrategies();
        this.subscribe();
    }

    private initStrategies() {
        this.strategies.set( NavigationType.rendering, new RenderingStrategy() );
        this.strategies.set( NavigationType.tocSelect, new TocSelectStrategy() );
        this.strategies.set( NavigationType.userScroll, new UserScrollStrategy( this.updateSectionService ) );
    }

    private subscribe() {
        scrollSubject.subscribe( this.onScroll.bind( this ) );
        sectionVisibleSubject.subscribe( this.checkLastRenderedSectionIsVisible.bind( this ) );
    }

    private strategies: Map<NavigationType, NavigationStrategy> = new Map();

    private onScroll(): void {
        const notification: ScrollNotification | undefined = lastScrollNotification();
        if ( notification ) {
            this.strategy.onScroll( notification );
        }
    }

    private get strategy(): NavigationStrategy {
        const navigationType = getActiveLazyState().navigationType;
        return this.strategies.get( navigationType )!;
    }

    checkLastRenderedSectionIsVisible() {
        const state = getActiveLazyState();
        if ( state.navigationType !== NavigationType.rendering ) return;

        const idx: number = state.lastRenderedIdx,
            lastRenderedNodeId: NodeId | undefined = state.idx2NodeId( idx ),
            isVisible = lastRenderedNodeId && state.visibleSections.has( lastRenderedNodeId );

        if ( isVisible ) {
            this.updateSectionService.expandSections( AboveBelow.below );
            TocService.notifyUpdateSections();
        }
    }
}
