import { createStore, Reducer, Store } from 'redux';
import { IdentityAction, identityReducer, IdentityState } from './identityReducer';

export interface LocalLanguageNotification {
    localLanguage: string;
}

type S = IdentityState<LocalLanguageNotification>;
type A = IdentityAction<LocalLanguageNotification>;
const setLocalLanguageSubject: Store<S, A> = createStore( identityReducer as Reducer );

export { setLocalLanguageSubject };
