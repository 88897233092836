import * as assert from 'assert';

import { NodeId } from '../../../types/json-node.type';

export interface IdSibling {
    id: NodeId
    siblingIdx: number
}

function idSibling2string( idSibling: IdSibling ): string {
    return `${idSibling.id}|${idSibling.siblingIdx}`;
}

function idSiblingFromString( s: string ): IdSibling {
    const twoParts = s.split( '|' ),
        siblingIdx = Number( twoParts[ 1 ] );

    assert.strictEqual( twoParts.length, 2);
    assert.ok( !isNaN( siblingIdx ), `unexpected siblingIdx: ${twoParts[ 1 ]}` );

    return {
        id: twoParts[ 0 ],
        siblingIdx
    };
}

export { idSibling2string, idSiblingFromString };
