import * as React from 'react';
import { Helmet } from 'react-helmet';
import { getCustomerCode } from '../util/checkConfig';

type FaviconProps = {
    customer?: string,
}

const Favicon = ({ customer }: FaviconProps) =>  {

    const theme = customer ? customer : getCustomerCode();
    const themePath = `${process.env.PUBLIC_URL}/themes/${theme}Theme/favicon`;

    return (
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href={`${themePath}/apple-touch-icon.png?v=00aXgg8nAM`}/>
            <link rel="icon" type="image/png" sizes="32x32" href={`${themePath}/favicon-32x32.png?v=00aXgg8nAM`}/>
            <link rel="icon" type="image/png" sizes="16x16" href={`${themePath}/favicon-16x16.png?v=00aXgg8nAM`}/>
            <link rel="manifest" href={`${themePath}/manifest.json?v=00aXgg8nAM`}/>
            <link rel="mask-icon" href={`${themePath}/safari-pinned-tab.svg?v=00aXgg8nAM`} color="#000d3f" />
            <link rel="shortcut icon" href={`${themePath}/favicon.ico?v=00aXgg8nAM`}/>
            <meta name="apple-mobile-web-app-title" content="Standard Norge | standard.no"/>
            <meta name="application-name" content="Standard Norge | standard.no"/>
            <meta name="msapplication-TileColor" content="#2b5797"/>
            <meta name="msapplication-TileImage" content={`${themePath}/mstile-144x144.png?v=00aXgg8nAM`}/>
            <meta name="msapplication-config" content={`${themePath}/browserconfig.xml?v=00aXgg8nAM`}/>
            <meta name="theme-color" content="#000d3f"/>
        </Helmet>
    );
}

export default Favicon;
