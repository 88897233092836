import React from "react";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {

    const { t } = useTranslation();

    return (
        <div className="errorMessage">
            <h1>{t('Errors.PageNotFound')}</h1>
            <p dangerouslySetInnerHTML={{__html: t('Errors.PageNotExistText')}}/>
        </div>
    )
}

export { PageNotFound }