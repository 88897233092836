import { Action } from 'redux';

import { NodeId } from '../../types/json-node.type';
import { IdSiblingString, Position, TextSearchResult, SortedDisplayOrderEntry } from '../../types/search.type';
import { Uuid } from '../../types/workerMessage.type';
import { uuid } from '../../component/util/uuid';

export enum SearchActionType {
    searchTerm = 'searchTerm',
    storeResultsArray = 'storeResultsArray',
    storeResultsMap = 'storeResultsMap',
    storeOwnMatchesTrigger = 'storeOwnMatchesTrigger',
    storeOwnMatches = 'storeOwnMatches',
    storeChildMatchesTrigger = 'storeChildMatchesTrigger',
    storeChildMatches = 'storeChildMatches',
    storeToc2matchTrigger = 'storeToc2matchTrigger',
    storeToc2match = 'storeToc2match',
    storeDisplayOrderTrigger = 'storeDisplayOrderTrigger',
    storeDisplayOrder = 'storeDisplayOrder',
    setBookmark = 'setBookmark',
    bookmarkIncrement = 'bookmarkIncrement',
    bookmarkDecrement = 'bookmarkDecrement',
    clearSearch = 'clearSearch',     //  also abort
    searchComplete = 'searchComplete'
}


export interface SearchState {
    searchTerm?: string
    searchInProgress?: Uuid

    /**   mixed-content   */
    resultArray: TextSearchResult[]
    resultMap: Map<IdSiblingString, Position[]>

    /**   sidebar  */
    ownMatches: Map<NodeId, number>     //  count matches in this node only
    childMatches: Map<NodeId, number>    //  count matches in all below nodes
    toc2match: Map<NodeId, [ NodeId, number ]>         //  <toc node, [1st match, bookmark]>
    sortedDisplayOrder: SortedDisplayOrderEntry[]
    bookmark: number    //  idx
}

export interface SearchAction extends Action {
    type: SearchActionType
    content?: string |
        TextSearchResult[] |
        Map<IdSiblingString, Position[]> |
        Map<NodeId, number> |
        Map<NodeId, [ NodeId, number ]> |
        SortedDisplayOrderEntry[] |
        number |
        string
}

function newState(): SearchState {
    return {
        resultArray: [],                        //  to calculate statistics
        resultMap: new Map<IdSiblingString, Position[]>(),   //  for highlighting
        ownMatches: new Map<NodeId, number>(),      //  statistics
        childMatches: new Map<NodeId, number>(),    //  statistics
        toc2match: new Map<NodeId, [ NodeId, number ]>(),           //  navigation
        sortedDisplayOrder: [],
        bookmark: -1
    };
}

function searchReducer(
    previousState: SearchState = newState(),
    action: SearchAction ): SearchState {

    const newState1 = { ...previousState };

    switch ( action.type ) {
        case SearchActionType.searchTerm:
            newState1.searchTerm = action.content as string;
            if ( action.content ) {
                newState1.searchInProgress = uuid();
            }
            break;
        case SearchActionType.storeResultsArray:
            newState1.resultArray = action.content as TextSearchResult[];
            break;
        case SearchActionType.storeResultsMap:
            newState1.resultMap = action.content as Map<IdSiblingString, Position[]>;
            break;
        case SearchActionType.storeOwnMatches:
            newState1.ownMatches = action.content as Map<NodeId, number>;
            break;
        case SearchActionType.storeChildMatches:
            newState1.childMatches = action.content as Map<NodeId, number>;
            break;
        case SearchActionType.storeToc2match:
            newState1.toc2match = action.content as Map<NodeId, [ NodeId, number ]>;
            break;
        case SearchActionType.storeDisplayOrder:
            newState1.sortedDisplayOrder = action.content as SortedDisplayOrderEntry[];
            break;
        case SearchActionType.setBookmark:
            newState1.bookmark = action.content as number;
            break;
        case SearchActionType.bookmarkIncrement:
            newState1.bookmark += 1;
            break;
        case SearchActionType.bookmarkDecrement:
            newState1.bookmark -= 1;
            break;
        case SearchActionType.clearSearch:
            return newState();
        case SearchActionType.searchComplete:
            newState1.searchInProgress = undefined;
            break;
        case SearchActionType.storeOwnMatchesTrigger:
        case SearchActionType.storeChildMatchesTrigger:
        case SearchActionType.storeDisplayOrderTrigger:
        case SearchActionType.storeToc2matchTrigger:
            //  do nothing
            break;
    }

    ensureBookmarkIsWithinBounds( newState1 );
    return newState1;
}

function ensureBookmarkIsWithinBounds( state: SearchState ): void {
    const length = state.sortedDisplayOrder.length;

    if ( length === 0 ) {
        state.bookmark = -1;
        return;
    }

    if ( state.bookmark < -1 ) {
        state.bookmark = -1;
        return;
    }

    if ( state.bookmark > ( length - 1 ) ) {
        state.bookmark = length - 1;
    }
}

export { searchReducer };
