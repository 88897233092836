import React, { Component, RefObject } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { getContentLanguageCode } from "../../i18n/getContentLanguageCode";
import { getSupportedLanguages } from "../../i18n/getSupportedLanguages";
import i18n from "../../i18n/i18n";
import { LocalLanguageNotification, setLocalLanguageSubject } from "../../redux/identity/setLocalLanguageSubject";
import { standardStateStore } from "../../redux/standard-state/standard-state.store";
import { storageKeys } from "../../storage/storage-keys";
import { LanguageInfo } from "../../types/language-info";
import { isSdCommerce } from "../util/checkConfig";
    
export interface LanguageSelectionState {
    globalLanguageMenuExpanded: boolean;
    supportedLanguages: LanguageInfo[];
}
    
class LanguageSelection1 extends Component<WithTranslation, LanguageSelectionState> {

    private readonly languageList: RefObject<HTMLUListElement> | null;
    private globalLanguageDiv: HTMLDivElement | null;
    
    constructor(props: WithTranslation) {
        super(props);
        this.state = {           
            globalLanguageMenuExpanded: false,
            supportedLanguages: []
        };
        this.languageList = React.createRef();
    }
    componentDidMount() {
        document.addEventListener( 'mousedown', this.clickOutsideHandler );
        document.addEventListener( 'touchstart', this.clickOutsideHandler );
        this.setState( { supportedLanguages: getSupportedLanguages() });
    }

    componentWillUnmount() {
        document.removeEventListener( 'mousedown', this.clickOutsideHandler );
        document.removeEventListener( 'touchstart', this.clickOutsideHandler );
    }
    
    expandLanguageMenuHandler = () => {
        let languageMenuExpanded = !this.state.globalLanguageMenuExpanded;
        this.setState( { globalLanguageMenuExpanded: languageMenuExpanded } );

        if ( languageMenuExpanded ) {
            this.languageList!.current!.tabIndex = -1;
            this.languageList!.current!.focus();
        }
    };

    private shouldCloseLanguageMenu( e: Event ): boolean {
        if ( !this.state.globalLanguageMenuExpanded ) {
            return false;
        }
        const target = e.target as HTMLDivElement;
        // noinspection RedundantIfStatementJS
        if ( this.globalLanguageDiv && this.globalLanguageDiv.contains( target ) ) {
            return false;
        }

        return true;
    }

    clickOutsideHandler = ( e: Event ) => {
        const shouldCloseLanguageMenu1 = this.shouldCloseLanguageMenu( e ),
            newState: any = {};

        if ( shouldCloseLanguageMenu1 ) {
            newState.globalLanguageMenuExpanded = false;
        }

        this.setState( newState );
    };

    changeLanguageHandler( languageCode: string ) {
        i18n.changeLanguage( languageCode ).then();
        this.setState( { globalLanguageMenuExpanded: false } );

        const storageService = standardStateStore.getState().storageService!;
        storageService.writeToStorage( storageKeys.LANGUAGE_CODE, languageCode );

        if (isSdCommerce()) {
            const localLanguageNotification: LocalLanguageNotification = { localLanguage: languageCode };
            setLocalLanguageSubject.dispatch( { type: null, content: localLanguageNotification } );
        }
    }

    toggleLanguageHandler(languageCode: string) {

        const otherLanguage = this.state.supportedLanguages.find(x=> x.code === languageCode);
        if (otherLanguage) {

            i18n.changeLanguage( otherLanguage.code ).then();    
            const storageService = standardStateStore.getState().storageService!;
            storageService.writeToStorage( storageKeys.LANGUAGE_CODE, otherLanguage.code );    

            if (isSdCommerce()) {
                const localLanguageNotification: LocalLanguageNotification = { localLanguage: otherLanguage.code };
                setLocalLanguageSubject.dispatch( { type: null, content: localLanguageNotification } );
            }
        }
    }


    render() {
        const { t } = this.props;

        if (this.state.supportedLanguages.length < 2) {
            return null;
        }

        let contentHtml;
        if (this.state.supportedLanguages.length > 2) {
            const languagesHtml = this.state.supportedLanguages.map((supportedLanguage) => (
                <li
                    key={supportedLanguage.code}
                    className={i18n.language === supportedLanguage.code ? 'selected' : ''}
                >
                    <button
                        lang={supportedLanguage.code}
                        onClick={() => this.changeLanguageHandler( supportedLanguage.code )}
                    >
                        {supportedLanguage.name}
                    </button>
                </li>
            ));

            const currentLanguage = this.state.supportedLanguages.find((lang) => lang.code === i18n.language)?.name;
            contentHtml = (
                <>
                    <button
                        className="globalLanguageExpand"
                        aria-expanded={this.state.globalLanguageMenuExpanded}
                        aria-controls="globalLanguageMenu"
                        onClick={this.expandLanguageMenuHandler}
                        aria-label={t('CurrentLanguage', { language: currentLanguage })}
                    >
                        {getContentLanguageCode( i18n.language )}
                    </button>
                    <ul
                        ref={this.languageList}
                        id="globalLanguageMenu"
                        hidden={!this.state.globalLanguageMenuExpanded}
                        aria-label={t('SelectLanguage')}
                    >
                        {languagesHtml}
                    </ul>
                </>
            );
        } else if (this.state.supportedLanguages.length === 2) {

            // same as in Store - show not current language but the one that will be change to when clicked on button
            const supportedLanguage = this.state.supportedLanguages.find((lang) => lang.code !== i18n.language);
            if (supportedLanguage) {
                contentHtml =
                (
                    <button
                        className="globalLanguageExpand"
                        title={supportedLanguage.name}
                        lang={supportedLanguage.code}
                        onClick={() => this.toggleLanguageHandler( supportedLanguage.code )}
                        aria-label={t('SelectLanguage', { language: supportedLanguage.name })}
                    >
                        {getContentLanguageCode( supportedLanguage.code )}
                    </button>
                );      
            }
        }

        return (
            <div className="globalLanguage" ref={node => ( this.globalLanguageDiv = node )}>
                {contentHtml}
            </div>
        );
    }
}

const LanguageSelection = withTranslation()( LanguageSelection1 );

export { LanguageSelection }
