import React from 'react';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { ComponentArg } from '../../types/component-arg';

function Thead( arg: ComponentArg ): JSX.Element {
    return <thead key={ key( arg ) }>{ defaultChildren( arg ) }</thead>;
}

export { Thead };
