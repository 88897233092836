import { ShowErrorInstance } from '../../singleton';
import { LoadingSpinnerNotification, loadingSpinnerSubject } from '../../redux/identity/loadingSpinnerSubject';

export function processError(exception: any) {
    ShowErrorInstance.Fn(JSON.stringify(exception));
    const loadingSpinner: LoadingSpinnerNotification = { visible: false };
    loadingSpinnerSubject.dispatch({ type: null, content: loadingSpinner });
    if (exception.response && exception.response.data && exception.response.data.errors) {
        ShowErrorInstance.Fn(JSON.stringify(exception.response.data.errors));
    }

    let urlToNavigate: string = '';
    if (exception.response && exception.response.status === 404) {
        urlToNavigate = '/notfound' + (exception.response.data && exception.response.data.errorId ? '/' + exception.response.data.errorId : '');
    } else if (exception.response && exception.response.status === 422) {
        urlToNavigate = `/closed${exception.response.data.published ? '/published' : ''}`;
    } else if (exception.response && exception.response.data && exception.response.data.errorId) {
        urlToNavigate = '/servererror/' + exception.response.data.errorId;
    }

    if (urlToNavigate) {
        //TODO: need to use navigate from useNavigation() hook instead when it will work
        //Tried all of these variants - https://stackoverflow.com/questions/70881320/redirect-to-route-from-saga-using-react-router-v6
        //no one worked, only changed URL and did not route further, seems something still missing in React 18 + Router V6 and Redux Sagas.
        window.location.href = urlToNavigate;
    }
}