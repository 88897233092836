import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { NodeId } from '../../../types/json-node.type';

type Content = WorkerStoreItem<Map<NodeId, [ NodeId, number ]>>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const toc2MatchStore: Store<S, A> = createStore( identityReducer as Reducer );

export { toc2MatchStore };
