import { TagEnum } from '../types/tag-enum';

function nodeWithTagSelector( tag: TagEnum ): string {
    return `node[tag="${ tag }"]`;
}

function nodeWithTagSelectors( tags: TagEnum[] ): string {
    return tags.map( nodeWithTagSelector ).join( ',' );
}

export { nodeWithTagSelector, nodeWithTagSelectors };
