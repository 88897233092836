import React from 'react';
import { ComponentArg } from '../../../../types/component-arg';
import { defaultChildren } from '../../../generic/default-children';

export interface ValueProps {
  component: ComponentArg;
  vocab: string;
}; 

export interface ValueRenderProps {
  component: ComponentArg;
  vocab: string;
  vocabValue: string;
}; 

const ValueRender = ({component, vocab, vocabValue}: ValueRenderProps) => {
  if (canRender({component, vocab, vocabValue})) {
    return <>{defaultChildren(component)}</>;
  }

  return null;
}

export const canRender = ({component, vocab, vocabValue}: ValueRenderProps): boolean => {
  const vocabOk = vocab === vocabValue;
  const hasComponents = (component?.node?.c?.length ?? 0) > 0;
  return vocabOk && hasComponents;
}

export default ValueRender;