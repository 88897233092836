import * as React from 'react';
import IconButton from '../../atoms/IconButton/IconButton';
import LanguageSelector from '../../atoms/LanguageSelector/LanguageSelector';
import { standardStateStore } from "../../../../../redux/standard-state/standard-state.store";
import styles from './HeaderControls.module.less';
import { IconType } from '../../atoms/Icon/Icon';
import DropdownList from '../DropdownList/DropdownList';
import { DropdownOption } from '../../atoms/DropdownItem/DropdownItem';
import { TextAlign } from '../../atoms/IconTextButton/IconTextButton';
import { useTranslation } from 'react-i18next';
import urlJoin from 'url-join';
import { urlToEnquiry } from '../../../../../component/util/checkConfig';
import { getActiveCustomer } from '../../../util/util';
import { WithRouterProps } from '../../../../../withRouter';

export type HeaderControlsProps = {
  className?: string,
  router: WithRouterProps,
  toggleMenu: () => void,
}

const currentTheme = `${getActiveCustomer()}Theme`;

const HeaderControls = ({ 
  className = '', 
  router,
  toggleMenu
}: HeaderControlsProps) => {
  const { t } = useTranslation();

  const state = standardStateStore.getState();
  const userName = state.auth?.user.profile.given_name;
  const isLoggedIn = state.auth?.isLoggedIn();

  const dropdownOptions: DropdownOption[] = [
    {
      url: urlJoin(urlToEnquiry(), 'profile'),
      label: t('Enquiry.Menu.UserProfile'),
      value: 1,
    },
    {
      onClick: state.auth?.logOut,
      label: t('Enquiry.Menu.LogOut'),
      value: 2,
    }
  ];

  const userMenu = (
    <DropdownList
      className={styles.userMenu}
      optionsList={dropdownOptions} 
      iconRight={true} 
      iconType={IconType.User}
      iconHeight="var(--userMenuIconSize)"
      iconWidth="var(--userMenuIconSize)"
      buttonText={userName}
      textAlign={TextAlign.Right}
    />
  );

  const userButton = (
    <IconButton
      iconType={IconType.User}
      iconHeight="var(--userButtonIconSize)"
      iconWidth="var(--userButtonIconSize)"
      className={styles.user}
      onClick={() => state.auth?.startAuthentication(router)}
      ariaLabel="Log in"
    />
  );

  return (
    <section className={`${styles.headerControls} ${currentTheme} ${className}`}>
      {isLoggedIn ? userMenu : userButton}
      <LanguageSelector className={styles.languageSelector} />
      <IconButton
        className={styles.hamburger}
        iconType={IconType.Menu}
        iconHeight="var(--hamburgerIconSize)"
        iconWidth="var(--hamburgerIconSize)"
        onClick={toggleMenu}
        ariaLabel="Main navigation"
      />
    </section>
  );
};

export default HeaderControls;
