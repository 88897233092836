import * as lunr from 'lunr';
import { flatten, join } from 'lodash';

import { getByAttribute, getById, getByTag, JsonNodeType, NodeId } from '../../../types/json-node.type';
import { prepareIndex, searchIndex, standard2entries } from './indexStandard';
import { IdSibling, idSibling2string } from './idSibling';
import {
  IdSiblingString,
  IndexEntry,
  Position,
  TextSearchResult,
  SortedDisplayOrderEntry
} from '../../../types/search.type';
import { GraphService } from '../../../graph/graph.service';
import { WorkerMessageType, ToWorker } from '../../../types/workerMessage.type';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { ownMatchesStore } from '../../../redux/worker/stores/ownMatchesStore';
import { childMatchesStore } from '../../../redux/worker/stores/childMatches.store';
import { toc2MatchStore } from '../../../redux/worker/stores/toc2MatchStore';
import { sortedInDisplayOrderStore } from '../../../redux/worker/stores/sortedInDisplayOrder.store';
import { TagEnum } from '../../../types/tag-enum';
import { CurrentFilteredCategory } from '../../../redux/filter-document/filter-document.selector';
import { ViewOnlyCategory } from '../../../redux/filter-document/filter-document.state';
import { getSectionRequirementThemes } from '../../../redux/standard-state/standard-state.selectors';
import { addThemes, addVocabTerms, SearchIdAndPropertiesInput } from '../../../features/searchIdAndProperties/searchIdAndProperties.State';
import store from '../../../redux/store';
import { partialEnquiry } from '../../../constants/partial-enquiry';

class SearchService {
  constructor(
    private graph: GraphService,
    fullModel: JsonNodeType) {
    this.initIndex(fullModel);
  }

  private _lunrIndexes = new Map();

  search(term: string): TextSearchResult[] {
    const cleanTerm1 = cleanTerm(term),
      andTerm1 = andTerm(cleanTerm1);
    const result = searchIndex(andTerm1, this.getSearchIndex());
    return result;
  }

  getSearchIndex(): lunr.Index {
    const currentFilter = CurrentFilteredCategory();
    let result = this._lunrIndexes.get('default') as lunr.Index;

    if (currentFilter !== undefined) {
      result = this._lunrIndexes.get(currentFilter) as lunr.Index;
    }

    return result;
  }

  static calculateOwnMatches(results: TextSearchResult[]): Promise<Map<NodeId, number>> {
    const data4worker: ToWorker = [WorkerMessageType.calculateOwnMatches, results] as ToWorker;
    return makeWorkerPromise(data4worker, ownMatchesStore);
  }

  static calculateChildMatches(ownMatches: Map<NodeId, number>): Promise<Map<NodeId, number>> {
    const data4worker: ToWorker = [WorkerMessageType.calculateChildMatches, ownMatches] as ToWorker;
    return makeWorkerPromise(data4worker, childMatchesStore);
  }

  static calculateToc2Match(sortedMatches: SortedDisplayOrderEntry[]): Promise<Map<NodeId, [NodeId, number]>> {
    const data4worker: ToWorker = [WorkerMessageType.calculateToc2Match, sortedMatches] as ToWorker;
    return makeWorkerPromise(data4worker, toc2MatchStore);
  }

  static calculateSortedInDisplayOrder(resultMap: Map<IdSiblingString, Position[]>): Promise<SortedDisplayOrderEntry[]> {
    const data4worker: ToWorker = [WorkerMessageType.calculateSortedInDisplayOrder, resultMap] as ToWorker;
    return makeWorkerPromise(data4worker, sortedInDisplayOrderStore);
  }

  private initIndex(fullModel: JsonNodeType) {
    if (!fullModel) {
      return;
    }

    const entries: IndexEntry<IdSibling>[] = standard2entries(fullModel);
    this._lunrIndexes.set('default', prepareIndex(entries));
    const tableItems = this.prepareIndexByTag(fullModel, TagEnum.Table);
    this._lunrIndexes.set(ViewOnlyCategory.tables, prepareIndex(tableItems));
    const figuresItems = this.prepareIndexByTag(fullModel, TagEnum.Fig);
    this._lunrIndexes.set(ViewOnlyCategory.figures, prepareIndex(figuresItems));
    const requirementsItems = this.prepareIndexByTag(fullModel, TagEnum.NamedContent, 'requirement');
    this._lunrIndexes.set(ViewOnlyCategory.requirements, prepareIndex(requirementsItems));
    const commentingEnabledItems = this.prepareIndexByAttribute(fullModel, 'specific-use', partialEnquiry);
    this._lunrIndexes.set(ViewOnlyCategory.commentingEnabled, prepareIndex(commentingEnabledItems));
    const themesItems = this.prepareIndexForThemes(fullModel);
    this._lunrIndexes.set(ViewOnlyCategory.themes, prepareIndex(themesItems));

    let items: SearchIdAndPropertiesInput[] = themesItems.map(value => {
      const searchId: SearchIdAndPropertiesInput = {
        id: idSibling2string(value.id),
        themes: value.themes,
        vocabTerm: ''
      }

      return searchId;
    });

    store.dispatch(addThemes(items));

    items = requirementsItems.map(value => {
      const searchId: SearchIdAndPropertiesInput = {
        id: idSibling2string(value.id),
        themes: [],
        vocabTerm: value.vocabTerm
      }

      return searchId;
    });

    store.dispatch(addVocabTerms(items));
  }

  private prepareIndexForThemes(fullModel: JsonNodeType): IndexEntry<IdSibling>[] {
    const entries: IndexEntry<IdSibling>[] = [];
    const sectionRequirementThemes = getSectionRequirementThemes();
    const jsonNodes: JsonNodeType[] = [];
    sectionRequirementThemes.forEach(section => {
      if (section.sectionRequirementId !== '') {
        jsonNodes.push(...getById(fullModel, section.sectionRequirementId));
      }
    });

    const tablesEntries = jsonNodes.map((table: JsonNodeType) => standard2entries(table));
    tablesEntries.forEach((entry) => entries.push(...entry));

    return entries;
  }

  private prepareIndexByTag(fullModel: JsonNodeType, tag: TagEnum, vocab: string | undefined = undefined): IndexEntry<IdSibling>[] {
    const entries: IndexEntry<IdSibling>[] = [];
    const tables = getByTag(fullModel, tag, vocab);
    const tablesEntries = tables.map((table: JsonNodeType) => standard2entries(table));
    tablesEntries.forEach((entry) => entries.push(...entry));
    return entries;
  }

  private prepareIndexByAttribute(fullModel: JsonNodeType, name: string, value: string): IndexEntry<IdSibling>[] {
    const entries: IndexEntry<IdSibling>[] = [];
    const tables = getByAttribute(fullModel, name, value);
    const tablesEntries = tables.map((table: JsonNodeType) => standard2entries(table));
    tablesEntries.forEach((entry) => entries.push(...entry));
    return entries;
  }
}

function convertResult2Map(results: TextSearchResult[]): Map<IdSiblingString, Position[]> {
  const result = new Map<IdSiblingString, Position[]>();

  results.forEach(r => {
    if (r && r.id) {
      const matches: Position[] = result.get(r.id) || [];
      result.set(r.id, [...matches, ...flatten(r.resultMatches.map(m => m.position))]);
    }
  });

  const keys: IdSiblingString[] = Array.from(result.keys());
  keys.forEach(k => {
    if (k) {
      const match: Position[] = result.get(k) || [];
      match.sort(comparePositionForMixedContent);
      result.set(k, match);
    }
  });

  return result;
}

function comparePositionForMixedContent(left: Position, right: Position): -1 | 0 | 1 {
  if (left[0] < right[0]) return -1;
  if (left[0] > right[0]) return 1;
  return 0;
}

function andTerm(term: string): string {
  term = term.trim();
  return join(term.split(/\s+/).map(s => s[0] === '+' ? s : `+${s}`), ' ');
}

function cleanTerm(term: string): string {
  return term.replace(/[-+:.]/g, ' ');
}

export { SearchService, convertResult2Map, andTerm, cleanTerm };
