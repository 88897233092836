import React, { useEffect } from 'react';
import { ComponentArg } from '../../../types/component-arg';
import { key, ridKey } from '../../util/key';
import { Uri } from '../uri';
import { FootnoteModal } from '../footnote/footnote-modal';
import store from '../../../redux/store';
import { ComponentId, hideComponent } from '../../../redux/hide-component/hideComponents.State';
import { getClosestParentSectionById, getClosestRequirementById, getClosestTableById } from '../../lazy/getClosestParentSectionById';
import { addFootnote, FootnoteState } from '../../../features/footnote/footnote.State';
import { isFootnoteVisible } from '../../util/checkConfig';
import { RefTypes } from '../../../constants/ref-types';

function Xref(arg: ComponentArg): JSX.Element {
    const refType = arg.node!.a['ref-type'];
    const componentKey = key(arg);
    const footnoteId = ridKey(arg);

    useEffect(() => {
        let isSubscribed = true;

        if (refType === RefTypes.Fn || refType === RefTypes.TableFn) {
            if (isFootnoteVisible() === false) {
                const payload: ComponentId = { key: footnoteId };
                store.dispatch(hideComponent(payload));
            }

            const fillFootnoteStoreWithClosestElements = async () => {
                let closestTable: string | null = null;
                if (refType === RefTypes.TableFn) {
                    closestTable = await getClosestTableById(footnoteId);
                    if (isSubscribed) {
                        sendToStore(closestTable, footnoteId);
                    }
                }

                if (closestTable == null) {
                    let closestRequirement = await getClosestRequirementById(footnoteId)
                    if (isSubscribed) {
                        sendToStore(closestRequirement, footnoteId);
                        if (closestRequirement == null) {
                            let closestParentSection = await getClosestParentSectionById(footnoteId)
                            if (isSubscribed) {
                                sendToStore(closestParentSection, footnoteId);
                            }
                        }
                    }
                }
            };

            fillFootnoteStoreWithClosestElements();
        }

    return () => { isSubscribed = false; }
}, [refType, footnoteId])

if (refType === RefTypes.Fn || refType === RefTypes.TableFn) {
    return (<FootnoteModal  {...arg} key={footnoteId} />);
} else if (refType === RefTypes.Bibr) {
    const argToPass: ComponentArg = {
        ...arg,
        useScrollTypeBody: true,
    };
    return (<FootnoteModal  {...argToPass} key={componentKey} />);
}
return (
    <Uri {...arg} key={componentKey} />
);
}

function sendToStore(containerId: string | null, footnoteId: string) {
    if (containerId == null) {
        return;
    }

    let footnote: FootnoteState = {
        containerId: containerId,
        footnoteId: footnoteId,
    };

    store.dispatch(addFootnote(footnote));
}

export { Xref };
