import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from './redux/store';

const TranslationOverrideWrapper = ({
  children,
}: {
  children: React.JSX.Element;
}) => {
  const { i18n } = useTranslation();
  const config = useAppSelector((state) => state.configuration);

  if (config.features.translations) {
    let defaultNamespace = 'tranlsation';

    const i18nNamespace = i18n.options.defaultNS;
    defaultNamespace = Array.isArray(i18nNamespace)
      ? i18nNamespace.length > 0
        ? i18nNamespace[0]
        : 'translation'
      : typeof i18nNamespace === 'string' || i18nNamespace instanceof String
        ? i18nNamespace
        : 'translation';

    for (const entry of Object.entries(config.features.translations)) {
      const language = entry[0];
      const translations = entry[1];
      for (const translationEntry of Object.entries(translations)) {
        const sanitizedTranslation = translationEntry[1].replaceAll('\\n', '\n');
        i18n.addResource(
          language,
          defaultNamespace,
          translationEntry[0],
          sanitizedTranslation,
        );
      }
    }
  }

  return <>{children}</>;
};

export default TranslationOverrideWrapper;
