import moment from "moment";
import i18n from "../../i18n/i18n";

export interface GetDateInfoResult {
    dateFormatted: string;
    dateInternal: string;
}

function getDateInfo(dateNumber: number, format: string = 'LL'): GetDateInfoResult {

    let dateMoment = moment(dateNumber * 1000);
    dateMoment.locale(i18n.language);
    const dateFormatted = dateMoment.format(format);
    const dateInternal = dateMoment.format('YYYY-MM-DD');
    
    return { dateFormatted, dateInternal };
}


function getDateInfoFromDate(date: Date, format: string = 'LL'): GetDateInfoResult {

    let dateMoment = moment(date);
    dateMoment.locale(i18n.language);
    const dateFormatted = dateMoment.format(format);
    const dateInternal = dateMoment.format('YYYY-MM-DD');
    
    return { dateFormatted, dateInternal };
}

export { getDateInfo, getDateInfoFromDate }
