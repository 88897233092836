import lunr, { Index } from 'lunr';
import stemmer from 'lunr-languages/lunr.stemmer.support';
import multi from 'lunr-languages/lunr.multi';
import no from 'lunr-languages/lunr.no';

import { isText, JsonNodeType, MixedContent } from '../../../types/json-node.type';
import { IdSibling, idSibling2string } from './idSibling';
import { EmptyProperties, IndexEntry, IndexEntryProperties, ResultMatch, TextSearchResult } from '../../../types/search.type';
import { cleanTerm } from './search.service';
import { compareCustomerCode } from '../../util/checkConfig';
import { customerCodes } from '../../../constants/customer-codes';
import { TagEnum } from '../../../types/tag-enum';
import { getDateInfo } from '../../util/getDates';
import { toNumber } from 'lodash';
import store from '../../../redux/store';
import { metatags, themes } from '../../../features/searchIdAndProperties/searchIdAndProperties.Selector';
import { getSectionRequirementTheme } from '../../../redux/standard-state/standard-state.selectors';
import { IsFilterApplied, IsFilterCategoryRequirements, IsFilterCategoryThemes, selectedRequirementTypes, selectedThemes } from '../../../redux/filter-document/filter-document.selector';

stemmer( lunr );
multi( lunr );
no( lunr );

function standard2entries( fullJson: JsonNodeType ): IndexEntry<IdSibling>[] {
    const result: IndexEntry<IdSibling>[] = [];

    let properties: IndexEntryProperties = EmptyProperties();
    properties.vocabTerm = GetVocabTerm(fullJson);
    properties.themes = getSectionRequirementTheme(fullJson.id);
    
    node2entries( fullJson, result, properties);
    return result;
}

function GetVocabTerm(node: JsonNodeType): string {
    const requirement = node.a['vocab'];

    if (requirement === 'requirement') {
        return `${node.a["vocab-term"]}`;
    }

    return '';
}

function node2entries( node: JsonNodeType, accumulated: IndexEntry<IdSibling>[], properties: IndexEntryProperties ): void {
    if (!node || !node.c) {
        return;
    }

    const internalProperties = {...properties};

    node.c.forEach( ( mixedContent: MixedContent, siblingIdx: number ) => {
        if ( isText( mixedContent ) ) {
            const entry: IndexEntry<IdSibling> = {
                id: {
                    id: node.id,
                    siblingIdx
                },
                textContent: cleanTerm(mixedContent.t!),
                vocabTerm: internalProperties.vocabTerm,
                themes: internalProperties.themes,
            };

            accumulated.push( entry );
        } else {
            const node = mixedContent.x!;
            if ( node.tag === TagEnum.NamedContent) {
                const validFrom = node.a['valid-from'];

                if ( validFrom ) {
                    const dateInfo = getDateInfo(toNumber(validFrom), 'L')
                    const entry: IndexEntry<IdSibling> = {
                        id: {
                            id: node.id,
                            siblingIdx: 4,
                        },
                        textContent: dateInfo.dateFormatted,
                        vocabTerm: internalProperties.vocabTerm,
                        themes: internalProperties.themes,
                    };

                    accumulated.push( entry );
                }
            }
            
            node2entries( node, accumulated, internalProperties );
        }
    } );
}

function mapIndexEntry<From, To>( fn: ( f: From ) => To, entry: IndexEntry<From> ): IndexEntry<To> {
    const result = {
        id: fn( entry.id ),
        textContent: `${entry.textContent}`,
        vocabTerm: `${entry.vocabTerm}`,
        themes: entry.themes,
    };

    return result;
}

function prepareIndex( documents: IndexEntry<IdSibling>[] ): lunr.Index {
    return lunr( function() {
        if (compareCustomerCode(customerCodes.svv)) {
            // @ts-ignore
            this.use( lunr.multiLanguage('en', 'no') );
        } else {
             // @ts-ignore
             this.use( lunr.multiLanguage( 'en') );
        }

        this.ref( 'id' );
        this.field( 'textContent' );

        this.metadataWhitelist = [ 'position' ];

        // @ts-ignore
        this.pipeline.remove( lunr.stopWordFilter );  //  include "shall" etc in the index
        // @ts-ignore
        this.pipeline.remove(lunr.no.stopWordFilter );  //  include "shall" etc in the index

        documents.forEach( function( this: any, doc ) {
            const searchItem = mapIndexEntry( idSibling2string, doc );
            this.add( searchItem );
        }, this );
    } );
}



function searchIndex( searchTerm: string, idx: lunr.Index ): TextSearchResult[] {
    let results: Index.Result[] = [];
    let searchQuery: string = `${searchTerm}`;

    if (searchTerm && searchTerm.indexOf(' ') === -1) {
        searchQuery = searchTerm.replaceAll('+', '');
        searchQuery = `${searchQuery}^100 *${searchQuery}*^10`;
    }  

    results = idx.search( searchQuery );
    const state = store.getState();

    if (IsFilterApplied()) {

        if (IsFilterCategoryRequirements()) {
            results = results
            .filter((searchresult) => selectedRequirementTypes().length > 0)
            .filter((searchResult) => metatags(state).valueContainsId(selectedRequirementTypes(), searchResult.ref))
        }

        if (IsFilterCategoryThemes()) {
            results = results
            .filter((searchresult) => selectedThemes().length > 0)
            .filter((searchResult) => themes(state).valueContainsId(selectedThemes(), searchResult.ref));
        }
    }

    return results
    .map( convertResult );
}

function convertResult( lunrResult: Index.Result ): TextSearchResult {
    const metadataObject: Object = lunrResult.matchData.metadata,
        resultMatches: ResultMatch[] = [];

    for ( const key in metadataObject ) {
        // @ts-ignore
        let val = metadataObject[ key ];

        if ( !( val && val.textContent ) ) continue;

        const m: ResultMatch = {
            matchedString: key,
            position: val.textContent.position
        };

        resultMatches.push( m );
    }

    return {
        id: lunrResult.ref,
        resultMatches
    };
}

export {
    standard2entries,
    prepareIndex,
    mapIndexEntry,
    searchIndex
};
