import { delay } from "lodash";
import store from "../../redux/store";
import ScrollToInput from "./ScrollInput";
import { itemToScrollTo } from "./ScrollSelector";
import { addScrollId, clearScroll, scrollToItem, scrollToLastItem } from "./ScrollState";

export const scrollToElement = (elementKey: string) => {
  const internalCall = () => {
    const scroll: ScrollToInput = {
      idToScrollTo: elementKey,
    }
    store.dispatch(scrollToItem(scroll));
  }
  
  delay(internalCall, 0);
};

export const scrollToLastElement = (scrollDelay: number | undefined = undefined) => {
  let postponeScroll = 0;

  if (scrollDelay) {
    postponeScroll = scrollDelay;
  }

  delay(() => {
    var state = store.getState();
    var lastItem = itemToScrollTo(state);

    if (lastItem.idToScrollTo === '') {
      return;
    }

    store.dispatch(scrollToLastItem());
  }, postponeScroll);
};
export const clearScrollToElement = () => {
  delay(() => store.dispatch(clearScroll()), 0);
};

export const addScrollableItems = (elementKey: string[]) => {
  const items = elementKey.map((value: string) =>{
    const scroll: ScrollToInput = {
      idToScrollTo: value,
    }
    return scroll;
  } )
  
  store.dispatch(addScrollId(items));
};