import React from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function DispFormula(arg: ComponentArg): JSX.Element {
    const id = key(arg);
    return (
        <div id={id} key={id} className="disp-formula">
            {defaultChildren(arg)}
        </div>
    );
}

export { DispFormula };