import React, { useEffect, useMemo, useState } from 'react';

import { nodeKey } from '../util/key';
import { defaultChildren } from '../generic/default-children';
import { ComponentArg } from '../../types/component-arg';
import { TagEnum } from '../../types/tag-enum';
import { nodeWithTagSelectors } from '../../graph/nodeWithTagSelectors';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';
import { DbType } from '../../types/db.type';
import { makeWorkerPromise } from '../../redux/worker/stores/workerRequestFactory';
import { parentsStore } from '../../redux/worker/stores/parents.store';
import styles from './p.module.css';

function P( props: ComponentArg ): JSX.Element {
    // Declare a new state variable, which we'll call "count"
    const [inline, setInline] = useState(false);
    const { node } = props;
    const id = useMemo(() => nodeKey(node), [node])
    const directParent = useMemo(() => {
        if (!node) {
            return '';
        }

        return node.a['direct-parent'];
    }, [node])

    useEffect(() => {
        let isSubscribed = true;

        const isInsideFn = async () => {

            const selector = nodeWithTagSelectors( [ TagEnum.Fn ] ),
            data4worker: ToWorker = [ WorkerMessageType.directParents, [ id, selector, DbType.full ] ];

            const result = await makeWorkerPromise( data4worker, parentsStore );

            if (isSubscribed) {
                setInline(directParent === TagEnum.Def || result.length > 0);
            }
        };

        isInsideFn();

        return () => { isSubscribed = false; }
    }, [directParent, id]);

    const directChild = node!.a['direct-child'];
    let classNames = `sts-p ${styles.paragraph}`;
    if (directChild && (directChild === 'table-wrap' || directChild === 'disp-formula')) {
        classNames += ' full-size';
    }

    if (inline) {
        return <span className={classNames} id={id} key={id}>{defaultChildren( props )}</span>;
    } else {
        return <div className={classNames} id={id} key={id}>{defaultChildren( props )}</div>;
    }
}

export { P };
