import { getAppConfiguration } from "../../../redux/store";

export const getActiveCustomer = ():string => {
  const customerCode = getAppConfiguration().customerCode;
  return customerCode ? customerCode : 'svv';
}

export const validateMinChars = (str: string | undefined, minLength: number): boolean => {
  return Boolean(str && str.length >= minLength);
}

export const getCurrentLanguage = () => {
  const languageFromLocalStorage = localStorage.getItem('language_code');
  const languageCode = languageFromLocalStorage && JSON.parse(languageFromLocalStorage);
  return languageCode || getAppConfiguration().language;
}

export const isNullOrWhiteSpace = (value?: string) => { 
  return !value || !value.trim();
}

export const isExternalLink = (url?: string): boolean => {
  const baseUrl = new URL(`${window.location.protocol}//${window.location.host}`);
  if (!url) {
    return false;
  }

  // We pass in the baseUrl to make sure to generate a valid URL. If the url is
  // a relative URL, base is required, and will be used as the base URL. If url
  // is an absolute URL, the given base will be ignored.
  return new URL(url, baseUrl).hostname !== baseUrl.hostname;
};
