import React, { Component, SyntheticEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { selectById } from '../lazy/selectById';
import { ErrorTypes } from '../../redux/show-error';
import { ShowErrorInstance } from '../../singleton';
import { getClosestParentSectionById } from '../lazy/getClosestParentSectionById';
import { isExternalLink } from '../../features/enquiry/util/util';
import ScreenReaderOnly from '../util/screenReaderOnly';

export interface AState {
    linkUrl?: string;
    extLinkUrl?: string;
}

class Uri1 extends Component<ComponentArg & WithTranslation, AState> {
    constructor( p: ComponentArg & WithTranslation ) {
        super( p );
        this.state = {};
    }

    componentDidMount() {

        const node = this.props.node;
        if ( !node || !node.a || !node.a[ 'rid' ]  ) {
            if (node && node.c && node.c.length === 1) {
                const urlValue = node.c[0].t;
                if (urlValue && isExternalLink(urlValue)) {
                    this.setState( { extLinkUrl: urlValue } );
                }
            }
        }
        else {
            this.setState( { linkUrl: node.a[ 'rid' ] } );
        }
    }

    onNavigate( e: SyntheticEvent<HTMLAnchorElement, MouseEvent> ) {
        e.preventDefault();

        const linkUrl = this.state.linkUrl;
        if ( !linkUrl ) {
            ShowErrorInstance.Fn( ErrorTypes.UrlNotFound );
            return;
        }

        getClosestParentSectionById( linkUrl )
            .then( closestParentSectionId => {
                if ( closestParentSectionId ) {
                    selectById( closestParentSectionId, linkUrl );
                } else {
                    selectById( linkUrl );
                }
            } );
    }

    render() {
        const { t } = this.props;

        if (this.state.extLinkUrl) {
            return (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.extLinkUrl}
                    className="link"
                    key={key( this.props )}>
                    { this.state.extLinkUrl }
                    <ScreenReaderOnly>{ t('External link') }</ScreenReaderOnly>
                </a>
            );
        }
        else {
            return ( <a className="link"
                        href={`#${this.state.linkUrl}`}
                        onClick={this.onNavigate.bind( this )}
                        key={key( this.props )}>
                    {defaultChildren( this.props )}
                </a>
            );
        }
    }
}

const Uri = withTranslation()( Uri1 );

export { Uri };
