enum EformContentType {
  counterparty = 'counterparty',
  representative = 'representative',
  realestate = 'realestate',
  billing = 'billing',
  timelimits = 'timelimits',
  signatures = 'signatures',
}

const eformContentTypes = (): string[] => Object.values(EformContentType);

export { EformContentType, eformContentTypes };
