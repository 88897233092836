import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const freeStoreStandardsApi = createApi({
  reducerPath: 'freeStoreStandardsApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAccessToken: builder.query<{accessToken: string}, void>({
      query: () => ({
        url: `/freestorestandards/accesstoken`,
      }),
    }),
  }),
});

export const { useGetAccessTokenQuery, useLazyGetAccessTokenQuery } = freeStoreStandardsApi;
