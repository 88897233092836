import React from 'react';
import { ComponentArg } from '../../../../types/component-arg';
import { defaultChildren } from '../../../generic/default-children';

export interface NameProps {
  component: ComponentArg;
  className: string
}; 

const Name = ({component, className}: NameProps) => {
    return <span className={className}>{defaultChildren(component)}: </span>;
}

export const IsName = (contentType: string) => {
    return 'name' === contentType;
}

export default Name;