import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ImageKey } from "../../types/image/image.types";
import { getAppConfiguration } from "../store";
import { isPublic } from "../../component/util/checkConfig";
import { standardStateStore } from "../standard-state/standard-state.store";

const prepareHeaders = async () => {
  const headers = new Headers();
  headers.set("Content-Type", "text/plain; charset=utf-8");
  headers.set("Allow-Control-Allow-Origin", "*");
  if (!isPublic()) {
    const auth =  standardStateStore.getState().auth;
    if (!auth) {
      throw new Error("auth is undefined but necessary for private api calls");
    }
    await auth.setBearerToken(headers);
  }
  return headers;
}

export const imageApi = createApi({
  reducerPath: 'imageApi',
  keepUnusedDataFor: 86400, // Keep image data cached in redux store
  baseQuery: fetchBaseQuery({
    responseHandler: 'text'
  }),
  endpoints: (builder) => ({
    getImage: builder.query<string, ImageKey>({
      queryFn: async (imageKey, _api, _extraOptions, baseQuery) => {
        let apiUrl = "";
        if (imageKey.xmlFileInfo) {
          apiUrl = `/image/${imageKey.xmlFileInfo.fileLocation}/${imageKey.xmlFileInfo.nsbFolder}/${imageKey.xmlFileInfo.xmlFolder}/${imageKey.imageId}`;
        } else {
          apiUrl = `/image/${imageKey.productId}/${imageKey.imageId}`;
        }

        if (imageKey.organization) {
          apiUrl = `${apiUrl}?organization=${imageKey.organization}`;
        }

        const headers = await prepareHeaders();

        const {data} = await baseQuery({
          url: `${getAppConfiguration().standardsViewerApiUrl}${apiUrl}`,
          headers
        });

        return { data: data as string };
      },
    }),
  }),
});
