import React, { Component } from 'react';
import Auth from '../authentication/auth';
import { isPublic } from '../util/checkConfig';
import { WithRouterProps } from '../../withRouter';

export interface HomeProps {
    auth: Auth,
    router: WithRouterProps
}

class Home extends Component<HomeProps> {
    componentDidMount() {
        if (!isPublic()) {
            const queryString = this.props.router.location.search;
            if (
                queryString &&
                queryString.indexOf( 'code' ) > -1 &&
                queryString.indexOf( 'state' ) > -1 &&
                queryString.indexOf( 'session_state' ) > -1
            ) {
                this.props.auth.completeAuthentication(this.props.router).then();
                return;
            }
            
            if (!this.props.auth.isLoggedIn()) {
                this.props.auth.startAuthentication(this.props.router, queryString).then();
            }
        }
    }

    render() {
        return <div></div>;
    }
}

export { Home };