import React from 'react';
import * as assert from 'assert';
import { TFunction } from 'i18next';

import { ComponentArg } from '../../types/component-arg';
import { isText, MixedContent, NodeId } from '../../types/json-node.type';
import { MixedContentComponent } from './mixed-content-component';
import { key } from '../util/key';
import DefaultChildrenWithFootnote from './default-children-with-footnote';
import { RefTypes } from '../../constants/ref-types';
import { TagEnum } from '../../types/tag-enum';

function defaultChildren(arg: ComponentArg): JSX.Element | null {
  assert.ok(arg.node, 'expected node');
  return defaultChildrenMixed(arg.node!.c, key(arg), arg.t);
}

function defaultChildrenMixed(
  mixed: MixedContent[],
  key: NodeId,
  t: TFunction,
): JSX.Element | null {
  const length = mixed.length;
  switch (length) {
    case 0:
      return null;
    case 1:
      if (!mixed[0]) return null;
      const singleMixedContent = (
        <MixedContentComponent
          mixedContent={mixed[0]}
          parentNodeId={key}
          siblingIdx={0}
          t={t}
        />
      );
      if (isText(mixed[0]))
        return (
          <span className="default-text" key={key}>
            {singleMixedContent}
          </span>
        );
      return singleMixedContent;
    default:
      const footnotes = mixed.filter((c) => {
        if (!c.x || c.x.tag !== TagEnum.Xref) {
          return false;
        }

        const type = c.x.a['ref-type'];
        return type && [RefTypes.Fn, RefTypes.TableFn].includes(type);
      });

      // Footnotes must be handled separately to ensure that they always wrap together with the word
      // that the footnote is next to
      if (footnotes.length > 0) {
        return (
          <DefaultChildrenWithFootnote
            footnotes={footnotes}
            mixed={mixed}
            parentNodeId={key}
          />
        );
      }
      return (
        <React.Fragment key={key}>
          {mixed.map((child: MixedContent, idx: number) => (
            <MixedContentComponent
              parentNodeId={key}
              siblingIdx={idx}
              t={t}
              mixedContent={child}
              key={idx}
            />
          ))}
        </React.Fragment>
      );
  }
}

export { defaultChildren, defaultChildrenMixed };
