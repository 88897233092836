import { Component } from 'react';
import { Unsubscribe } from 'redux';

import { closeLeftMenuSubject } from '../redux/identity/closeLeftMenuSubject';
import { toggleBodyClass } from './util/toggleBodyClass';

const noOutlineClass = 'noFocus';

class NoOutline<Prop, State> extends Component<Prop, State> {
    closeLeftMenuUnsubscribe?: Unsubscribe;

    componentDidMount(): void {
        document.addEventListener( 'mouseup', NoOutline.onMouseUp );
        document.addEventListener( 'mousedown', NoOutline.onMouseDown );
        document.addEventListener( 'keyup', NoOutline.onKeyUp );
        document.addEventListener( 'keydown', NoOutline.onKeyDown );
    }

    componentDidUpdate(): void {
        NoOutline.showOutline( false );
        this.closeLeftMenuUnsubscribe = closeLeftMenuSubject.subscribe( () => NoOutline.showOutline( false ) );
    }

    componentWillUnmount(): void {
        if (this.closeLeftMenuUnsubscribe) {
            this.closeLeftMenuUnsubscribe!();
        }
        document.removeEventListener( 'mouseup', NoOutline.onMouseUp );
        document.removeEventListener( 'mousedown', NoOutline.onMouseDown );
        document.removeEventListener( 'keyup', NoOutline.onKeyUp );
        document.removeEventListener( 'keydown', NoOutline.onKeyDown );
    }

    // @ts-ignore
    private static key( e: KeyboardEvent<HTMLDivElement> ): string {
        if ( !e.key ) return '';
        return e.key.toLowerCase();
    }

    // @ts-ignore
    static onKeyUp( e: KeyboardEvent<HTMLDivElement> ) {
        const key = NoOutline.key( e );
        if ( key === 'tab' ) {
            NoOutline.showOutline( true );
        }
    }

    // @ts-ignore
    static onKeyDown( e: KeyboardEvent<HTMLDivElement> ) {
        const key = NoOutline.key( e );
        if ( key === 'enter' ) {
            NoOutline.showOutline( false );
        }
    }

    static onMouseUp( e: MouseEvent ) {
        NoOutline.onMouseDown( e );
    }

    static onMouseDown( e: MouseEvent ) {
        if ( !e.target ) return;
        const isDiv = e.target instanceof HTMLDivElement,
            isInput = e.target instanceof HTMLInputElement,
            isSelect = e.target instanceof HTMLSelectElement,
            isTextArea = e.target instanceof HTMLTextAreaElement;

        if ( !( isDiv || isInput || isSelect || isTextArea ) ) {
            NoOutline.blurNavigatedElement( e.target as HTMLElement );
            NoOutline.showOutline( false );
        }
    }

    private static blurNavigatedElement( el: HTMLElement ) {
        // Don't blur element if it is inside one of the sidebars.
        if ( el && el.blur && !el.closest('#navigationColumn, #siderRight') ) {
            el.blur();
        }
    }

    static showOutline( show: boolean ): void {
        toggleBodyClass( noOutlineClass, !show );
    }
}

export { NoOutline };
