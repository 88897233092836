import React, { useEffect, useState } from 'react';
import { ComponentArg } from '../../types/component-arg';
import { JsonNodeTypeBranch } from '../../types/json-node.type';
import { defaultChildren } from '../../component/generic/default-children';
import { defaultComponent } from '../../component/generic/default-component';
import getRelatedFootnoteNode from './getRelatedFootnoteNode';

export interface IRelatedFootnote {
  relatedFootnoteNode?: JsonNodeTypeBranch | null;
}

type FootnoteProps = {
  footnoteInRegularFont?: boolean | null;
  hideFootnoteNumber?: boolean | null;
  rid: string;
  arg: ComponentArg;
};

const FootnoteItem = ({
  rid,
  arg,
  hideFootnoteNumber,
  footnoteInRegularFont,
}: FootnoteProps) => {
  const [relatedFootnoteNodeState, setRelatedFootnoteNodeState] =
    useState<IRelatedFootnote>({ relatedFootnoteNode: undefined });

  useEffect(() => {
    let isSubscribed = true;
    getRelatedFootnoteNode(rid).then((value) => {
      if (isSubscribed) {
        setRelatedFootnoteNodeState({ relatedFootnoteNode: value });
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [rid]);

  useEffect(() => {
    let isSubscribed = true;
    if (hideFootnoteNumber) {
      if (relatedFootnoteNodeState.relatedFootnoteNode) {
        if (
          relatedFootnoteNodeState.relatedFootnoteNode!.c &&
          relatedFootnoteNodeState.relatedFootnoteNode!.c.length
        ) {
          const labelNode = relatedFootnoteNodeState.relatedFootnoteNode!.c[0];
          if (labelNode!.x!.tag === 'label') {
            if (isSubscribed) {
              setRelatedFootnoteNodeState({
                relatedFootnoteNode: {
                  ...relatedFootnoteNodeState.relatedFootnoteNode,
                  c: relatedFootnoteNodeState.relatedFootnoteNode!.c.slice(1),
                },
              });
            }
          }
        }
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [hideFootnoteNumber, relatedFootnoteNodeState.relatedFootnoteNode]);

  if (relatedFootnoteNodeState.relatedFootnoteNode == null) {
    return null;
  }

  let footnoteModalProps: ComponentArg = {
    node: relatedFootnoteNodeState.relatedFootnoteNode,
    t: arg.t,
  };

  const renderChildren = footnoteInRegularFont
    ? defaultChildren(footnoteModalProps)
    : defaultComponent(footnoteModalProps);

  return <>{renderChildren}</>;
};

export default FootnoteItem;
