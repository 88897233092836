import React from 'react';

import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function Tbody( arg: ComponentArg ): JSX.Element {
    return <tbody key={ key( arg ) }>{ defaultChildren( arg ) }</tbody>;
}

export { Tbody };
