import React from 'react';
import { createBrowserRouter, Route, Routes, RouterProvider } from 'react-router-dom';
import { I18nextProvider, withTranslation, WithTranslation } from 'react-i18next';
import i18n from './i18n/i18n';
import { createBrowserHistory, History } from 'history';
import { Unsubscribe } from 'redux';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import Auth from './component/authentication/auth';
import Unauthorized from './component/authentication/unauthorized';
import { initStandardStateStoreSaga, standardStateStore } from './redux/standard-state/standard-state.store';
import { StandardStateActionType } from './redux/standard-state/standard-state.types';
import { loadingSpinnerSubject, LoadingSpinnerNotification } from './redux/identity/loadingSpinnerSubject';
import LoadingComponent from './LoadingComponent';
import { StorageService } from './storage/storage.service';
import { standardSaga } from './redux/standard-state/standard-state.saga';
import { NoOutline } from './component/no-outline';
import { JsonNodeInstance, ShowErrorInstance } from './singleton';
import { jsonNode } from './component/generic/json-node';
import { NotFound } from './component/errors/not-found';
import { ServerError } from './component/errors/server-error';
import { ApiCaller } from './component/api-caller';
import { showError } from './redux/show-error';
import { isFavouritesEnabled, isPublic, featureEnquiryState, isSelectionEnabled } from './component/util/checkConfig';
import Favicon from './component/util/favicon';
import store from './redux/store';
import { FeaturesState, setAllFeatures } from './redux/features';
import DocumentClosedPage from './features/enquiry/components/pages/DocumentClosedPage/DocumentClosedPage';
import { getCurrentLanguage } from './features/enquiry/util/util';
import withRouter from './withRouter';
import { Home } from './component/home';

declare global {
    interface Window {
        MathJax: any;
    }
}

export interface AppState {
    showLoadingSpinner: boolean;
}

export const globalHistory = createBrowserHistory();

const HomeWithRouter = withRouter(Home);
const ApiCallerWithRouter = withRouter(ApiCaller);

class App extends NoOutline<WithTranslation, AppState> {
    constructor(p: WithTranslation) {
        super(p);
        this.state = { showLoadingSpinner: false };
        App.initStorage();
        this.history = globalHistory;
        initStandardStateStoreSaga(standardSaga);
        this.auth = this.initAuth();
        App.initMathJax();
        App.setSingletonFns();
        if (isPublic()) {
            this.loadingSpinnerUnsubscribe = loadingSpinnerSubject.subscribe(this.loadingSpinnerNotification.bind(this));
        }

        const features: FeaturesState = {
          enquiry: featureEnquiryState(),
          selection: isSelectionEnabled(),
          favourites: isFavouritesEnabled()
        };

        if (features.enquiry.enabled) {
          document.body.classList.add('enquiryMode');
        }

        this.router = createBrowserRouter([{ path: "*", element: (
            <Routes>
                <Route path="/" element={<HomeWithRouter auth={this.auth} />} />
                <Route
                    path="/product/:id/:languageCode?/:parameter?"
                    element={<ApiCallerWithRouter auth={this.auth} history={this.history} />}
                />
                <Route path="/unauthorized" element={ < Unauthorized />} />
                <Route path="/notfound/:id" element={ <NotFound />} />
                <Route path="/servererror/:id" element={ <ServerError />} />
                <Route path="/closed" element={ <DocumentClosedPage published={false} /> } />
                <Route path="/closed/published" element={ <DocumentClosedPage published={true} />} />
                <Route element={ <NotFound />} />
            </Routes>) }]);
        store.dispatch(setAllFeatures(features));
    }

    private static setSingletonFns() {
        JsonNodeInstance.Fn = jsonNode;
        ShowErrorInstance.Fn = showError;
    }

    readonly router: any;
    readonly history: History;
    private readonly auth: Auth;
    private loadingSpinnerUnsubscribe?: Unsubscribe;

    componentDidMount(): void {
        super.componentDidMount();
        if (!isPublic()) {
            this.loadingSpinnerUnsubscribe = loadingSpinnerSubject.subscribe(this.loadingSpinnerNotification.bind(this));
        }
    }

    componentWillUnmount(): void {
        window.process = { ...window.process };
        super.componentWillUnmount();
        this.loadingSpinnerUnsubscribe!();
    }

    loadingSpinnerNotification() {
        const loadingSpinner: LoadingSpinnerNotification | undefined = loadingSpinnerSubject.getState().content;
        if (!loadingSpinner) return;

        this.setState({ showLoadingSpinner: loadingSpinner.visible });
    }

    private initAuth(): Auth {
        const auth = new Auth();
        standardStateStore.dispatch({
            type: StandardStateActionType.setAuth,
            content: auth
        });
        return auth;
    }

    private static initStorage() {
        const storageService = new StorageService();
        standardStateStore.dispatch({
            type: StandardStateActionType.setStorageService,
            content: storageService
        });
    }

    private static initMathJax() {
        window.MathJax = {
            showMathMenu: false,
            skipStartupTypeset: true,
            menuSettings: { zoom: isMobile ? 'NoZoom' : 'Click' },
            tex2jax: { inlineMath: [['$', '$']], displayMath: [['$$', '$$']] }
        };

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "/lib/mathjax/MathJax.js?config=TeX-MML-AM_CHTML";
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Helmet>
                    <html lang={getCurrentLanguage()} />
                </Helmet>
                <Favicon />
                <LoadingComponent active={this.state.showLoadingSpinner}>
                    <RouterProvider router={this.router} />
                </LoadingComponent>
            </I18nextProvider>
        );
    }
}

const AppWithTranslation = withTranslation()(App);
export { AppWithTranslation as App };
