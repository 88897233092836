const languageCodes = {
    en: 'en',
    fr: 'fr',
    de: 'de',
    nb: 'nb'
};

const languageNames = {
    english: 'English',
    french: 'Français',
    german: 'Deutsch',
    norwegian: 'Norsk'
};

const languageCodeToName = {
    en: languageNames.english,
    fr: languageNames.french,
    de: languageNames.german,
    nb: languageNames.norwegian,
    no: languageNames.norwegian
}

export { languageCodes, languageNames, languageCodeToName };
