import { throttle, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { highlightToCthrottle } from '../../constants/json-node';

export enum ThrottleActionType {
    raw = 'raw',
    throttled = 'throttled'
}

export interface ThrottleAction<Content> extends Action {
    type: ThrottleActionType,
    content?: Content
}

function* resendAction<Content>( action: ThrottleAction<Content> ) {
    yield put( { type: ThrottleActionType.throttled } );
}

function* throttleSaga<Content>() {
    yield throttle( highlightToCthrottle, ThrottleActionType.raw, resendAction );
}

export { throttleSaga };
