import React from 'react';
import { timelimitsImage } from './image-data';

const Timelimits = () => {
  return (
    <div>
      <img src={timelimitsImage} alt="timelimits" />
    </div>
  );
};

export default Timelimits;
