import { ShowErrorState } from './show-error.state';
import { ShowErrorAction, ShowErrorActionType } from './types';

function newState(): ShowErrorState {
    return {
        errorText: ''
    };
}

export function showErrorReducer(
    prevState: ShowErrorState = newState(),
    action: ShowErrorAction ): ShowErrorState {

    let result = { ...prevState };

    switch ( action.type ) {
        case ShowErrorActionType.clearError:
            result = newState();
            break;

        case ShowErrorActionType.addError:
            result.errorText = action.errorText;
            break;

        case ShowErrorActionType.globalError:
            result.errorText = action.errorText;
            break;
    }

    return result;
}
