import { createStore, Reducer, Store } from 'redux';

import { IdentityAction, identityReducer, IdentityState } from '../../identity/identityReducer';
import { WorkerStoreItem } from './item.type';
import { CyNode } from '../../../types/graph.type';

type Content = WorkerStoreItem<CyNode | null>;
type S = IdentityState<Content>;
type A = IdentityAction<Content>;
const getByIdStore: Store<S, A> = createStore( identityReducer as Reducer );

export { getByIdStore };
