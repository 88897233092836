import React, { useEffect } from "react";
import { GlobalError } from "./templates/global-error";
import { globalError, ErrorTypes } from "../../redux/show-error";
import '../../themes/sdTheme/styles/sdThemeStyles.css';
import SwitchableHeader from "../../component/switchable-header";
import { useParams } from 'react-router-dom';

const ServerError = () => {

    const id = useParams().id ?? '';

    useEffect(() => {
        globalError(ErrorTypes.GlobalError);
    }, []);

    return (
        <>
            <SwitchableHeader />
            <main className="viewerMain">
                <div className="wrapper">
                    <GlobalError errorId={id} />
                </div>
            </main>
        </>
    )
}

export { ServerError };