import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import store from '../../../../../redux/store';
import { TagEnum } from '../../../../../types/tag-enum';
import getParentSections from './parentSections';
import { getElementInformation } from './selectionDetails';

const nothingSelected = '';
export interface DocumentFractionWrapperState {
  selectedItem: string;
  location: ElementLocation[];
  selection?: ElementDetails;
}

let initialState: DocumentFractionWrapperState = {
  selectedItem: nothingSelected,
  location: [],
};

export interface ElementLocation {
  id: string;
  title: string;
  label: string;
}

export interface WrapperSelectedInput {
  elementId: string;
  location: ElementLocation[];
}

export interface ElementDetails {
  id: string;
  tag: TagEnum;
  label: string;
  title: string;
}

const wrapperSlice = createSlice({
  name: 'DocumentFractionWrapperMouseHover',
  initialState,
  reducers: {
    wrapperSelected(state, action: PayloadAction<WrapperSelectedInput>) {
      state.selectedItem = action.payload.elementId;
      state.location = action.payload.location;
    },
    setSelection(state, action: PayloadAction<ElementDetails>) {
      state.selection = action.payload;
    },
    clearWrapperSelection() {
      return { ...initialState };
    },
  },
});

const selectWrapper = (elementKey: string) => {
  getParentSections(elementKey).then((value) => {
    if (value == null) {
      return;
    }
    store.dispatch(wrapperSelected(value));
  });
  getElementInformation(elementKey).then((selection) => {
    if (selection == null) {
      return;
    }
    store.dispatch(setSelection(selection));
  });
};

export const { wrapperSelected, setSelection, clearWrapperSelection } = wrapperSlice.actions;

export { nothingSelected, selectWrapper };

export default wrapperSlice.reducer;
