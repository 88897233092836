import * as React from 'react';
import * as assert from 'assert';
import { ComponentArg } from '../../types/component-arg';
import { defaultComponent } from './default-component';
import { TagEnum } from '../../types/tag-enum';
import { Table } from '../specific/table';
import { tr } from '../specific/tr';
import { Title } from '../specific/title';
import { italic } from '../specific/italic';
import { underline } from '../specific/underline';
import { defaultChildren } from './default-children';
import { Uri } from '../specific/uri';
import { NamedContent } from '../specific/named-content';
import { ref } from '../specific/ref';
import Sec from '../specific/sec';
import { AppSection } from '../specific/app-section';
import { TermSec } from '../specific/term-sec';
import { RefList } from '../specific/ref-list';
import { key } from '../util/key';
import { Label } from '../specific/label';
import { bold } from '../specific/bold';
import { sup } from '../specific/sup';
import { caption } from '../specific/caption';
import { longDesc } from '../specific/long-desc';
import { array } from '../specific/array';
import { strike } from '../specific/strike';
import { list } from '../specific/list';
import { listItem } from '../specific/list-item';
import { P } from '../specific/p';
import { annexType } from '../specific/annex-type';
import { boxedText } from '../specific/boxed-text';
import { code } from '../specific/code';
import { dispQuote } from '../specific/disp-quote';
import { Fig } from '../specific/fig';
import { Graphic } from '../specific/graphic';
import { Math } from '../specific/math';
import { monospace } from '../specific/monospace';
import { nonNormativeExample } from '../specific/non-normative-example';
import { overline } from '../specific/overline';
import { roman } from '../specific/roman';
import { sansSerif } from '../specific/sans-serif';
import { smallcaps } from '../specific/smallcaps';
import { sub } from '../specific/sub';
import { TableWrap } from '../specific/table-wrap';
import { ExtLink } from '../specific/ext-link';
import { NonNormativeNote } from '../specific/non-normative-note';
import { Tig } from '../specific/tig';
import { StyledContent } from '../specific/styled-content';
import { Td } from '../specific/td';
import { Th } from '../specific/th';
import { Tbody } from '../specific/tbody';
import { isWithinScrollThreshold } from '../lazy/inView';
import { Xref } from '../specific/xref/xref';
import { fnGroup } from '../specific/fn-group';
import { langSet } from '../specific/lang-set';
import { indexTerm } from '../specific/index-term';
import { term } from '../specific/term';
import { ProtectedContentNote } from '../specific/protected-content-note';

import {
  TableCaptionLabel,
  TableCaptionTitle,
  TableCaptionUnits,
} from '../specific/table-caption';
import {
  GraphicCaptionLabel,
  GraphicCaptionTitle,
} from '../specific/graphic-caption';
import { termInfoLabel } from '../specific/term-info-label';
import { termInfoSource } from '../specific/term-info-source';
import { MathWrapper } from '../specific/math-wrapper';
import { def } from '../specific/def';
import DocumentFractionWrapper from '../../features/selection/components/molecules/DocumentFractionWrapper/DocumentFractionWrapper';
import { scrollableItems } from '../../features/scroll/ScrollSelector';
import store from '../../redux/store';
import ScrollWrapper from '../../features/scroll';
import { privateChar } from '../specific/private-char';
import { subPart } from '../specific/sub-part';
import { TableWrapFooter } from '../specific/table-wrap-footer';
import { keyHidden } from '../../redux/hide-component/hideComponent.Selector';
import StdRef from '../specific/std-ref';
import Hidden from '../specific/hidden';
import DefaultChildrenWithTrailingSpace from '../specific/defaultChildrenWithTrailingSpace';
import { DispFormula } from '../specific/disp-formula';
import { Fn } from '../specific/fn';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import Metadata from '../specific/metadata';
import Standard from '../specific/standard';

function jsonNode(arg: ComponentArg): JSX.Element | null {
  const key1 = key(arg);
  const hiddenComponent = keyHidden(store.getState(), { key: key1 });
  const isCollection = standardStateStore.getState().collectionDto !== undefined;

  if (hiddenComponent) {
    return null;
  }

  const scrollItems = scrollableItems(store.getState());

  const component = jsonNodeParser(arg, isCollection);

  if (component === null) {
    return component;
  }

  const tag = arg.node?.tag;
  const vocab = arg.node?.a.vocab;
  const isTop = arg.node?.isTop;
  const status = arg.node?.a['specific-use'];

  // these tags have duplicates that are not marked with 'isTop' flag and that are not rendered in the content view,
  // avoid wrapping them as they are empty, but selection border is visible when selecting the duplicate element
  if ((tag === TagEnum.Sec || tag === TagEnum.App) && !isTop) {
    return component;
  }

  const wrapScrollItem = (childControl: JSX.Element, key: string) => {
    if (scrollItems.indexOf(key) > -1) {
      return <ScrollWrapper elementKey={key}>{childControl}</ScrollWrapper>;
    }

    return childControl;
  };

  const wrapDocumentFraction = (childControl: JSX.Element, key: string) => {
    const metaTags: string[] = [
      TagEnum.NatMeta,
      TagEnum.IsoMeta,
      TagEnum.RegMeta,
      TagEnum.StdDocMeta,
      TagEnum.StdMeta,
    ];
    const wrapTagForSelection =
      tag === TagEnum.Sec ||
      tag === TagEnum.App ||
      tag === TagEnum.RefList ||
      tag === TagEnum.TableWrap ||
      tag === TagEnum.Fig ||
      tag === TagEnum.DispFormula ||
      (tag === TagEnum.NamedContent && vocab === 'requirement') ||
      tag === TagEnum.TermSec ||
      (tag !== undefined && metaTags.includes(tag));

    if (wrapTagForSelection) {
      return (
        <DocumentFractionWrapper elementKey={key} elementStatus={status}>
          {childControl}
        </DocumentFractionWrapper>
      );
    }
    return childControl;
  };

  return wrapScrollItem(wrapDocumentFraction(component, key1), key1);
}

function jsonNodeParser(
  arg: ComponentArg,
  isCollection?: boolean,
): JSX.Element | null {
  assert.ok(arg.node, 'expect arg.node');
  const key1 = key(arg);

  switch (arg.node!.tag) {
    case TagEnum.AnnexType:
      return annexType(arg);
    case TagEnum.App:
      return <AppSection {...arg} key={key1} />;
    case TagEnum.Array:
      return array(arg);
    case TagEnum.Bold:
      return bold(arg);
    case TagEnum.BoxedText:
      return boxedText(arg);
    case TagEnum.Break:
      return <br key={key1} />;
    case TagEnum.Caption:
      return caption(arg);
    case TagEnum.Code:
      return code(arg);
    case TagEnum.Def:
      return def(arg);
    case TagEnum.DispQuote:
      return dispQuote(arg);
    case TagEnum.FnGroup:
      return fnGroup(arg);
    case TagEnum.Fn:
      return <Fn {...arg} key={key1} />;
    case TagEnum.Fig:
      return <Fig {...arg} key={key1} />;
    case TagEnum.Graphic:
      return (
        <Graphic
          {...arg}
          isInline={false}
          isinViewport={(el) => (el ? isWithinScrollThreshold(el) : false)}
          key={key1}
        />
      );
    case TagEnum.InlineGraphic:
      return (
        <Graphic
          {...arg}
          isInline={true}
          isinViewport={(el) => (el ? isWithinScrollThreshold(el) : false)}
          key={key1}
        />
      );
    case TagEnum.GraphicCaptionLabel:
      return <GraphicCaptionLabel {...arg} key={key1} />;
    case TagEnum.GraphicCaptionTitle:
      return <GraphicCaptionTitle {...arg} key={key1} />;
    case TagEnum.IndexTerm:
      return indexTerm(arg);
    case TagEnum.Italic:
    case TagEnum.MixedCitation:
      return italic(arg);
    case TagEnum.Label:
      return <Label {...arg} key={key1} />;
    case TagEnum.List:
      return list(arg);
    case TagEnum.ListItem:
      return listItem(arg);
    case TagEnum.LongDesc:
      return longDesc(arg);
    case TagEnum.Math:
      return <Math {...arg} key={key1} />;
    case TagEnum.MathWrapper:
      return <MathWrapper {...arg} key={key1} />;
    case TagEnum.Monospace:
      return monospace(arg);
    case TagEnum.NamedContent:
      return <NamedContent {...arg} key={key1} />;
    case TagEnum.NonNormativeExample:
      return nonNormativeExample(arg);
    case TagEnum.NonNormativeNote:
      return <NonNormativeNote {...arg} key={key1} />;
    case TagEnum.Overline:
      return overline(arg);
    case TagEnum.P:
      return <P {...arg} key={key1} />;
    case TagEnum.ProtectedContentNote:
      return <ProtectedContentNote {...arg} key={key1} />;
    case TagEnum.Ref:
      return ref(arg);
    case TagEnum.RefList:
      return <RefList {...arg} key={key1} />;
    case TagEnum.Roman:
      return roman(arg);
    case TagEnum.SansSerif:
      return sansSerif(arg);
    case TagEnum.Sec:
      return <Sec component={arg} elementKey={key1} />;
    case TagEnum.Smallcaps:
    case TagEnum.Sc:
      return smallcaps(arg);
    case TagEnum.Strike:
      return strike(arg);
    case TagEnum.StyledContent:
      return <StyledContent {...arg} key={key1} />;
    case TagEnum.Sub:
      return sub(arg);
    case TagEnum.Sup:
      return sup(arg);
    case TagEnum.Table:
      return <Table {...arg} key={key1} />;
    case TagEnum.TableCaptionLabel:
      return <TableCaptionLabel {...arg} key={key1} />;
    case TagEnum.TableCaptionTitle:
      return <TableCaptionTitle {...arg} key={key1} />;
    case TagEnum.TableCaptionUnits:
      return <TableCaptionUnits {...arg} key={key1} />;
    case TagEnum.TableWrap:
      return <TableWrap {...arg} key={key1} />;
    case TagEnum.TableWrapFoot:
      return <TableWrapFooter {...arg} key={key1} />;
    case TagEnum.Tbody:
      return Tbody(arg);
    case TagEnum.TbxLangSet:
      return langSet(arg);
    case TagEnum.TbxTig:
      return <Tig {...arg} key={key1} />;
    case TagEnum.Td:
      return <Td {...arg} key={key1} />;
    case TagEnum.Term:
      return term(arg);
    case TagEnum.TermInfoLabel:
      return termInfoLabel(arg);
    case TagEnum.TbxSource:
      return termInfoSource(arg);
    case TagEnum.TermSec:
      return <TermSec {...arg} key={key1} />;
    case TagEnum.Title:
      return <Title {...arg} key={key1} />;
    case TagEnum.ExtLink:
      return <ExtLink {...arg} key={key1} />;
    case TagEnum.Uri:
      return <Uri {...arg} key={key1} />;
    case TagEnum.Xref:
      return <Xref {...arg} key={key1} />;
    case TagEnum.Th:
      return <Th {...arg} key={key1} />;
    case TagEnum.Tr:
      return tr(arg);
    case TagEnum.Underline:
      return underline(arg);
    case TagEnum.Standard:
      if (isCollection) {
        // For collection documents we don't want to render the standard node, since that can lead to duplicate content in the
        // rendered output. Normally standard node is not even included in the document for viewer, but it is included in the
        // case of a collection document, to group table of content data.
        return <Standard {...arg} key={key1} />;
      }
      return defaultChildren(arg);
    case TagEnum.Document:
    case TagEnum.Front:
    case TagEnum.Body:
    case TagEnum.Back:
    case TagEnum.Std:
      return defaultChildren(arg);
    case TagEnum.StdId:
      return <Hidden {...arg} key={key1} />;
    case TagEnum.StdRef:
      return <StdRef {...arg} key={key1} />;
    case TagEnum.TbxEntailedTerm:
    case TagEnum.TbxTermEntry:
    case TagEnum.Span:
    case TagEnum.Year:
    case TagEnum.InlineFormula:
    case TagEnum.PublisherName:
    case TagEnum.PersonGroup:
    case TagEnum.Etal:
    case TagEnum.ArticleTitle:
    case TagEnum.Source:
    case TagEnum.Volume:
    case TagEnum.Issue:
    case TagEnum.FPage:
    case TagEnum.LPage:
    case TagEnum.Edition:
    case TagEnum.PublisherLoc:
      return defaultChildren(arg);
    case TagEnum.DispFormula:
      return <DispFormula {...arg} key={key1} />;
    case TagEnum.Name:
    case TagEnum.Surname:
    case TagEnum.GivenNames:
    case TagEnum.Collab:
      return DefaultChildrenWithTrailingSpace(arg);
    case TagEnum.StdDocMeta:
    case TagEnum.StdMeta:
    case TagEnum.NatMeta:
    case TagEnum.IsoMeta:
    case TagEnum.RegMeta:
      return <Metadata {...arg} key={key1} />;
    case TagEnum.TbxTerm:
    case TagEnum.TbxNormativeAuthorization:
    case TagEnum.TbxPartOfSpeech:
    case TagEnum.TbxTermType:
      return null;
    case TagEnum.PrivateChar:
      return privateChar(arg);
    case TagEnum.SubPart:
      return subPart(arg);
    default:
      return defaultComponent(arg);
  }
}

export { jsonNode };
