import { delay } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { highlightedContainer } from '../../features/searchmarkcontainer/SearchMarkSelector';
import { getUILangIfDifferentFromContentLang } from '../../i18n/getUILangIfDifferentFromContentLang';
import { RootState } from '../../redux/reducer';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';

function NonNormativeNote( arg: ComponentArg ): JSX.Element {
    const [ isExpand, setIsExpand ] = useState( false );
    const highLightedParents = useSelector(state => highlightedContainer(state as RootState, key(arg)));
    
    if (highLightedParents) {
        delay(() => setIsExpand(true), 0);
    }
    const handleCollapseExpandToggle = () => {
        setIsExpand(!isExpand);
    }

    let classes = 'sts-non-normative-note';
    const contentType = arg.node && arg.node.a['content-type'] ? arg.node.a['content-type'] : null;
    if (contentType) {
        classes += ` ${contentType}`;
    }
    const isInsideRequirement = arg.node && arg.node.a['is-inside-requirement'] ? true : false;
    if (isInsideRequirement && contentType === 'guide') {
        const {t} = arg,
            collapseExpandText = isExpand ? t( 'Collapse' ) : t( 'Expand' );

        return (
            <div className={classes} key={key(arg)}>
                <button
                    type="button"
                    onClick={handleCollapseExpandToggle}
                    className="guide-toggle"
                    title={collapseExpandText}
                    lang={getUILangIfDifferentFromContentLang()}
                    aria-controls={`#${key(arg)}`}
                    aria-expanded={isExpand}
                >
                    {t('Guidance on the requirement')}
                </button>
                <div className={`guide-text ${!isExpand ? 'hidden' : ''}`} id={key(arg)}>
                    {defaultChildren(arg)}
                </div>
            </div>
        );
    } else {
        return (
            <div className={ classes } key={ key( arg ) }>
                { defaultChildren( arg ) }
            </div>
        );
    }
}

export { NonNormativeNote };
