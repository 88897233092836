import { Uuid, WorkerMessage, FromWorker } from '../../../types/workerMessage.type';

export interface WorkerStoreItem<Content> {
    requestId: Uuid
    content: Content
}

function message2item<Content>( message: WorkerMessage<FromWorker> ): WorkerStoreItem<Content> {
    return {
        requestId: message.requestId,
        content: message.content[ 1 ] as any as Content
    };
}

export { message2item };
