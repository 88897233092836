import * as assert from 'assert';

import {
    maxBufferSizePixels,
    minBufferSizePixels,
    minBufferTextSize,
    preventTrimForSectionsOfMinSize
} from '../../constants/json-node';
import { ScrollNotification } from '../../types/lazy.type';
import { getActiveLazyState } from '../../redux/standard-state/lazyStateProxy';
import { NodeId } from '../../types/json-node.type';

export enum AboveBelow {
    above = 'above',
    below = 'below'
}

export class UpdateSectionService {
    static shouldSectionsTrim(
        notification: ScrollNotification,
        where: AboveBelow ): boolean {
        // const preventTrim = UpdateSectionService.preventTrimBasedOnTextSize( where );
        // if ( preventTrim ) {
        //     return false;
        // }

        // return UpdateSectionService.trimBasedOnPixelSize( where, notification );

        return false;
    }

    private static trimBasedOnPixelSize( where: AboveBelow, notification: ScrollNotification ) {
        switch ( where ) {
            case AboveBelow.above:
                return notification.topBufferMargin > maxBufferSizePixels;
            case AboveBelow.below:
                return notification.bottomBufferMargin > maxBufferSizePixels;
        }
    }

    private static preventTrimBasedOnTextSize( where: AboveBelow ): boolean {
        const indices = UpdateSectionService.hiddenSectionIdx( where ),
            state = getActiveLazyState(),
            range = indices[ 1 ] - indices[ 0 ];

        assert.ok( range >= 0, 'incorrect range: indices[ 1 ] < indices[ 0 ]' );

        let result = false;

        for ( let idx = indices[ 0 ]; idx <= indices[ 1 ]; idx++ ) {
            const nodeId = state.idx2NodeId( idx );
            if ( nodeId && !result ) {
                result = ( state.flatRenderedTextSize.get( nodeId ) || 0 ) > preventTrimForSectionsOfMinSize;
                if ( result ) {
                    break;
                }
            }
        }

        return result && range < 10;
    }

    private static hiddenSectionIdx( where: AboveBelow ): [ number, number ] {
        const state = getActiveLazyState(),
            result: [ number, number ] = [ -1, -1 ];
        switch ( where ) {
            case AboveBelow.above:
                result[ 0 ] = state.firstRenderedIdx;
                result[ 1 ] = state.firstRenderedIdx;
                for ( let above = state.firstRenderedIdx; above <= state.lastRenderedIdx; above++ ) {
                    const nodedId: NodeId | undefined = state.idx2NodeId( above );
                    if ( nodedId && state.visibleSections.has( nodedId ) ) break;
                    result[ 1 ] = above;
                }
                break;
            case AboveBelow.below:
                result[ 0 ] = state.lastRenderedIdx;
                result[ 1 ] = state.lastRenderedIdx;
                for ( let below = state.lastRenderedIdx; below >= state.firstRenderedIdx; below-- ) {
                    const nodedId: NodeId | undefined = state.idx2NodeId( below );
                    if ( nodedId && state.visibleSections.has( nodedId ) ) break;
                    result[ 0 ] = below;
                }
                break;
        }

        return result;
    }

    static shouldSectionsExpand(
        notification: ScrollNotification,
        where: AboveBelow ): boolean {

        switch ( where ) {
            case AboveBelow.above:
                return notification.topBufferMargin < minBufferSizePixels &&
                    !UpdateSectionService.reachedStartOfDocument;
            case AboveBelow.below:
                return notification.bottomBufferMargin < minBufferSizePixels &&
                    !UpdateSectionService.reachedEndOfDocument;
        }
    }

    expandSections( where: AboveBelow ): void {
        switch ( where ) {
            case AboveBelow.above:
                this.expandTopTextSizeAware( 0 );
                break;
            case AboveBelow.below:
                this.expandBottomTextSizeAware( 0 );
                break;
        }
    }

    static trimSections( where: AboveBelow ): void {
        const state = getActiveLazyState();

        switch ( where ) {
            case AboveBelow.above:
                state.firstRenderedIdx++;
                break;
            case AboveBelow.below:
                state.lastRenderedIdx--;
                break;
        }
    }

    private expandTopTextSizeAware( accumulatedTextSize: number ): void {
        // noinspection DuplicatedCode
        if ( accumulatedTextSize > minBufferTextSize ||
            UpdateSectionService.reachedStartOfDocument ) {
            return;
        }

        const state = getActiveLazyState();
        state.firstRenderedIdx--;
        const nextIdx = state.firstRenderedIdx,
            id: NodeId | undefined = state.idx2NodeId( nextIdx );

        if ( !id ) {
            return;
        }

        const textSize = state.flatRenderedTextSize.get( id );
        assert.ok( textSize !== undefined && textSize >= 0,
            `expected non-negative textSize for ${id}. Got: ${textSize}` );

        this.expandTopTextSizeAware( accumulatedTextSize + ( textSize as number ) );
    }

    private expandBottomTextSizeAware( accumulatedTextSize: number ): void {
        // noinspection DuplicatedCode
        if ( accumulatedTextSize > minBufferTextSize ||
            UpdateSectionService.reachedEndOfDocument ) {
            return;
        }

        const state = getActiveLazyState();
        state.lastRenderedIdx++;
        const nextIdx = state.lastRenderedIdx,
            id: NodeId | undefined = state.idx2NodeId( nextIdx );

        if ( !id ) {
            return;
        }

        const textSize = state.flatRenderedTextSize.get( id );
        assert.ok( textSize !== undefined, `undefined textSize for ${id}` );
        this.expandBottomTextSizeAware( accumulatedTextSize + ( textSize as number ) );
    }


    private static get reachedStartOfDocument(): boolean {
        return getActiveLazyState().firstRenderedIdx <= 0;
    }

    private static get reachedEndOfDocument(): boolean {
        const state = getActiveLazyState();
        return state.lastRenderedIdx >= state.allCount - 1;
    }
}
