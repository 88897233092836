import i18n from "./i18n";

function getUILangIfDifferentFromContentLang(): string | undefined  {

    let uiDifferentLang = undefined;
    if (document.documentElement.lang !== i18n.language) {
        uiDifferentLang = i18n.language;
    }   
    return uiDifferentLang;
}

export { getUILangIfDifferentFromContentLang }