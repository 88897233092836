enum TagEnum {
    AnnexType = 'annex-type',
    App = 'app',
    AppGroup = 'app-group',
    Array = 'array',
    Back = 'back',
    Body = 'body',
    Bold = 'bold',
    BoxedText = 'boxed-text',
    Break = 'break',
    Caption = 'caption',
    Code = 'code',
    Col = 'col',
    Colgroup = 'colgroup',
    ContentLanguage = 'content-language',
    Def = 'def',
    DefList = 'def-list',
    DefItem = 'def-item',
    Document = '#document',
    DispFormula = 'disp-formula',
    DispQuote = 'disp-quote',
    ExtLink = 'ext-link',
    Fig = 'fig',
    Fn = 'fn',
    FnGroup = 'fn-group',
    Front = 'front',
    Graphic = 'graphic',
    GraphicCaptionLabel = 'graphic-caption-label',
    GraphicCaptionTitle = 'graphic-caption-title',
    IndexTerm = 'index-term',
    InlineGraphic = 'inline-graphic',
    InlineFormula = 'inline-formula',
    IsoMeta = 'iso-meta',
    Italic = 'italic',
    Label = 'label',
    List = 'list',
    ListItem = 'list-item',
    LongDesc = 'long-desc',
    Main = 'main',
    Math = 'mml:math',
    MathWrapper = 'math-wrapper',
    MixedCitation = 'mixed-citation',
    Monospace = 'monospace',
    NamedContent = 'named-content',
    NatMeta = 'nat-meta',
    NonNormativeExample = 'non-normative-example',
    NonNormativeNote = 'non-normative-note',
    Overline = 'overline',
    P = 'p',
    ProtectedContentNote = 'protected-content-note',
    PrivateChar = 'private-char',
    Ref = 'ref',
    RefList = 'ref-list',
    RegMeta = 'reg-meta',
    Roman = 'roman',
    SansSerif = 'sans-serif',
    Sec = 'sec',
    Smallcaps = 'smallcaps',
    Sc = 'sc',
    Span = 'span',
    Standard = 'standard',
    Std = 'std',
    StdDocMeta = 'std-doc-meta',
    StdMeta = 'std-meta',
    StdId = 'std-id',
    StdRef = 'std-ref',
    Strike = 'strike',
    StyledContent = 'styled-content',
    Sub = 'sub',
    SubPart = 'sub-part',
    Sup = 'sup',
    Table = 'table',
    TableCaptionLabel = 'table-caption-label',
    TableCaptionTitle = 'table-caption-title',
    TableCaptionUnits = 'table-caption-units',
    TableWrap = 'table-wrap',
    TableWrapFoot = 'table-wrap-foot',
    Tbody = 'tbody',
    TbxLangSet = 'tbx:langSet',
    TbxNormativeAuthorization = 'tbx:normativeAuthorization',
    TbxEntailedTerm = 'tbx:entailedTerm',
    TbxPartOfSpeech = 'tbx:partOfSpeech',
    TbxTerm = 'tbx:term',
    TbxTermEntry = 'tbx:termEntry',
    TbxTermType = 'tbx:termType',
    TbxTig = 'tbx:tig',
    TbxSource = 'tbx:source',
    Td = 'td',
    Term = 'term',
    TermInfoLabel = 'term-info-label',
    TermSec = 'term-sec',
    Thead = 'thead',
    Th = 'th',
    Title = 'title',
    TitleWrap = 'title-wrap',
    Tr = 'tr',
    Underline = 'underline',
    Uri = 'uri',
    Xref = 'xref',
    Year = 'year',
    PublisherName = 'publisher-name',
    PersonGroup = 'person-group',
    Etal = 'etal',
    ArticleTitle = 'article-title',
    Source = 'source',
    Volume = 'volume',
    Issue = 'issue',
    FPage = 'fpage',
    LPage = 'lpage',
    Edition = 'edition',
    PublisherLoc = 'publisher-loc',
    Name = 'name',
    Surname = 'surname',
    GivenNames = 'given-names',
    Collab = 'collab',
}

export { TagEnum };
