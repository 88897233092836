import React from "react";
import { useTranslation } from "react-i18next";
import { isSdCommerce } from "../../util/checkConfig";
import { useAppSelector } from "../../../redux/store";

export interface ReportErrorProp {
    errorId: string;
}

export const ReportError = ({ errorId }: ReportErrorProp) => {

    const { t } = useTranslation();
    const config = useAppSelector((state) => state.configuration);
    const supportEmailLink = `
        <a href="mailto:${config.supportEmail}">
            <span class="invisible">${t('Send email to')}</span>
            ${config.supportEmail}
        </a>
    `;
    const translationKey = isSdCommerce() ? 'Errors.DocumentInaccessibleReportId' : 'Errors.DocumentReportId';

    const translatedText = t(translationKey, { errorId, "email": supportEmailLink} );

    return (
        <p className="small" dangerouslySetInnerHTML={{__html: translatedText}} />
    )
}
