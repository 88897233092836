export enum ShowErrorActionType {
    clearError = 'clearError',
    addError = 'addError',
    globalError = 'globalError'
}

export interface ShowErrorAction {
    type: ShowErrorActionType;
    errorText?: string;
}
