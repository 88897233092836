import { Action } from 'redux';

export enum ScreenWidthEnum {
    Narrow = 'Narrow',
    Wide = 'Wide'
}

export enum FullScreenEnum {
    FullScreen = 'FullScreen',
    NoFullScreen = 'NoFullScreen'
}

export enum ScreenWidthActionTypeEnum {
    screenWidth = 'screenWidth',
    fullScreen = 'fullScreen'
}

export interface ScreenWidthState {
    screenWidth?: ScreenWidthEnum
    fullScreen?: FullScreenEnum
}

export interface ScreenWidthAction extends Action, ScreenWidthState {
    type: ScreenWidthActionTypeEnum
}

function screenWidthReducer(
    _previousState: ScreenWidthState = {},
    action: ScreenWidthAction ): ScreenWidthState {

    let newState = { ..._previousState };

    switch ( action.type ) {
        case ScreenWidthActionTypeEnum.screenWidth:
            newState.screenWidth = action.screenWidth;
            break;
        case ScreenWidthActionTypeEnum.fullScreen:
            newState.fullScreen = action.fullScreen;
            break;
    }

    return newState;
}

export { screenWidthReducer };
