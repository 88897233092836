const languages = {
  en: {
    code: 'en',
    shortName: 'en',
    longName: 'English',
  },
  nb: {
    code: 'nb',
    shortName: 'nb',
    longName: 'Norsk',
  }
};

export { languages }