import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppConfiguration } from '../slices/configurationSlice';

export const appConfigurationApi = createApi({
  reducerPath: 'appConfigurationApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
  }),
  keepUnusedDataFor: 86400, // Keep data cached, really should just call this once during app startup
  endpoints: (builder) => ({
    getAppConfiguration: builder.query<AppConfiguration, void>({
      query: () => ({
        url: `/appconfiguration`,
      }),
    }),
  }),
});

export const { useGetAppConfigurationQuery } = appConfigurationApi;
