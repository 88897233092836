import { TFunction } from "i18next";
import React from "react";
import { useLocation } from "react-router-dom";
import { queryStrings } from "../constants/query-strings";
import i18n from "../i18n/i18n";
export interface ProductLinkProps {
    productId: string;
    linkTitle: any;
    className?: string;
    useRegularAnchorTag?: boolean;
    languageCode?: string;
    languageName?: string;
    filePath?: string;
    t?: TFunction;
    fileType?: string;
}

const ProductLink = (props: ProductLinkProps) => {
    const { search } = useLocation();
    const currentUrlSearchParams = new URLSearchParams(search);
    const externalClient = currentUrlSearchParams.get(queryStrings.externalClient);
    const organization = currentUrlSearchParams.get(queryStrings.organization);

    const productLinkSearchParams = new URLSearchParams();

    productLinkSearchParams.append(queryStrings.langUI, i18n.language);
    if (props.filePath) {
        productLinkSearchParams.append(queryStrings.filePath, props.filePath);
    }

    if (organization) {
        productLinkSearchParams.append(queryStrings.organization, organization);
    }

    if (externalClient) {
        productLinkSearchParams.append(queryStrings.externalClient, externalClient);
    }

    if (props.fileType) {
        productLinkSearchParams.append(queryStrings.fileType, props.fileType);
    }

    const langCodeAddress = (props.languageCode && `/${props.languageCode}`) || '';
    const linkUrl = `/product/${props.productId}${langCodeAddress}?${productLinkSearchParams.toString()}`;

    if (props.useRegularAnchorTag && props.languageCode && props.languageName && props.t) {

        // this is added because some page content is not refreshed when using <Link to navigate to other language
        return <a
            href={linkUrl}
            hrefLang={props.languageCode.toLowerCase()}
            aria-label={props.t('SelectDocumentLanguage', { language: props.languageName })}>
            {props.linkTitle}
        </a>
    }

    return <a
        href={linkUrl}
        className={props.className ? props.className : ''}>
        {props.linkTitle}
    </a>
};

export { ProductLink };
