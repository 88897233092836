import React from 'react';
import * as assert from 'assert';

import { ComponentArg } from '../../types/component-arg';
import { JsonNodeType } from '../../types/json-node.type';
import { defaultChildren } from './default-children';
import { key } from '../util/key';
import { getAppConfiguration } from '../../redux/store';

class Tags {
    constructor( arg: ComponentArg ) {
        assert.ok( arg.node, 'undefined arg.node' );
        this.node = arg.node!;
    }

    readonly node: JsonNodeType;

    get tag(): string {
        return `<${ this.node.tag }>`;
    }

    get closingTag(): string {
        return `</${ this.node.tag }>`;
    }

    get attributes(): string | null {
        const a = this.node.a,
            exist = Object.keys( a ).length > 0;

        return exist ? JSON.stringify( a ) : null;
    }
}

function defaultComponent( arg: ComponentArg ): JSX.Element {
    const isProd = getAppConfiguration().production;
    return isProd ? componentNoTags(arg) : componentWithTags(arg);
}

function componentWithTags( arg: ComponentArg ): JSX.Element {
    const tags = new Tags( arg );
    return (
        <React.Fragment key={ key( arg ) }>
            <span className="tagName">{ tags.tag }</span>
            { tags.tag ? <p className="attributes">{ tags.attributes }</p> : '' }
            { defaultChildren( arg ) }
            <span className="tagName">{ tags.closingTag }</span>
        </React.Fragment>
    );
}

function componentNoTags( arg: ComponentArg ): JSX.Element {
    const className = arg.node!.tag ? arg.node!.tag.replace(':', '_') : undefined;
    return (<div key={ key( arg ) } className={ className } >{defaultChildren( arg )}</div>);
}

export { defaultComponent };
