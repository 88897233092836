import { getBranch } from '../../graph/getBranch';
import { GraphService } from '../../graph/graph.service';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { path2idStore } from '../../redux/worker/stores/path2Id.store';
import { makeWorkerPromise } from '../../redux/worker/stores/workerRequestFactory';
import { CyNode } from '../../types/graph.type';
import { JsonNodeTypeBranch } from '../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';

async function getRelatedFootnoteNode(
  footnoteId: string
): Promise<JsonNodeTypeBranch | undefined> {
  let relatedFootnoteNode: JsonNodeTypeBranch | undefined;
  const graphService: GraphService =
      standardStateStore.getState().graphService!,
    fullModel = graphService.fullJsonData!,
    data4worker: ToWorker = [WorkerMessageType.pathToId, footnoteId],
    promise: Promise<CyNode[]> = makeWorkerPromise(data4worker, path2idStore),
    path: CyNode[] = await promise;

  if (path && path.length) {
    relatedFootnoteNode = getBranch(fullModel, path);
  }

  return relatedFootnoteNode;
}

export default getRelatedFootnoteNode;
