import React, { Component, RefObject } from 'react';
import { Unsubscribe } from 'redux';
import i18n from '../../i18n/i18n';

import { storageKeys } from '../../storage/storage-keys';
import { standardStateStore } from '../../redux/standard-state/standard-state.store';
import { productInfoLoadedSubject } from '../../redux/identity/productInfoLoadedSubject';
import { OpenedDocument } from '../../types/opened-document';
import { closeLeftMenuSubject } from '../../redux/identity/closeLeftMenuSubject';
import { screenWidthStore } from '../../redux/identity/screenWidthStore';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toggleBodyClass } from '../util/toggleBodyClass';
import { innerDiv } from '../util/innerDiv';
import { ScreenWidthActionTypeEnum, ScreenWidthEnum } from '../../redux/identity/screenWidthReducer';
import { showErrorStore, ErrorTypes } from '../../redux/show-error';
import { ShowErrorState } from '../../redux/show-error/show-error.state';
import { compareProductOwner, isPublic, isSdCommerce, productOwner } from '../util/checkConfig';
import logoImageSnv from '../../themes/snvTheme/assets/images/logo.png';
import { LanguageSelection } from './language-selection';
import { productOwners } from '../../constants/product-owners';
import { customerCodes } from '../../constants/customer-codes';
import Logo from './logo';
import { ProductLink } from '../product-link';
import { getAppConfiguration } from '../../redux/store';

export interface HeaderState {
    leftMenuExpanded: boolean,
    productId: string;
    lastOpenedDocuments: OpenedDocument[];
    documentListSelected: boolean;
    wasGlobalError: boolean;
    customCssClientCode?: string;
}

export interface HeaderProp extends WithTranslation {}

enum CloseLeftMenuCase {
    Close = 'Close',
    Toggle = 'Toggle'
}

class Header1 extends Component<HeaderProp, HeaderState> {
    constructor( p: HeaderProp ) {
        super( p );
        this.state = {
            leftMenuExpanded: false,
            productId: '',
            lastOpenedDocuments: [],
            documentListSelected: false,
            wasGlobalError: false,
            customCssClientCode: '',
        };
        this.viewerHeaderRef = React.createRef();
    }

    private readonly languageList: RefObject<HTMLUListElement> | null;
    private readonly viewerHeaderRef?: RefObject<HTMLDivElement>;

    private documentHistoryBreadcrumb: HTMLDivElement | null;
    private documentHistoryList: HTMLUListElement | null;

    private productInfoLoadedUnsubscribe?: Unsubscribe;
    private closeLeftMenuUnsubscribe?: Unsubscribe;
    private unsubscribeGlobalError?: Unsubscribe;

    componentDidMount() {
        document.addEventListener( 'mousedown', this.clickOutsideHandler );
        document.addEventListener( 'touchstart', this.clickOutsideHandler );
        window.addEventListener( 'resize', this.notifyScreenWidth.bind( this ) );
        this.productInfoLoadedUnsubscribe = productInfoLoadedSubject.subscribe( this.setProductId.bind( this ) );
        this.closeLeftMenuUnsubscribe = closeLeftMenuSubject.subscribe( this.toggleLeftMenuHandler( CloseLeftMenuCase.Close ) );
        this.unsubscribeGlobalError = showErrorStore.subscribe(this.onGlobalErrorUpdated.bind( this ));

        this.loadLastOpenedDocuments();
        this.notifyScreenWidth();
        this.setProductId();
    }

    onGlobalErrorUpdated() {
        const storeState: ShowErrorState = showErrorStore.getState();
        if (storeState.errorText === ErrorTypes.GlobalError) {
            this.setState( { wasGlobalError: true } );
        }
    }

    componentDidUpdate( prevProps: Readonly<{}>, prevState: Readonly<HeaderState>, snapshot?: any ): void {
        this.notifyScreenWidth();
    }

    loadLastOpenedDocuments() {
        const storageService = standardStateStore.getState().storageService!;
        let lastOpenedDocuments: OpenedDocument[] = storageService.readFromStorage( storageKeys.LAST_OPENED_DOCUMENTS );
        if ( lastOpenedDocuments && lastOpenedDocuments.length ) {
            const owner = productOwner();
            lastOpenedDocuments = lastOpenedDocuments.filter(x => x.productOwner === owner);
            this.setState( { lastOpenedDocuments: lastOpenedDocuments } );
        }
    }

    setProductId() {
        if (standardStateStore.getState().requestUrlData) {
            this.setState( { productId: standardStateStore.getState().requestUrlData!.productId! } );
        }

        const graph = standardStateStore.getState().graphService;
        if ( graph ) {
            const productInfo = graph.productInfo;
            if ( productInfo ) {

                const config = getAppConfiguration();
                let customCssClientCode = undefined;
                if (isSdCommerce()) {
                    customCssClientCode =  config.customerCode;
                } else {
                    customCssClientCode =  productInfo.customCssClientCode;
                }
                this.setState( { customCssClientCode: customCssClientCode } );
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener( 'mousedown', this.clickOutsideHandler );
        document.removeEventListener( 'touchstart', this.clickOutsideHandler );
        window.removeEventListener( 'resize', this.notifyScreenWidth.bind( this ) );
        this.productInfoLoadedUnsubscribe!();
        this.closeLeftMenuUnsubscribe!();
        this.unsubscribeGlobalError!();
    }

    private static buttonIsClicked( e: Event ): boolean {
        if ( e.target && e.target instanceof HTMLElement ) {
            return e.target.tagName.toLowerCase() === 'button';
        }
        return false;
    }

    clickOutsideHandler = ( e: Event ) => {
        const shouldCloseMainMenu = this.shouldCloseMainMenu( e ),
            newState: any = {};

        if ( shouldCloseMainMenu ) {
            newState.leftMenuExpanded = false;
            this.toggleBodyClass();
        }

        this.setState( newState );
    };

    private targetIsInsideDocumentHistoryBreadcrumb( e: Event ): boolean {
        const targetDiv: HTMLDivElement = e.target as HTMLDivElement;
        return this.documentHistoryBreadcrumb !== null &&
            this.documentHistoryBreadcrumb.contains( targetDiv );
    }

    private shouldCloseMainMenu( e: Event ): boolean {
        const target = e.target as HTMLDivElement,
            slideOutMenuContainer: HTMLDivElement | null = innerDiv();

        if ( !slideOutMenuContainer ) return false;

        return this.state.leftMenuExpanded &&
            !slideOutMenuContainer!.contains( target ) &&
            !Header1.buttonIsClicked( e ) &&
            !this.targetIsInsideDocumentHistoryBreadcrumb( e );
    }


    toggleLeftMenuHandler = ( menuCase: CloseLeftMenuCase ) => () => {
        const currentExpanded = this.state.leftMenuExpanded;
        if ( menuCase === CloseLeftMenuCase.Close && !currentExpanded ) return;

        this.toggleBodyClass();

        const newMenuExpanded = !this.state.leftMenuExpanded;
        this.setState( { leftMenuExpanded: newMenuExpanded } );
        if ( this.state.documentListSelected ) {
            this.setState( { documentListSelected: false } );
            toggleBodyClass( 'documentSelectOpen', false );
        }
    };

    private toggleBodyClass(): void {
        const newMenuExpanded = !this.state.leftMenuExpanded;
        toggleBodyClass( 'menuExpanded', newMenuExpanded );
    }

    closeDocumentHandler( index: number ) {
        const lastOpenedDocuments = [ ...this.state.lastOpenedDocuments ];
        lastOpenedDocuments.splice( index, 1 );

        this.setState( { lastOpenedDocuments } );

        const storageService = standardStateStore.getState().storageService!;
        storageService.writeToStorage( storageKeys.LAST_OPENED_DOCUMENTS, lastOpenedDocuments );
    }

    documentSelectHandler() {
        const documentListSelected = !this.state.documentListSelected;
        this.setState( { documentListSelected } );
        toggleBodyClass( 'documentSelectOpen', documentListSelected );
    }

    render() {
        const { t } = this.props,
            selectDocument = t( 'Show more' ),
            closeDocument = t( 'Close document' ),
            menu = t( 'Menu' );

        let uiDifferentLang = '';
        if ( document.documentElement.lang !== i18n.language ) {
            uiDifferentLang = i18n.language;
        }

        const showPartsOfHeader = this.state.productId || this.state.wasGlobalError;

        let logoHtml = (
            <div className="viewerLogo">
                <Logo clientCode={this.state.customCssClientCode} />
                <span className="screen-reader-only">Logo</span>
            </div>);

        if (isPublic() && this.state.customCssClientCode === customerCodes.snv && compareProductOwner( productOwners.at )) {
            logoHtml = (
                <>
                    <div><img className="logoImage" src={ logoImageSnv } alt="logo" /></div>
                    <p className="headerText">{  t( 'covidtext' ) }</p>
                </>
            );
        }

        let documentHistoryHtml: JSX.Element | null = null;
        if (isPublic()) {
            if (isSdCommerce() || this.state.customCssClientCode !== customerCodes.snv) {
                documentHistoryHtml = (
                    <div className="documentHistory">
                        <ul className="documentList"></ul>
                    </div>
                );
            }
        } else {
            documentHistoryHtml = (
                <>
                    <div ref={node => ( this.documentHistoryBreadcrumb = node )} className="documentHistory">
                        <ul ref={node => ( this.documentHistoryList = node )} className="documentList">
                            {this.documentHistoryBreadcrumbs( closeDocument, uiDifferentLang )}
                        </ul>
                    </div>
                    <button
                        className="documentSelect"
                        onClick={this.documentSelectHandler.bind( this )}
                        title={selectDocument}
                        lang={uiDifferentLang ? uiDifferentLang : undefined}
                    >
                        {selectDocument}
                    </button>
                </>
            );
        }

        return (
         <>
            { showPartsOfHeader ? (<a className="skip" href="#mainContent">{t('Skip to content')}</a>) : null }
            <header className="viewerHeader" ref={this.viewerHeaderRef}>
                { showPartsOfHeader ? (<>
                        { logoHtml }
                        { documentHistoryHtml }
                        <div className="headerControls">
                            <LanguageSelection/>
                            <button
                                className="menuExpand"
                                onClick={this.toggleLeftMenuHandler( CloseLeftMenuCase.Toggle )}
                                title={menu}
                                lang={uiDifferentLang ? uiDifferentLang : undefined}
                            >{menu}</button>
                        </div>
                    </>) : null }
            </header>
        </>
        );
    }

    private documentHistoryBreadcrumbs( closeDocument: string, uiDifferentLang: string ): JSX.Element[] {

        return this.state.lastOpenedDocuments.map( ( lastOpenedDocument, index ) => {

            const closeDocumentHandlerCallback = () => {
                this.closeDocumentHandler( index );
            };

            const documentTitle = lastOpenedDocument.title + (lastOpenedDocument.languageCode ? ' ' + lastOpenedDocument.languageCode : '');
            const documentTitleHtml = documentTitle.replace(/[+]/g, '+<wbr>').replace(/[/]/g, '/<wbr>');
            const documentTitleJsx = React.createElement('span', { dangerouslySetInnerHTML: { __html: documentTitleHtml } });

            return ( <li key={lastOpenedDocument.productId}
                         className={this.state.productId && this.state.productId === lastOpenedDocument.productId ? 'documentListItem selected' : 'documentListItem'}>

                <ProductLink
                            productId={lastOpenedDocument.productId}
                            languageCode={lastOpenedDocument.languageCode}
                            linkTitle={documentTitleJsx}
                            className={'documentListItemLink'}/>

                <button onClick={closeDocumentHandlerCallback}
                        title={closeDocument}
                        lang={uiDifferentLang ? uiDifferentLang : undefined}
                        className="closeDocumentButton">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" className="closeDocumentButtonIcon">
                                <g
                                    fill="none"
                                    fillRule="evenodd"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    transform="translate(1 1)"
                                >
                                    <rect width="20" height="20" rx="4"></rect>
                                    <path d="M5 5l10 10m0-10L5 15"></path>
                                </g>
                            </svg>
                        </button>
            </li> );
        } );
    }

    notifyScreenWidth() {
        const screenWidth: ScreenWidthEnum | null = this.screenWidth;
        if ( screenWidth ) {
            screenWidthStore.dispatch( {
                type: ScreenWidthActionTypeEnum.screenWidth,
                screenWidth
            } );
        }
        this.checkDocumentHistoryBreadcrumbsWidth();
    }

    private checkDocumentHistoryBreadcrumbsWidth() {
        if ( this.documentHistoryBreadcrumb && this.documentHistoryList ) {
            const listInnerHeight = this.documentHistoryList.scrollHeight;
            const listOuterHeight = this.documentHistoryList.offsetHeight;
            if ( !document.body.classList.contains( 'documentSelectOpen' ) ) {
                if ( listInnerHeight > listOuterHeight ) {
                    this.documentHistoryBreadcrumb.classList.add( 'tooWide' );
                } else {
                    this.documentHistoryBreadcrumb.classList.remove( 'tooWide' );
                }
            }
        }
    }

    private get screenWidth(): ScreenWidthEnum | null {
        const ref = this.viewerHeaderRef;
        if ( !ref ) return ScreenWidthEnum.Wide;
        const el: HTMLDivElement | null = ref.current;
        if ( !el ) return ScreenWidthEnum.Wide;
        const style = window.getComputedStyle( el );
        switch ( style.position ) {
            case 'fixed':
                return ScreenWidthEnum.Narrow;
            case 'relative':
                return ScreenWidthEnum.Wide;
            default:
                return null;
        }
    }
}

const Header = withTranslation()( Header1 );

export { Header };
