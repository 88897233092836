import React, { useRef, useEffect } from 'react';
import { ComponentArg } from '../../types/component-arg';
import { defaultChildren } from '../generic/default-children';
import { key } from '../util/key';
import { rotateTableCell } from '../util/tableCellRotate';

export type align = "left" | "center" | "right" | "justify" | "char" | undefined;

function Th(props: ComponentArg): JSX.Element {

    const thRef: React.RefObject<HTMLTableCellElement> = useRef(null);

    useEffect(() => {
        let style = props.node!.a && props.node!.a['style'];
        if (style) {
            style = rotateTableCell(style, thRef);
            thRef.current!.setAttribute('style', style);
        }
    }, [props.node]);

    const rowSpan = props.node!.a && props.node!.a['rowspan'] ? Number(props.node!.a['rowspan']) : undefined;
    const colSpan = props.node!.a && props.node!.a['colspan'] ? Number(props.node!.a['colspan']) : undefined;
    const align = props.node!.a && props.node!.a['align'] ? props.node!.a['align']! as align : undefined;
    const scope = props.node!.a && props.node!.a['scope'] ? props.node!.a['scope']! : undefined;

    return (<th ref={thRef}
        rowSpan={rowSpan ? rowSpan : undefined}
        colSpan={colSpan ? colSpan : undefined}
        align={align ? align : undefined}
        scope={scope ? scope : undefined}
        key={key(props)}>{defaultChildren(props)}</th>);
}

export { Th };
