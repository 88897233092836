import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/reducer'
import { defaultTableItem, TableItem, TablesStateProps } from './index';

const root = (state: RootState) => state.TablesState;
const tableWrapIdToSearch = (state: RootState, tableWrapId: string) => tableWrapId

const tableState = createSelector([root, tableWrapIdToSearch],  (state: TablesStateProps, idToSearch: string): TableItem => {
  const result = state.items.filter(x => x.tableWrapId === idToSearch);

  if (result.length === 0) {
    return defaultTableItem();
  }

  return result[0];
});

export { tableState }