import React from 'react';
import { TFunction } from 'i18next';

import { ComponentArg } from '../../types/component-arg';
import { TagEnum } from '../../types/tag-enum';
import { JsonNodeTypeGraph} from '../../types/graph.type';
import { ToWorker, WorkerMessageType } from '../../types/workerMessage.type';
import { DbType } from '../../types/db.type';
import { nodeWithTagSelectors } from '../../graph/nodeWithTagSelectors';
import { makeWorkerPromise } from '../../redux/worker/stores/workerRequestFactory';
import { parentsStore } from '../../redux/worker/stores/parents.store';
import { childrenStore } from '../../redux/worker/stores/children.store';
import { MixedContent } from '../../types/json-node.type';

export interface TigState {
    termTitle?: string;
    termNode?: ComponentArg;
    normativeAuthorization?: string;
    termType?: string;
    partOfSpeech?: string;
    languageCode?: string;
    isDeprecatedTerm?: boolean;
    isPreferredTerm?: boolean;
}

async function stateChanges( props: ComponentArg ): Promise<TigState> {
    const stateChanges: TigState = {};
    const langSetNode = await getLangSetNode( props.node!.id );
    let hasMultipleTerms = false;
    if ( langSetNode ) {
        hasMultipleTerms = await hasMultipleTermsForLangSetNode( langSetNode.id );
    }

    for ( let termChild of props.node!.c ) {
        if (isTermWithChild(termChild)) {
            stateChanges.termTitle = termChild.x!.c[0].t;
            if (termChild.x!.c[0].t) {
                let termChildNodeType = { ...termChild.x!, c: termChild.x!.c.slice(1) };
                stateChanges.termNode = { node: termChildNodeType, t: props.t };
                continue;
            }

            stateChanges.termNode = { node: termChild.x, t: props.t };
            continue;
        }

        if ( termChild.x && termChild.x.tag === 'tbx:normativeAuthorization' && termChild.x.a[ 'value' ] ) {
            const attributeValue = termChild.x.a[ 'value' ];
            if ( attributeValue === 'deprecatedTerm' ) {
                stateChanges.isDeprecatedTerm = true;
            } else if ( hasMultipleTerms && attributeValue === 'preferredTerm' ) {
                stateChanges.isPreferredTerm = true;
            }
            stateChanges.normativeAuthorization = attributeValue;
            continue;
        }

        if ( termChild.x && termChild.x.tag === 'tbx:termType' && termChild.x.a[ 'value' ] ) {
            stateChanges.termType = termChild.x.a[ 'value' ];
            continue;
        }

        if ( termChild.x && termChild.x.tag === 'tbx:partOfSpeech' && termChild.x.a[ 'value' ] ) {
            stateChanges.partOfSpeech = termChild.x.a[ 'value' ];
        }
    }

    const languageCode = getLanguageCodeIfDifferentFromContentLanguage( langSetNode );
    if ( languageCode ) {
        stateChanges.languageCode = languageCode;
    }
    return stateChanges;
}

function getTitle( props: ComponentArg, state: TigState ): JSX.Element | undefined {
    let termAttributeListHtml = getTermAttributeListHtml( props, state );
    if ( !termAttributeListHtml ) {
        return undefined;
    }

    return (
        <>
            <h2>{state.termTitle}</h2>
            {termAttributeListHtml}
        </> );
}

function getTermAttributeListHtml( props: ComponentArg, state: TigState ): JSX.Element | null {
    const { t } = props,
        normativeAuthorizationHtml = getNormativeAuthorizationHtml( t, state ),
        termTypeHtml = getTermTypeHtml( t, state ),
        partOfSpeechHtml = getPartOfSpeechHtml( t, state ),
        languageHtml = getLanguageHtml( t, state );

    let termAttributeListHtml = null;
    if ( normativeAuthorizationHtml || termTypeHtml || partOfSpeechHtml || languageHtml ) {
        termAttributeListHtml = (
            <ul>
                {languageHtml}
                {normativeAuthorizationHtml}
                {termTypeHtml}
                {partOfSpeechHtml}
            </ul>
        );
    }
    return termAttributeListHtml;
}

function getPartOfSpeechHtml( t: TFunction, state: TigState ): JSX.Element | null {
    let partOfSpeechHtml = null;
    if ( state.partOfSpeech ) {
        partOfSpeechHtml = (
            <li>
                <strong>{t( 'Part of speech' )}: </strong>
                <span>{t( 'TermAttributes.PartOfSpeech.' + state.partOfSpeech )}</span>
            </li>
        );
    }
    return partOfSpeechHtml;
}

function getTermTypeHtml( t: TFunction, state: TigState ): JSX.Element | null {
    let termTypeHtml = null;
    if ( state.termType ) {
        termTypeHtml = (
            <li>
                <strong>{t( 'Term type' )}: </strong>
                <span>{t( 'TermAttributes.TermType.' + state.termType )}</span>
            </li>
        );
    }
    return termTypeHtml;
}

function getNormativeAuthorizationHtml( t: TFunction, state: TigState ): JSX.Element | null {
    let normativeAuthorizationHtml = null;
    if ( state.normativeAuthorization ) {
        normativeAuthorizationHtml = (
            <li>
                <strong>{t( 'Authorization' )}: </strong>
                <span>{t( 'TermAttributes.NormativeAuthorization.' + state.normativeAuthorization )}</span>
            </li>
        );
    }
    return normativeAuthorizationHtml;
}

function getLanguageHtml( t: TFunction, state: TigState ): JSX.Element | null {
    let languageHtml = null;
    if ( state.languageCode ) {
        languageHtml = (
            <li>
                <strong>{t( 'Language' )}: </strong>
                <span>{t( 'TermAttributes.Language.' + state.languageCode )}</span>
            </li>
        );
    }
    return languageHtml;
}

async function getLangSetNode( id: string ): Promise<JsonNodeTypeGraph | undefined> {
    let langSetNode: JsonNodeTypeGraph | undefined = undefined;
    const selector = nodeWithTagSelectors( [ TagEnum.TbxLangSet ] ),
        data4worker: ToWorker = [ WorkerMessageType.directParents, [ id, selector, DbType.full ] ],
        directParentNodes = await makeWorkerPromise( data4worker, parentsStore );

    if ( directParentNodes && directParentNodes.length ) {
        langSetNode = directParentNodes[ 0 ];
    }
    return langSetNode;
}

function getLanguageCodeIfDifferentFromContentLanguage( langSetNode: JsonNodeTypeGraph | undefined ): string {
    let languageCode = '';
    if ( langSetNode && langSetNode.a[ 'xml:lang' ] ) {
        let langAttribute = langSetNode.a[ 'xml:lang' ].toLowerCase();
        if ( langAttribute === 'no' ) {
            langAttribute = 'nb';
        }
        if ( document.documentElement.lang !== langAttribute ) {
            languageCode = langSetNode.a[ 'xml:lang' ];
        }
    }
    return languageCode;
}

async function hasMultipleTermsForLangSetNode( langSetElementId: string ): Promise<boolean> {
    let hasMultipleTerms = false;

    const selector = nodeWithTagSelectors( [ TagEnum.TbxTig ] ),
        data4worker: ToWorker = [ WorkerMessageType.children, [ langSetElementId, selector, DbType.full ] ],
        termNodes = await makeWorkerPromise( data4worker, childrenStore );

    if ( termNodes && termNodes.length > 1 ) {
        hasMultipleTerms = true;
    }
    return hasMultipleTerms;
}

function isTermWithChild(term:MixedContent): boolean {
    return term.x && term.x.tag === 'tbx:term' && term.x.c[0] ? true : false;
}

export { stateChanges, getTitle };
