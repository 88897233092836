import { createSlice } from '@reduxjs/toolkit';

export interface ExpandCollapseState {
    areAllExpanded: boolean;
}

export const initialState: ExpandCollapseState = {
    areAllExpanded: true,
};

const expandCollapseState = createSlice({
    name: 'ExpandCollapseState',
    initialState,
    reducers: {
        setAreAllExpanded(state, action) {
            state.areAllExpanded = action.payload;
        }
    }
});

export const { setAreAllExpanded } = expandCollapseState.actions;

export default expandCollapseState.reducer;
