import React, { Component } from 'react';
import { ComponentArg } from '../../types/component-arg';

import { defaultChildren } from '../generic/default-children';
import { TooltipWrapper } from '../tooltip/tooltip-wrapper';
import { getTitle, stateChanges, TigState } from './tig.title';

class Tig extends Component<ComponentArg, TigState> {
    isSubscribed: boolean;

    constructor( p: ComponentArg ) {
        super( p );
        this.state = {};
    }

    componentDidMount(): void {
        this.isSubscribed = true;
        stateChanges( this.props ).then( changes => {
            if (this.isSubscribed) {
                this.setState( changes );
            }
        } );
    }

    componentWillUnmount() {
        this.isSubscribed = false;
    }

    render() {
        if (!this.state.termNode) {
            return null;
        }

        const { t } = this.props,
            title = getTitle( this.props, this.state );

        let classNames,
            additionalText;

        const { termNode } = this.state;

        if ( termNode && termNode!.node!.tag ) {
            classNames = this.state.termNode!.node!.tag.replace( ':', '_' );
        }

        if ( this.state.isDeprecatedTerm ) {
            additionalText = <span className="deprecated">{' (' + t( 'Deprecated term' ) + ')'}</span>;
        }

        if ( this.state.isPreferredTerm ) {
            additionalText = <span className="preferred">{' (' + t( 'Preferred term' ) + ')'}</span>;
        }

        return (
            <div className={this.props.node!.tag ? this.props.node!.tag.replace( ':', '_' ) : undefined}>
                <div className={classNames}>
                    {this.state.termTitle && title ? (
                            <>
                                <TooltipWrapper
                                    termTitle={this.state.termTitle}
                                    title={title}/>
                                {additionalText}
                            </>
                        ) :
                        (
                            <>
                                <span>{this.state.termTitle}</span>
                                {additionalText}
                            </>
                        )
                    }
                    {defaultChildren( this.state.termNode! )}
                </div>

                {defaultChildren( this.props )}

            </div>
        );
    }
}

export { Tig };
