import { createStore, Reducer, Store } from 'redux';
import { IdentityAction, identityReducer, IdentityState } from './identityReducer';

export type Visible = boolean;

export interface LoadingSpinnerNotification {
    visible: Visible;
}

type S = IdentityState<LoadingSpinnerNotification>;
type A = IdentityAction<LoadingSpinnerNotification>;
const loadingSpinnerSubject: Store<S, A> = createStore( identityReducer as Reducer );

export { loadingSpinnerSubject };
