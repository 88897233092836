import * as React from 'react';
import { ReactComponent as Search } from '../../../themes/baseTheme/assets/icons/search.svg';
import { ReactComponent as Menu } from '../../../themes/baseTheme/assets/icons/menu.svg';
import { ReactComponent as User } from '../../../themes/baseTheme/assets/icons/user.svg';
import { ReactComponent as X } from '../../../themes/baseTheme/assets/icons/x.svg';
import { ReactComponent as Help } from '../../../themes/baseTheme/assets/icons/help.svg';
import { ReactComponent as Eye } from '../../../themes/baseTheme/assets/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../../themes/baseTheme/assets/icons/eye-off.svg';
import { ReactComponent as Trash } from '../../../themes/baseTheme/assets/icons/trash.svg';
import { ReactComponent as Check } from '../../../themes/baseTheme/assets/icons/check.svg';
import { ReactComponent as Filter } from '../../../themes/baseTheme/assets/icons/filter.svg';
import { ReactComponent as ChevronDown } from '../../../themes/baseTheme/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '../../../themes/baseTheme/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronsLeft } from '../../../themes/baseTheme/assets/icons/chevrons-left.svg';
import { ReactComponent as ChevronRight } from '../../../themes/baseTheme/assets/icons/chevron-right.svg';
import { ReactComponent as ChevronsRight } from '../../../themes/baseTheme/assets/icons/chevrons-right.svg';
import { ReactComponent as Pencil } from '../../../themes/baseTheme/assets/icons/pencil.svg';
import { ReactComponent as Plus } from '../../../themes/baseTheme/assets/icons/plus.svg';
import { ReactComponent as Info } from '../../../themes/baseTheme/assets/icons/info.svg';
import { ReactComponent as Open } from '../../../themes/baseTheme/assets/icons/open.svg';
import { ReactComponent as Download } from '../../../themes/baseTheme/assets/icons/download.svg';
import { ReactComponent as Inbox } from '../../../themes/baseTheme/assets/icons/inbox.svg';
import { ReactComponent as Alert } from '../../../themes/baseTheme/assets/icons/alert-octagon.svg';
import { ReactComponent as Calendar } from '../../../themes/baseTheme/assets/icons/calendar.svg';
import { ReactComponent as Clock } from '../../../themes/baseTheme/assets/icons/clock.svg';
import { ReactComponent as Forbidden } from '../../../themes/baseTheme/assets/icons/slash.svg';
import { ReactComponent as CheckCircle } from '../../../themes/baseTheme/assets/icons/check-circle.svg';
import { ReactComponent as Zip } from '../../../themes/baseTheme/assets/icons/zip.svg';
import { ReactComponent as Home } from '../../../themes/baseTheme/assets/icons/home.svg';
import styles from './Icon.module.less';

export type IconProps = {
  type: IconType,
  width?: string | number,
  height?: string | number,
  className?: string
  strokeWidth?: string | number,
  ariaHidden?: boolean
}

export enum IconType {
  Alert = 'alert',
  Calendar = 'calendar',
  Check = 'check',
  CheckCircle = 'check-circle',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronsLeft = 'chevrons-left',
  ChevronsRight = 'chevrons-right',
  Clock = 'clock',
  Download = 'download',
  EyeOff = 'eye-off',
  Eye = 'eye',
  Filter = 'filter',
  Forbidden = 'forbidden',
  Home = 'home',
  Inbox = 'inbox',
  Info = 'info',
  Help = 'help',
  Menu = 'menu',
  Open = 'open',
  Pencil = 'pencil',
  Plus = 'plus',
  Search = 'search',
  Trash = 'trash',
  Upload = 'upload',
  User = 'user',
  X = 'x',
  Zip = 'zip',
}

const Icon = ({ 
  type, 
  width = 24, 
  height = 24, 
  className = '', 
  strokeWidth = '',
  ariaHidden
}: IconProps) => {
  // Use Search as default.
  let Icon = Search;
  if (type === IconType.Menu) {
    Icon = Menu;
  } else if (type === IconType.User) {
    Icon = User;
  } else if (type === IconType.X) {
    Icon = X;
  } else if (type === IconType.Help) {
    Icon = Help;
  } else if (type === IconType.Eye) {
    Icon = Eye;
  } else if (type === IconType.EyeOff) {
    Icon = EyeOff;
  } else if (type === IconType.Trash) {
    Icon = Trash;
  } else if (type === IconType.Check) {
    Icon = Check;
  } else if (type === IconType.Filter) {
    Icon = Filter;
  } else if (type === IconType.ChevronDown) {
    Icon = ChevronDown;
  } else if (type === IconType.ChevronLeft) {
    Icon = ChevronLeft;
  } else if (type === IconType.ChevronRight) {
    Icon = ChevronRight;
  } else if (type === IconType.Pencil) {
    Icon = Pencil;
  } else if (type === IconType.Plus) {
    Icon = Plus;
  } else if (type === IconType.Info) {
    Icon = Info;
  } else if (type === IconType.Open) {
    Icon = Open;
  } else if (type === IconType.Download) {
    Icon = Download;
  } else if (type === IconType.Inbox) {
    Icon = Inbox;
  } else if (type === IconType.Alert) {
    Icon = Alert;
  } else if (type === IconType.Calendar) {
    Icon = Calendar;
  } else if (type === IconType.ChevronsLeft) {
    Icon = ChevronsLeft;
  } else if (type === IconType.ChevronsRight) {
    Icon = ChevronsRight;
  } else if (type === IconType.Clock) {
    Icon = Clock;
  } else if (type === IconType.Forbidden) {
    Icon = Forbidden;
  } else if (type === IconType.CheckCircle) {
    Icon = CheckCircle;
  } else if (type === IconType.Zip) {
    Icon = Zip;
  } else if (type === IconType.Home) {
    Icon = Home;
  }

  return (
    <Icon 
      className={`${className} ${styles.icon}`} 
      style={{ 
        width: width,
        height: height,
        strokeWidth: strokeWidth,
      }}
      height={undefined}
      aria-hidden={ariaHidden}
    />
  );
};

export default Icon;
