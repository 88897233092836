import React from 'react';
import CommentTag from '../../../../enquiry/components/atoms/CommentTag/CommentTag';
import { useSelector } from 'react-redux';
import styles from './DocumentFractionWrapper.module.less';
import { submittedComments } from '../../../../enquiry/components/organisms/CommentingFramework/CommentingFrameworkSelector';
import Icon, { IconType } from '../../../../enquiry/components/atoms/Icon/Icon';
import {
  featureSelection,
  isEnquiryPartial,
} from '../../../../../redux/features/selectors';
import { selectedComponent } from './DocumentFractionWrapperSelector';
import { selectWrapper } from './DocumentFractionWrapperState';
import { editComment } from '../../../../enquiry/components/organisms/CommentingFramework/CommentingFrameworkState';
import { isEnquiryEnabled } from '../../../../../component/util/checkConfig';
import Keys from '../../../../../types/key';
import { FontoMessage } from '../../../../../types/fonto-message';
import { useTranslation } from 'react-i18next';
import { partialEnquiry } from '../../../../../constants/partial-enquiry';

type DocumentFractionWrapperProps = {
  elementKey: string;
  elementStatus?: string;
  children?: React.ReactNode;
};

const DocumentFractionWrapper = ({
  elementKey,
  elementStatus,
  children,
}: DocumentFractionWrapperProps) => {
  const { t } = useTranslation();
  const allComments = useSelector(submittedComments);
  const selection = useSelector(featureSelection);
  const isPartialEnquiry = useSelector(isEnquiryPartial);
  const selectedItem = useSelector(selectedComponent);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const selectForCommenting = () => {
    selectWrapper(elementKey);
    if (isEnquiryEnabled()) {
      editComment(undefined);
    } else {
      var fontoMessage = new FontoMessage(elementKey);
      window.parent.postMessage(fontoMessage, '*');
    }
  };

  if (
    selection.enabled === false // selection feature disabled altogether
    || (isPartialEnquiry && elementStatus !== partialEnquiry) // current element is not ready for enquiry yet
  ) {
    return <>{children}</>;
  }

  const componentComments = allComments.filter(
    (comment) => comment.elementId === elementKey
  );
  const hasComments = componentComments.length;

  const keyPressHandler = (event: React.KeyboardEvent) => {
    if (Keys.isEnter(event.key) || Keys.isSpace(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      selectForCommenting();
    }
  };

  let isSelected = false;
  if (selectedItem.isKeySet) {
    isSelected = selectedItem.key === elementKey;
  }

  const selected = isSelected ? styles.selected : '';
  const comments = hasComments ? styles.comments : '';

  return (
    <div className={`${styles.fractionWrapper} ${comments}`}>
      <div className={styles.inner} ref={wrapperRef}>
        <button
          onClick={() => selectForCommenting()}
          onKeyPress={keyPressHandler}
          className={`${styles.clickableButton} ${selected}`}
          aria-label={t('Enquiry.MakeSelection')}
        />
        <div className={styles.childrenWrapper}>{children}</div>
        <div className={styles.iconWrapper}>
          <Icon
            type={IconType.Pencil}
            height={16}
            width={16}
            ariaHidden={true}
          />
        </div>
      </div>
      {componentComments.length > 0 && (
        <div className={styles.commentList}>
          {componentComments.map((comment) => {
            return <CommentTag key={comment.id} comment={comment} />;
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentFractionWrapper;
