import { InitLazyStateBase } from './init-lazy-state.base';
import { NodeId } from '../../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { flatRenderedTextSizeStore } from '../../../redux/worker/stores/flatRenderedTextSize.store';
import { compact, uniq } from 'lodash';

export class InitLazyStateCommentables extends InitLazyStateBase {
  private _ids: NodeId[];
  themes: string[] = [];

  protected async setLazyLoadedIds(): Promise<void> {
    this._ids = this.state.allLazyLoadedIdsInDisplayOrder = this.uniqueCompactIds();
  }

  private uniqueCompactIds(): NodeId[] {
    const ids1: NodeId[] = this.graphService.productInfo!.partialEnquiries;
    return uniq(compact(ids1));
  }

  protected async setflatRenderedTextSize(): Promise<void> {
    if (this._ids && this._ids.length) {
      const state = this.state,
        data4worker: ToWorker = [WorkerMessageType.flatRenderedTextSize, this._ids],
        promise = makeWorkerPromise(data4worker, flatRenderedTextSizeStore);

      await promise.then((map) => (state.flatRenderedTextSize = map));
    }
  }
}
