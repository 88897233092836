import { InitLazyStateBase } from './init-lazy-state.base';
import { NodeId } from '../../../types/json-node.type';
import { ToWorker, WorkerMessageType } from '../../../types/workerMessage.type';
import { makeWorkerPromise } from '../../../redux/worker/stores/workerRequestFactory';
import { RequirementType } from '../../../types/requirement.type';
import { filteredRequirementsStore } from '../../../redux/worker/stores/filteredRequirements.store';
import { flatRenderedTextSizeStore } from '../../../redux/worker/stores/flatRenderedTextSize.store';

export class InitLazyStateRequirements extends InitLazyStateBase {
  requirementTypes: Set<RequirementType> = new Set();
  private _ids: NodeId[];

  protected async setLazyLoadedIds(): Promise<void> {
    this._ids = this.state.allLazyLoadedIdsInDisplayOrder =
      await this.filteredRequirementIds();
  }

  private filteredRequirementIds(): Promise<NodeId[]> {
    const filter: Set<RequirementType> = this.requirementTypes,
      graphService = this.graphService,
      all = graphService.productInfo!.requirements;

    if (filter.size === 0) {
      return Promise.resolve(all);
    }

    const data4worker: ToWorker = [
      WorkerMessageType.filteredRequirements,
      [all, filter],
    ];
    return makeWorkerPromise(data4worker, filteredRequirementsStore);
  }

  protected async setflatRenderedTextSize(): Promise<void> {
    if (this._ids && this._ids.length) {
      const state = this.state,
        data4worker: ToWorker = [WorkerMessageType.flatRenderedTextSize, this._ids],
        promise = makeWorkerPromise(data4worker, flatRenderedTextSizeStore);

      await promise.then((map) => (state.flatRenderedTextSize = map));
    }
  }
}
