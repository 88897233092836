import { Menu } from 'antd';
import { ButtonSize } from 'antd/lib/button';
import * as React from 'react';
import DropdownItem, { DropdownOption } from '../../atoms/DropdownItem/DropdownItem';
import { IconProps, IconType } from '../../atoms/Icon/Icon';
import IconTextButton, { TextAlign } from '../../atoms/IconTextButton/IconTextButton';
import styles from './DropdownList.module.less';

export type DropdownListProps = {
  buttonSize?: ButtonSize,
  buttonText?: string,
  iconRight?: boolean,
  iconType?: IconType,
  iconHeight?: IconProps['height'],
  iconWidth?: IconProps['width'], 
  optionsList: DropdownOption[],
  ariaLabel?: string,
  onChange?: Function,
  value?: string | number,
  className?: string,
  textAlign?: TextAlign,
}

export enum EventKey {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  End = 'End',
  Home = 'Home',
  Enter = 'Enter',
  Space = ' ',
  Escape = 'Escape',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
}

const DropdownList = ({
  buttonSize,
  buttonText = '',
  iconRight = true,
  iconType = IconType.ChevronDown,
  iconWidth,
  iconHeight,
  optionsList,
  ariaLabel = 'Dropdown menu',
  onChange = () => {},
  value = '',
  className = '',
  textAlign = TextAlign.Left,
}: DropdownListProps) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const menuRef = React.useRef<HTMLDivElement>(null);
  const focusedIndex = React.useRef(0);
  const toggleRef = React.useRef<any>();
  const isComponentMounted = React.useRef(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const closeMenu = () => {
    setIsOpen(false);
    toggleRef.current.focus();
  }

  const closeAfterTimeout = () => {
    setTimeout(() => {
      if (isComponentMounted.current) {
        setIsOpen(false);
      }
    }, 250);
  }

  const getMenuElements = () => menuRef.current!.querySelectorAll<HTMLElement>('li > *');

  const focusElement = React.useCallback((index: number | 'last') => {
    const menuElements = getMenuElements();
    if (index === 'last') {
      menuElements[menuElements.length - 1].focus();
    } else if (index < menuElements.length && index >= 0) {
      menuElements[index].focus();
    }
  }, []);

  const keyHandler = (event: React.KeyboardEvent) => {
    if (isOpen) {
      const focusedOption = optionsList[focusedIndex.current];
      switch (event.key) {
        case EventKey.Escape:
          closeMenu();
          break;
        case EventKey.ArrowDown:
          event.preventDefault();
          focusElement(focusedIndex.current + 1);
          break;
        case EventKey.ArrowUp:
          event.preventDefault();
          focusElement(focusedIndex.current - 1);
          break;
        case EventKey.Home:
          event.preventDefault();
          focusElement(0);
          break;
        case EventKey.End:
          event.preventDefault();
          focusElement('last');
          break;
        case EventKey.Space:
        case EventKey.Enter:
          event.preventDefault();
          onChange(focusedOption.value);
          const menuElements = getMenuElements();
          menuElements[focusedIndex.current].click();
          closeMenu();
          break;
      }
    }
  }

  const focusHandler = (index: number) => {
    focusedIndex.current = index;
  }

  React.useEffect(() => {
    if (isOpen) {
      focusElement(focusedIndex.current);
      document.addEventListener('mousedown', closeAfterTimeout);
    } else {
      document.removeEventListener('mousedown', closeAfterTimeout);
    }
    isComponentMounted.current = true;
    return () => {
      document.removeEventListener('mousedown', closeAfterTimeout);
      isComponentMounted.current = false;
    };
  }, [isOpen, focusElement]);

  const closeMenuOnBlur = (event: React.FocusEvent<HTMLUListElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      closeAfterTimeout();
    }
  }

  const uniqueId = Math.random().toString(36).replace('0.', '');

  if (!buttonText) {
    const selectedOption = optionsList.find((option) => option.value === value);
    if (selectedOption) {
      buttonText = selectedOption?.label;
    }
  }

  return (
    <div className={`${styles.dropdown} ${className}`} onKeyDown={keyHandler}>
      <IconTextButton
        buttonSize={buttonSize}
        buttonText={buttonText}
        iconType={iconType}
        ariaControls={uniqueId}
        ariaExpanded={isOpen}
        ariaHasPopup="listbox"
        iconHeight={iconHeight}
        iconWidth={iconWidth}
        iconRight={iconRight}
        onClick={toggle}
        className={styles.toggle}
        textAlign={textAlign}
        ref={toggleRef}
      />
      <div ref={menuRef}>
        <Menu 
          hidden={!isOpen} 
          className={styles.menu} 
          id={uniqueId}
          role="listbox"
          aria-label={ariaLabel}
          selectedKeys={[String(value)]}
          onBlur={closeMenuOnBlur}
        >
          {optionsList.map((option, index) => (
            <Menu.Item key={index} onClick={() => onChange(option.value)} role="option">
              <DropdownItem option={option} index={index} onFocus={() => focusHandler(index)} />
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
}

export default DropdownList;
